"use client";
import { DependencyList, useEffect, useRef } from "react";

/**
 * 非同期関数を実行し、必要に応じてクリーンアップ関数を提供するカスタムフック。
 *
 * @param asyncFunction - 実行する非同期関数。Promiseを返す必要があり、
 *                        そのPromiseはクリーンアップ関数を返すことができます。
 * @param deps - useEffectフックの依存配列。この配列内の値が変更された場合、
 *               非同期関数が再実行されます。デフォルトは空配列です。
 */
export function useAsync(
  asyncFunction: () => Promise<void | (() => void)>,
  deps: DependencyList = []
) {
  const callbackRef = useRef<void | (() => void)>();
  useEffect(() => {
    asyncFunction().then((callback) => {
      callbackRef.current = callback;
    });
    return () => {
      if (callbackRef.current) {
        callbackRef.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
