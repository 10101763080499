import clsx from "clsx";

import styles from "./Grid.module.scss";

export interface GridProps extends React.ComponentProps<"div"> {
  container?: boolean;
}

export function Grid({ className, container = true, ...restProps }: GridProps): React.ReactNode {
  return <div className={clsx({ [styles.container]: container }, className)} {...restProps} />;
}
