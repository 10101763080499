"use client";

import clsx from "clsx";
import { usePathname } from "next/navigation";

import { FEATURE_FLAG } from "@/configs/system";

import styles from "./ExtraGiftCampaignBar.module.scss";

// キャンペーンを表示しないpath
const excludeCampaignPaths = [
  "/products/subscription/4",
  "/products/deli/basepasta/bolognese",
  "/products/deli/basepasta/tarako",
  "/products/deli/basepasta/creamymushroom",
  "/products/outlet/*",
].map((path) => new RegExp(`^${path.replace("*", ".*")}$`));

// キャンペーンを表示するpath
const includeCampaignPaths = ["/cart*", "/products/*"].map(
  (path) => new RegExp(`^${path.replace("*", ".*")}$`)
);

// おまけ増量キャンペーンバーを表示するpathを取得
const showExtraGiftCampaignBar = (pathname: string): boolean => {
  if (excludeCampaignPaths.some((path) => path.test(pathname))) {
    return false;
  }

  if (includeCampaignPaths.some((path) => path.test(pathname))) {
    return true;
  }

  return false;
};

export function ExtraGiftCampaignBar(): React.ReactNode {
  const pathname = usePathname();
  if (!FEATURE_FLAG.isExtraGiftCampaignActive || !showExtraGiftCampaignBar(pathname)) {
    return null;
  }

  return (
    <p
      className={clsx(
        "text__s text__bold text__center pd__top__s pd__bottom__s",
        styles.extraGiftCampaignBg
      )}
    >
      今ならおまけ3袋に増量中！
    </p>
  );
}
