import { Survey, GetSurveyResponse } from "@/generated/open-api/schemas";
import { objectEntries } from "@/utils/object";

import { SurveyType } from "./consts";
import { SurveyModel } from "./type";
import { convertObjToCamelCase } from "../../utils/converters";

/**
 * convert ApiSurvey to Survey
 */
export const convertApiSurveyToSurvey = (apiSurvey: Survey): SurveyModel => {
  const answerOptions = objectEntries(apiSurvey)
    .filter(([key, value]) => key.startsWith("answer_") && value)
    .map(([key, value]) => {
      const index = Number(key.replace("answer_", ""));
      return { value: index, label: value as string };
    })
    .sort((a, b) => a.value - b.value)
    .map(({ value, label }) => ({ value: value.toString(), label }));
  return {
    ...convertObjToCamelCase(apiSurvey),
    answerOptions,
    withShuffle: Boolean(apiSurvey.with_shuffle),
    hasOtherSelection: Boolean(apiSurvey.has_other_selection),
    type: apiSurvey.type === "1" ? SurveyType.radio : SurveyType.checkbox,
  };
};

export const convertApiSurveyResponseToSurveyResponse = (apiSurvey?: GetSurveyResponse) => {
  return {
    surveyList: apiSurvey?.survey_list.map(convertApiSurveyToSurvey) ?? [],
    surveyNum: apiSurvey?.survey_num ?? 0,
  };
};
