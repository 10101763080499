export const zeroPadding = (num: number | string, length: number) => {
  return (Array(length).join("0") + num).slice(-length);
};

export const floatDigits = (num: number | string | undefined, digits: number = 1) => {
  if (num === undefined) {
    return "";
  }

  return parseFloat(num.toString()).toFixed(digits);
};
