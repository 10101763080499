import { create } from "zustand";

type LPStore = {
  selectIndex?: number;
  setSelectIndex: (index: number) => void;
  submittingProgress: number;
  setSubmittingProgress: (progress: number) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  resetSubmittingStatus: () => void;
};

export const useLPStore = create<LPStore>()((set) => ({
  // selectIndexはlocalStorageから復活できるため、undefinedを初期値に設定
  selectIndex: undefined,
  setSelectIndex: (index) => set(() => ({ selectIndex: index })),
  submittingProgress: 0,
  setSubmittingProgress: (progress) => set(() => ({ submittingProgress: progress })),
  isSubmitting: false,
  setIsSubmitting: (isSubmitting) => set(() => ({ isSubmitting: isSubmitting })),
  resetSubmittingStatus: () => set(() => ({ submittingProgress: 0, isSubmitting: false })),
}));
