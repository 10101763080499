"use client";
import { useEffect } from "react";

/**
 * Modalのopen stateに応じてbodyのscrollを制御できる。
 * デフォルトはmount時にbodyのscrollを無効化する。
 */
export function useDisableBodyScroll(canBeDisabled = true) {
  useEffect(() => {
    const body = document.querySelector("body");
    if (!body) return;

    body.style.overflow = canBeDisabled ? "hidden" : "auto";

    return () => {
      body.style.overflow = "auto";
    };
  }, [canBeDisabled]);
}
