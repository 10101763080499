"use client";

import { useCallback, useState } from "react";

import Link from "next/link";
import { usePathname } from "next/navigation";

import { Image } from "@/components/displays";
import { CartBadge } from "@/components/domains";

import { Drawer } from "./Drawer";
import styles from "./MobileHeader.module.scss";

const getIconViewInfo = (pathname: string) => {
  // A/Bテストの結果、checkout時はアイコンを非表示にしたほうがCV数が高いことがわかったので、パスでアイコンの表示・非表示を制御している
  if (pathname === "/checkout" || pathname === "/checkout/confirm") {
    return {
      showMyPageIcon: false,
      showCartIcon: true,
      showMenuOpenIcon: false,
    };
  }

  return {
    showMyPageIcon: true,
    showCartIcon: true,
    showMenuOpenIcon: true,
  };
};

export function MobileHeader(): React.ReactNode {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => setMenuOpen((prev) => !prev), []);
  const pathname = usePathname();
  const { showMyPageIcon, showCartIcon, showMenuOpenIcon } = getIconViewInfo(pathname);

  return (
    <>
      <div className={styles.container}>
        <a href="https://basefood.co.jp/">
          <Image
            src="https://asset.basefood.co.jp/images/logo/logo-color.svg"
            alt="ベースフード株式会社"
            size={{ width: 146, height: 24 }}
          />
        </a>
        <ul className={styles.side}>
          {menuOpen ? (
            <li>
              <span onClick={toggleMenu}>
                <Image
                  src="https://asset.basefood.co.jp/images/navi/icon-close.png"
                  size={{ width: 44, height: 44 }}
                  alt="閉じる"
                />
              </span>
            </li>
          ) : (
            <>
              {showMyPageIcon && (
                <li>
                  <Link href="/mypage" className={styles.link}>
                    <Image
                      src="https://asset.basefood.co.jp/images/navi/icon-user.png"
                      alt="マイページ"
                      size={{ width: 44, height: 44 }}
                    />
                  </Link>
                </li>
              )}
              {showCartIcon && (
                <li>
                  <Link href="/cart" className={styles.link}>
                    <CartBadge
                      src="https://asset.basefood.co.jp/images/navi/icon-cart.png"
                      width={44}
                      height={44}
                      isPC={false}
                    />
                  </Link>
                </li>
              )}
              {showMenuOpenIcon && (
                <li>
                  <span onClick={toggleMenu}>
                    <Image
                      src="https://asset.basefood.co.jp/images/navi/icon-menu.png"
                      alt="メニュー"
                      size={{ width: 44, height: 44 }}
                    />
                  </span>
                </li>
              )}
            </>
          )}
        </ul>
      </div>

      <Drawer menuOpen={menuOpen} closeMenu={toggleMenu} />
    </>
  );
}
