import { Cookies } from "react-cookie";

import { payseTokenKey } from "@/models/auth/types";
import { isClient } from "@/utils";

type AuthInfo = Readonly<
  | {
      accessToken: string;
      isLoggedIn: true;
    }
  | {
      accessToken: undefined;
      isLoggedIn: false;
    }
>;

/**
 * next/headersを直接importすると、サーバー側しか動かないエラーになるためdynamic importを利用
 *
 * importを使うと関数が非同期になるため、requireを利用
 */
const getServerAccessToken = (): string | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const cookieStore = require("next/headers").cookies();
  return cookieStore.get(payseTokenKey)?.value;
};

const getClientAccessToken = (): string | undefined => {
  return new Cookies().get(payseTokenKey);
};

/**
 * サーバーサイドでもクライアントサイドでもアクセストークンための関数
 * 認証情報のセットはuseAuth.tsを利用する
 * @returns
 */
export const getAuthInfo = () => {
  const accessToken = isClient ? getClientAccessToken() : getServerAccessToken();
  return { accessToken, isLoggedIn: !!accessToken } as AuthInfo;
};
