import clsx from "clsx";

import { Image } from "@/components/displays";

import variation from "$/images/lp/product_variation.png";

import styles from "./Variation.module.scss";

export function Variation(): React.ReactNode {
  return (
    <Image
      src={variation.src}
      alt="飽きなく続けられるように豊富な味から選べる"
      aspectRatio="751 / 4450"
      containerClassName={clsx(styles.image, styles.fullImage)}
    />
  );
}
