import clsx from "clsx";

import { Chip } from "@/components/displays";

import styles from "./FieldLabel.module.scss";

interface FieldLabelProps {
  showUnfilled?: boolean;
  children: React.ReactNode;
}

export function FieldLabel({ children, showUnfilled }: FieldLabelProps): React.ReactNode {
  return (
    <p className={clsx("text__m", "mg__bottom__s", "mg__top__m")}>
      {showUnfilled && (
        <Chip color="red" className={styles.chip}>
          未入力
        </Chip>
      )}
      {children}
    </p>
  );
}
