import axios from "axios";
import urljoin from "url-join";

import { getAxios } from "@/configs";
import { amazonGetCheckoutUrl } from "@/generated/open-api/amazon/amazon";
import {
  AmazonGetCheckoutUrl200,
  AmazonGetCheckoutUrl200OneOf,
  AmazonGetCheckoutUrl200OneOfTwo,
  AmazonGetCheckoutUrlBody,
} from "@/generated/open-api/schemas";

declare global {
  interface Window {
    amazon?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Pay: any;
    };
  }
}

export async function waitAmazonPayLoaded() {
  await new Promise<void>((resolve, reject) => {
    if (window?.amazon?.Pay) {
      resolve();
      return;
    }
    const count = 0;
    const intervalId = setInterval(() => {
      if (count > 1000) {
        clearInterval(intervalId);
        reject(new Error("AmazonPayLoad failed"));
        return;
      }
      if (window?.amazon?.Pay) {
        clearInterval(intervalId);
        resolve();
      }
    }, 500);
  });
}

interface AmazonPayButtonInitArgs {
  amazonPayButtonId: string;
  reviewReturnPath: string;
  resultReturnPath: string;
  isSubscription: boolean;
}

export async function amazonPayButtonInit({
  amazonPayButtonId,
  reviewReturnPath,
  resultReturnPath,
  isSubscription,
}: AmazonPayButtonInitArgs) {
  const axios = getAxios();
  const response = await axios.get("/amazon/payload", {
    params: {
      subscription: isSubscription ? "true" : "false",
      review_return_path: reviewReturnPath,
      result_return_path: resultReturnPath,
    },
  });

  const values = {
    // set checkout environment
    merchantId: process.env.NEXT_PUBLIC_AMAZONPAY_MERCHANT_ID,
    ledgerCurrency: "JPY",
    sandbox: process.env.NEXT_PUBLIC_APP_ENV !== "production",
    // customize the buyer experience
    checkoutLanguage: "ja_JP",
    productType: "PayAndShip",
    placement: "Cart",
    buttonColor: "Gold",
    // set checkout environment
    createCheckoutSessionConfig: {
      payloadJSON: response.data.payload,
      signature: response.data.signature,
      publicKeyId: process.env.NEXT_PUBLIC_AMAZONPAY_PUBLIC_KEY_ID,
    },
  };
  const intervalId = setInterval(async () => {
    const button = document.getElementById(amazonPayButtonId);
    if (button && !button.shadowRoot) {
      // 未レンダリングの場合のみレンダリング
      await waitAmazonPayLoaded();
      window?.amazon?.Pay.renderButton(`#${amazonPayButtonId}`, values);
    }
    clearInterval(intervalId);
  }, 500);
}

export function amazonPaySubscriptionUpdateButtonInit(accessToken: string) {
  axios
    .get(urljoin(process.env.NEXT_PUBLIC_API_URL as string, "/api/v2/amazon/payload_update"), {
      params: {},
      headers: {
        "Jwt-Authorization": `Bearer ` + accessToken,
      },
    })
    .then(async (response) => {
      const values = {
        // set checkout environment
        merchantId: process.env.NEXT_PUBLIC_AMAZONPAY_MERCHANT_ID,
        ledgerCurrency: "JPY",
        sandbox: process.env.NEXT_PUBLIC_APP_ENV !== "production",
        // customize the buyer experience
        checkoutLanguage: "ja_JP",
        productType: "PayAndShip",
        placement: "Cart",
        buttonColor: "Gold",
        // set checkout environment
        createCheckoutSessionConfig: {
          payloadJSON: response.data.payload,
          signature: response.data.signature,
          publicKeyId: process.env.NEXT_PUBLIC_AMAZONPAY_PUBLIC_KEY_ID,
        },
      };
      await waitAmazonPayLoaded();
      window?.amazon?.Pay?.renderButton("#AmazonPayButton", values);
    });
}

interface SetupAmazonArgs {
  reviewReturnPath: string;
  resultReturnPath: string;
  isSubscription: boolean;
}

/**
 * AmazonPayのログイン画面を表示できます。
 * amazonPayButtonInitでは、AmazonPayのボタンを表示したのち、そのクリックイベントを発火させることで、AmazonPayのログイン画面を表示しますが
 * setupAmazonPayは命令的にAmazonPayのログイン画面を表示することができます。
 * @param param0
 */
export async function setupAmazonPay({
  isSubscription,
  reviewReturnPath,
  resultReturnPath,
}: SetupAmazonArgs) {
  await waitAmazonPayLoaded();
  const axios = getAxios();
  const response = await axios.get("/amazon/payload", {
    params: {
      subscription: isSubscription ? "true" : "false",
      review_return_path: reviewReturnPath,
      result_return_path: resultReturnPath,
    },
  });

  const values = {
    // set checkout environment
    merchantId: process.env.NEXT_PUBLIC_AMAZONPAY_MERCHANT_ID,
    ledgerCurrency: "JPY",
    sandbox: process.env.NEXT_PUBLIC_APP_ENV !== "production",
    // customize the buyer experience
    checkoutLanguage: "ja_JP",
    productType: "PayAndShip",
    placement: "Cart",
    buttonColor: "Gold",
    // set checkout environment
    createCheckoutSessionConfig: {
      payloadJSON: response.data.payload,
      signature: response.data.signature,
      publicKeyId: process.env.NEXT_PUBLIC_AMAZONPAY_PUBLIC_KEY_ID,
    },
  };
  const element = document.createElement("div");
  const elementId = `amazon-pay-setup-${Math.random().toString(36).slice(-8)}`;
  element.id = elementId;
  element.style.display = "none";
  document.body.appendChild(element);
  window?.amazon?.Pay.renderButton(`#${elementId}`, values);
  element.click();
  document.body.removeChild(element);
  element.remove();
}

/**
 * AmazonPayのアクションを命令的に実行する関数
 * amazonPayChangeButtonInitの代わりに使用可能
 * @param sessionId
 * @param action
 */
export async function fireAmazonPayAction(
  sessionId: string,
  action: "changePayment" | "changeAddress"
) {
  await waitAmazonPayLoaded();
  const element = document.createElement("button");
  const elementId = `amazon-pay-${action}-${Math.random().toString(36).slice(-8)}`;
  element.id = elementId;
  element.style.display = "none";
  document.body.appendChild(element);
  window?.amazon?.Pay.bindChangeAction(`#${elementId}`, {
    amazonCheckoutSessionId: sessionId,
    changeAction: action,
  });
  element.click();
  document.body.removeChild(element);
  element.remove();
}

type AmazonStorageKey = "amazon_pay_input_data" | "amazon_pay_point_pack";
/**
 * 元setStoreCheckoutAmazonPayInputData
 * pathnameの運用で、データが削除できていないため運用を変更
 * @param key
 * @param form
 */
export function setFormDataForAmazonPay<T>(key: AmazonStorageKey, form: T) {
  const data = JSON.stringify({ form });
  sessionStorage.setItem(key, data);
}

/**
 * 元getStoreCheckoutAmazonPayInputData
 * pathnameの運用で、データが削除できていないため運用を変更
 * @param key
 * @returns
 */
export function getFormDataForAmazonPay<T>(key: AmazonStorageKey): T | null {
  const sessionData = sessionStorage.getItem(key);
  if (!sessionData) {
    return null;
  }
  const data = JSON.parse(sessionData);
  return data.form;
}

/**
 * 元deleteStoreAmazonPayInputData
 * @param key
 */
export function deleteFormDataForAmazonPay(key: AmazonStorageKey) {
  sessionStorage.removeItem(key);
}

type GetAmazonCheckoutUrlResponse = {
  url?: string;
  error?: string;
};

// amazonGetCheckoutUrlのレスポンス型が複雑なので簡素化した型を返すラッパー
export async function getAmazonCheckoutUrl(
  body: AmazonGetCheckoutUrlBody
): Promise<GetAmazonCheckoutUrlResponse> {
  const res = await amazonGetCheckoutUrl(body);

  if (isResponseUrl(res) && res.url) {
    return { url: res.url };
  }

  if (isResponseError(res) && res.error) {
    return { error: res.error };
  }

  return {};
}

function isResponseUrl(res: AmazonGetCheckoutUrl200): res is AmazonGetCheckoutUrl200OneOf {
  return res.hasOwnProperty("url");
}

function isResponseError(res: AmazonGetCheckoutUrl200): res is AmazonGetCheckoutUrl200OneOfTwo {
  return res.hasOwnProperty("error");
}
