/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type {
  AmazonGetCheckout200,
  AmazonGetCheckoutBodyOne,
  AmazonGetCheckoutBodyTwo,
  AmazonGetCheckoutUrl200,
  AmazonGetCheckoutUrlBody,
  AmazonGetPermissionUrl200,
  AmazonGetPermissionUrlBodyOne,
  AmazonGetPermissionUrlBodyTwo,
  AmazonPayload200,
  AmazonPayloadParams,
  AmazonPayloadUpdate200,
  AmazonSavePermissionUrl200,
  AmazonSavePermissionUrl400,
  AmazonSavePermissionUrlBodyOne,
  AmazonSavePermissionUrlBodyTwo,
} from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Amazon Pay button display
 */
export const amazonPayload = (
  params: AmazonPayloadParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<AmazonPayload200>(
    { url: `/amazon/payload`, method: "GET", params, signal },
    options
  );
};

export const getAmazonPayloadQueryKey = (params: AmazonPayloadParams) => {
  return [`/amazon/payload`, ...(params ? [params] : [])] as const;
};

export const getAmazonPayloadQueryOptions = <
  TData = Awaited<ReturnType<typeof amazonPayload>>,
  TError = ErrorType<unknown>,
>(
  params: AmazonPayloadParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof amazonPayload>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAmazonPayloadQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof amazonPayload>>> = ({ signal }) =>
    amazonPayload(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof amazonPayload>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AmazonPayloadQueryResult = NonNullable<Awaited<ReturnType<typeof amazonPayload>>>;
export type AmazonPayloadQueryError = ErrorType<unknown>;

/**
 * @summary Amazon Pay button display
 */
export const useAmazonPayload = <
  TData = Awaited<ReturnType<typeof amazonPayload>>,
  TError = ErrorType<unknown>,
>(
  params: AmazonPayloadParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof amazonPayload>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAmazonPayloadQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Amazon Pay button display
 */
export const prefetchAmazonPayload = async <
  TData = Awaited<ReturnType<typeof amazonPayload>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: AmazonPayloadParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof amazonPayload>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getAmazonPayloadQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getAmazonPayloadSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof amazonPayload>>,
  TError = ErrorType<unknown>,
>(
  params: AmazonPayloadParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof amazonPayload>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAmazonPayloadQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof amazonPayload>>> = ({ signal }) =>
    amazonPayload(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof amazonPayload>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AmazonPayloadSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof amazonPayload>>
>;
export type AmazonPayloadSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary Amazon Pay button display
 */
export const useAmazonPayloadSuspense = <
  TData = Awaited<ReturnType<typeof amazonPayload>>,
  TError = ErrorType<unknown>,
>(
  params: AmazonPayloadParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof amazonPayload>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAmazonPayloadSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Amazon Pay button display
 */
export const prefetchAmazonPayloadSuspense = async <
  TData = Awaited<ReturnType<typeof amazonPayload>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: AmazonPayloadParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof amazonPayload>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getAmazonPayloadSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary address of amazon authenticated user
 */
export const amazonGetCheckout = (
  amazonGetCheckoutBody: AmazonGetCheckoutBodyOne | AmazonGetCheckoutBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<AmazonGetCheckout200>(
    { url: `/amazon/get_checkout`, method: "POST", data: amazonGetCheckoutBody },
    options
  );
};

export const getAmazonGetCheckoutMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof amazonGetCheckout>>,
    TError,
    { data: AmazonGetCheckoutBodyOne | AmazonGetCheckoutBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof amazonGetCheckout>>,
  TError,
  { data: AmazonGetCheckoutBodyOne | AmazonGetCheckoutBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof amazonGetCheckout>>,
    { data: AmazonGetCheckoutBodyOne | AmazonGetCheckoutBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return amazonGetCheckout(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AmazonGetCheckoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof amazonGetCheckout>>
>;
export type AmazonGetCheckoutMutationBody = AmazonGetCheckoutBodyOne | AmazonGetCheckoutBodyTwo;
export type AmazonGetCheckoutMutationError = ErrorType<unknown>;

/**
 * @summary address of amazon authenticated user
 */
export const useAmazonGetCheckout = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof amazonGetCheckout>>,
    TError,
    { data: AmazonGetCheckoutBodyOne | AmazonGetCheckoutBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getAmazonGetCheckoutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get amazon pay checkout url
 */
export const amazonGetCheckoutUrl = (
  amazonGetCheckoutUrlBody: AmazonGetCheckoutUrlBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<AmazonGetCheckoutUrl200>(
    {
      url: `/amazon/get_checkout_url`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: amazonGetCheckoutUrlBody,
    },
    options
  );
};

export const getAmazonGetCheckoutUrlMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof amazonGetCheckoutUrl>>,
    TError,
    { data: AmazonGetCheckoutUrlBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof amazonGetCheckoutUrl>>,
  TError,
  { data: AmazonGetCheckoutUrlBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof amazonGetCheckoutUrl>>,
    { data: AmazonGetCheckoutUrlBody }
  > = (props) => {
    const { data } = props ?? {};

    return amazonGetCheckoutUrl(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AmazonGetCheckoutUrlMutationResult = NonNullable<
  Awaited<ReturnType<typeof amazonGetCheckoutUrl>>
>;
export type AmazonGetCheckoutUrlMutationBody = AmazonGetCheckoutUrlBody;
export type AmazonGetCheckoutUrlMutationError = ErrorType<unknown>;

/**
 * @summary get amazon pay checkout url
 */
export const useAmazonGetCheckoutUrl = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof amazonGetCheckoutUrl>>,
    TError,
    { data: AmazonGetCheckoutUrlBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getAmazonGetCheckoutUrlMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get Payload And Signature Update Subscription
 */
export const amazonPayloadUpdate = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<AmazonPayloadUpdate200>(
    { url: `/amazon/payload_update`, method: "GET", signal },
    options
  );
};

export const getAmazonPayloadUpdateQueryKey = () => {
  return [`/amazon/payload_update`] as const;
};

export const getAmazonPayloadUpdateQueryOptions = <
  TData = Awaited<ReturnType<typeof amazonPayloadUpdate>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof amazonPayloadUpdate>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAmazonPayloadUpdateQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof amazonPayloadUpdate>>> = ({ signal }) =>
    amazonPayloadUpdate(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof amazonPayloadUpdate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AmazonPayloadUpdateQueryResult = NonNullable<
  Awaited<ReturnType<typeof amazonPayloadUpdate>>
>;
export type AmazonPayloadUpdateQueryError = ErrorType<unknown>;

/**
 * @summary get Payload And Signature Update Subscription
 */
export const useAmazonPayloadUpdate = <
  TData = Awaited<ReturnType<typeof amazonPayloadUpdate>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof amazonPayloadUpdate>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAmazonPayloadUpdateQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get Payload And Signature Update Subscription
 */
export const prefetchAmazonPayloadUpdate = async <
  TData = Awaited<ReturnType<typeof amazonPayloadUpdate>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof amazonPayloadUpdate>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getAmazonPayloadUpdateQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getAmazonPayloadUpdateSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof amazonPayloadUpdate>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof amazonPayloadUpdate>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAmazonPayloadUpdateQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof amazonPayloadUpdate>>> = ({ signal }) =>
    amazonPayloadUpdate(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof amazonPayloadUpdate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AmazonPayloadUpdateSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof amazonPayloadUpdate>>
>;
export type AmazonPayloadUpdateSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get Payload And Signature Update Subscription
 */
export const useAmazonPayloadUpdateSuspense = <
  TData = Awaited<ReturnType<typeof amazonPayloadUpdate>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof amazonPayloadUpdate>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAmazonPayloadUpdateSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get Payload And Signature Update Subscription
 */
export const prefetchAmazonPayloadUpdateSuspense = async <
  TData = Awaited<ReturnType<typeof amazonPayloadUpdate>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof amazonPayloadUpdate>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getAmazonPayloadUpdateSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary amazon pay get permission url
 */
export const amazonGetPermissionUrl = (
  amazonGetPermissionUrlBody: AmazonGetPermissionUrlBodyOne | AmazonGetPermissionUrlBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<AmazonGetPermissionUrl200>(
    { url: `/amazon/get_permission_url`, method: "POST", data: amazonGetPermissionUrlBody },
    options
  );
};

export const getAmazonGetPermissionUrlMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof amazonGetPermissionUrl>>,
    TError,
    { data: AmazonGetPermissionUrlBodyOne | AmazonGetPermissionUrlBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof amazonGetPermissionUrl>>,
  TError,
  { data: AmazonGetPermissionUrlBodyOne | AmazonGetPermissionUrlBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof amazonGetPermissionUrl>>,
    { data: AmazonGetPermissionUrlBodyOne | AmazonGetPermissionUrlBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return amazonGetPermissionUrl(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AmazonGetPermissionUrlMutationResult = NonNullable<
  Awaited<ReturnType<typeof amazonGetPermissionUrl>>
>;
export type AmazonGetPermissionUrlMutationBody =
  | AmazonGetPermissionUrlBodyOne
  | AmazonGetPermissionUrlBodyTwo;
export type AmazonGetPermissionUrlMutationError = ErrorType<unknown>;

/**
 * @summary amazon pay get permission url
 */
export const useAmazonGetPermissionUrl = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof amazonGetPermissionUrl>>,
    TError,
    { data: AmazonGetPermissionUrlBodyOne | AmazonGetPermissionUrlBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getAmazonGetPermissionUrlMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary amazon pay save permission
 */
export const amazonSavePermissionUrl = (
  amazonSavePermissionUrlBody: AmazonSavePermissionUrlBodyOne | AmazonSavePermissionUrlBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<AmazonSavePermissionUrl200>(
    { url: `/amazon/save_permission`, method: "POST", data: amazonSavePermissionUrlBody },
    options
  );
};

export const getAmazonSavePermissionUrlMutationOptions = <
  TError = ErrorType<AmazonSavePermissionUrl400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof amazonSavePermissionUrl>>,
    TError,
    { data: AmazonSavePermissionUrlBodyOne | AmazonSavePermissionUrlBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof amazonSavePermissionUrl>>,
  TError,
  { data: AmazonSavePermissionUrlBodyOne | AmazonSavePermissionUrlBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof amazonSavePermissionUrl>>,
    { data: AmazonSavePermissionUrlBodyOne | AmazonSavePermissionUrlBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return amazonSavePermissionUrl(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AmazonSavePermissionUrlMutationResult = NonNullable<
  Awaited<ReturnType<typeof amazonSavePermissionUrl>>
>;
export type AmazonSavePermissionUrlMutationBody =
  | AmazonSavePermissionUrlBodyOne
  | AmazonSavePermissionUrlBodyTwo;
export type AmazonSavePermissionUrlMutationError = ErrorType<AmazonSavePermissionUrl400>;

/**
 * @summary amazon pay save permission
 */
export const useAmazonSavePermissionUrl = <
  TError = ErrorType<AmazonSavePermissionUrl400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof amazonSavePermissionUrl>>,
    TError,
    { data: AmazonSavePermissionUrlBodyOne | AmazonSavePermissionUrlBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getAmazonSavePermissionUrlMutationOptions(options);

  return useMutation(mutationOptions);
};
