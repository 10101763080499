import clsx from "clsx";
import NextImage from "next/image";

import styles from "./Image.module.scss";

export type ImageSize = { width: number; height: number };

export interface ImageProps
  extends Omit<React.ComponentProps<typeof NextImage>, "width" | "height"> {
  size?: ImageSize; // サイズ指定がない場合、親に合わせてサイズを100%に拡大する。
  aspectRatio?: string; // サイズ指定がない場合、こちらで縦横比を指定する必要がある（デフォルトは1:1)
  containerClassName?: string;
}

// 画像コンポーネント。NextImageで最適なwebpにしてくれるので、既存であったsourceやsrcSetは使用しない。
export function Image({
  size,
  aspectRatio = "1",
  containerClassName,
  ...restProps
}: ImageProps): React.ReactNode {
  return (
    <div
      className={clsx(styles.container, containerClassName)}
      style={size ? size : { aspectRatio, width: "100%" }}
    >
      <NextImage fill={!size} width={size?.width} height={size?.height} {...restProps} />
    </div>
  );
}
