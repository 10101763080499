import clsx from "clsx";

import styles from "./CounterList.module.scss";

interface Content {
  title?: string;
  description?: string;
  extraDescription?: React.ReactNode;
}

export interface CounterListProps {
  contents: Content[];
  displayBorder?: boolean;
}

export function CounterList({ contents, displayBorder = true }: CounterListProps): React.ReactNode {
  return (
    <ol className={styles.counterList}>
      {contents.map(({ title, description, extraDescription }, index) => (
        <li
          key={title || description || index}
          className={clsx(displayBorder && styles.counterListBorderBottomDotted)}
        >
          <dl>
            {title && <dt className="text__m text__bold">{title}</dt>}
            {description && (
              // タイトルが表示される場合のみ、説明文の上部に余白を追加
              <dd className={clsx("text__m", title && "pd__top__s")}>{description}</dd>
            )}
            {extraDescription && <dd className="text__s pd__top__s">{extraDescription}</dd>}
          </dl>
        </li>
      ))}
    </ol>
  );
}
