"use client";
import { useEffect } from "react";

import clsx from "clsx";

import { Chip } from "@/components";
import { amazonPayButtonInit } from "@/utils/amazon";

import styles from "./AmazonPayPanel.module.scss";

interface AmazonPayPanelProps {
  isSubscription: boolean;
  reviewReturnPath: string;
  resultReturnPath: string;
  onClickPayButton: () => void;
}

const amazonPayButtonId = "AmazonPayButton";

export function AmazonPayPanel({
  isSubscription,
  onClickPayButton,
  reviewReturnPath,
  resultReturnPath,
}: AmazonPayPanelProps): React.ReactNode {
  useEffect(() => {
    amazonPayButtonInit({
      amazonPayButtonId,
      reviewReturnPath,
      resultReturnPath,
      isSubscription,
    });
  }, [isSubscription, resultReturnPath, reviewReturnPath]);

  return (
    <div className={clsx(styles.sectionAmazonPay, "mg__top__m", "mg__bottom__l")}>
      <div className={clsx("text__yellow", "text__bold", "text__s", "mg__bottom__xs")}>
        <Chip className={styles.tagAmazon}>
          <div className="text__white">おすすめ</div>
        </Chip>
        30秒で購入完了
      </div>
      <p
        className={clsx(
          "text__m",
          "text__bold",
          "mg__bottom__m",
          "pd__bottom__s",
          styles.titleBorder
        )}
      >
        Amazonアカウントの登録情報で購入する
      </p>
      <div className="row">
        <div className={clsx("col-12", "col-m-7")}>
          <p className={clsx("text__s", "mg__bottom__s", "text__justify")}>
            Amazonアカウントをお持ちのお客様は、Amazonに登録した配送先や支払方法をそのまま使用してお買い物ができる「Amazonアカウントでお支払い」を利用いただけます。
          </p>
          <p className={clsx("text__s", "text__gray__dark")}>
            ※Amazonにご登録のメールアドレスがアカウントのメールアドレスになります。
          </p>
        </div>
        <div
          className={clsx("col-12", "col-m-5", "text__center", "pd__top__m", "pd__top__off__pc")}
        >
          <div id={amazonPayButtonId} onClick={onClickPayButton} />
        </div>
      </div>
    </div>
  );
}
