import React, { useId } from "react";

import { Row } from "@/components/containers";

type BaseProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">;

export interface RadioButtonProps extends BaseProps {
  children: React.ReactNode;
}

export function RadioButton({
  id,
  className,
  children,
  ...rest
}: RadioButtonProps): React.ReactNode {
  const generatedId = useId();
  const inputId = id ?? generatedId;

  return (
    <Row className={className}>
      <input id={inputId} type="radio" className="radio__input" {...rest} />
      <label className="text__m" htmlFor={inputId}>
        {children}
      </label>
    </Row>
  );
}
