import { useMemo } from "react";

import { useGetCustomerPointDetail } from "@/generated/open-api/customer/customer";
import { convertObjToCamelCase } from "@/utils/converters";

export const useParsedGetCustomerPointDetail = (pointId: string) => {
  const { data, ...rest } = useGetCustomerPointDetail({ point_id: pointId });
  const parsed = useMemo(() => {
    if (!data) return;

    return convertObjToCamelCase(data);
  }, [data]);

  return { data: parsed, ...rest };
};
