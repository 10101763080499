export type DetectDataChangeFunction = (() => boolean) | (() => Promise<boolean>);

class DetectDataChangeFunctions {
  private detectDataChangeFunctionsMap: Map<string, DetectDataChangeFunction> = new Map();
  private openConfirmModal: () => Promise<boolean>;

  constructor() {
    // 設定されなかったら常に「廃棄する」を返す
    this.openConfirmModal = async () => true;
  }

  registerOpenConfirmModal(openConfirmModal: () => Promise<boolean>) {
    this.openConfirmModal = openConfirmModal;
  }

  unregisterOpenConfirmModal() {
    this.openConfirmModal = async () => true;
  }

  add(url: string, confirmFunction: DetectDataChangeFunction) {
    this.detectDataChangeFunctionsMap.set(url, confirmFunction);
  }

  remove(url: string) {
    this.detectDataChangeFunctionsMap.delete(url);
  }

  get(url: string) {
    return this.detectDataChangeFunctionsMap.get(url);
  }

  getAll() {
    return Array.from(this.detectDataChangeFunctionsMap.values());
  }

  removeAll() {
    this.detectDataChangeFunctionsMap.clear();
  }

  /**
   * 全ての廃棄確認ができたらtrueを返す
   */
  async confirmDiscard() {
    const allDetectDataChangeFunctions = this.getAll();
    const anyDataDirty = (
      await Promise.all(
        allDetectDataChangeFunctions.map((detectDataChangeFunction) => detectDataChangeFunction())
      )
    ).some(Boolean);

    if (anyDataDirty) {
      return await this.openConfirmModal();
    }

    return true;
  }
}

export const detectDataChangeFunctions = new DetectDataChangeFunctions();
