import clsx from "clsx";

import { CartModel } from "@/models/cart/type";
import { formatCurrency } from "@/utils/currency";

import styles from "./ProductGroup.module.scss";
import { ProductList } from "../ProductList";

interface ProductGroupProps {
  cart: CartModel;
  isFreeze?: boolean;
}

export function ProductGroup({ cart, isFreeze }: ProductGroupProps): React.ReactNode {
  // 色を変えたいので、常温の場合だけ配送料がかかるかチェックする
  const existShippingFee = !isFreeze && cart.normalShippingFee > 0;

  const products = (isFreeze ? cart.freezeProducts : cart.normalProducts) ?? [];

  return (
    <div className={clsx("mg__top__m mg__bottom__m", isFreeze ? styles.freeze : styles.normal)}>
      <p
        className={clsx(
          "text__m text__bold text__white",
          styles.title,
          isFreeze ? styles.freezeColor : styles.normalColor
        )}
      >
        {isFreeze ? "冷凍配送" : "常温配送"}
      </p>

      <ProductList products={products} isFirstSubscription={cart.isFirstSubscriptionOrder} />

      <dl className="table__dl pd__bottom__s pd__top__m">
        <dt>
          <p className="text__m">小計：</p>
        </dt>
        <dd className="text__right">
          <span className="text__l text__bold">
            {formatCurrency(isFreeze ? cart.freezeProductTotalPrice : cart.normalProductTotalPrice)}
          </span>
        </dd>
      </dl>

      <dl className="table__dl pd__bottom__s">
        <dt>
          <p className="text__m">配送料：</p>
        </dt>
        <dd className="text__right">
          <span className={clsx("text__l text__bold", existShippingFee && "text__red")}>
            {formatCurrency(isFreeze ? cart.freezeShippingFee : cart.normalShippingFee)}
            <sup className="sup">**</sup>
          </span>
        </dd>
      </dl>
    </div>
  );
}
