export interface OnePageCheckoutFormTitleProps {
  isInvited?: boolean;
  coupon?: string;
}

export function OnePageCheckoutFormTitle({
  isInvited,
  coupon,
}: OnePageCheckoutFormTitleProps): React.ReactNode {
  return (
    <div className="text__center mg__bottom__l">
      {coupon === "CKONY2" ? (
        <h2 className="text__xl text__bold">お申し込みはこちらのフォームから</h2>
      ) : isInvited ? (
        <h2 className="text__xl text__bold">お申し込みフォーム</h2>
      ) : (
        <h3 className="text__xl text__bold">お申し込みはこちらのフォームから</h3>
      )}
    </div>
  );
}
