import { type PropsWithChildren } from "react";

export interface MileIconProps {
  className?: string;
}

export function MileIcon({ className }: PropsWithChildren<MileIconProps>): React.ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="#8CB4DC"
      />
      <path
        d="M5.6 17.2762L7.47834 6.4H9.50817L12.0227 13.9891L14.5676 6.4H16.5671L18.4 17.2762H16.4005L15.2341 10.3991L12.9165 17.2762H11.0987L8.81136 10.3991L7.61467 17.2762H5.6Z"
        fill="white"
      />
    </svg>
  );
}
