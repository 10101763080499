import clsx from "clsx";

import styles from "./TermsAccordion.module.scss";

interface TermsAccordionProps {
  title: string;
  show: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

export function TermsAccordion({
  title,
  show,
  onClick,
  children,
}: TermsAccordionProps): React.ReactNode {
  return (
    <dl className={styles.container}>
      <dt className={clsx("bg__yellow text__bold", styles.title)} onClick={onClick}>
        {title}
        <i className={clsx("fas fa-plus", styles.icon)} />
      </dt>
      {show && <dd className={clsx(styles.content, "bg__gray")}>{children}</dd>}
    </dl>
  );
}
