/**
 * クライアントサイドかどうかを判定する
 */
export const isClient = typeof window !== "undefined";

/**
 * 引数のオブジェクトをクライアントサイドのみでアクセス可能なオブジェクトに変換する
 * NextJSでは、クライアント・サーバーの両方でコードを実行するため、この関数を使用することで、クライアントサイドのみでアクセス可能なオブジェクトを作成することができる
 * グローバル変数を宣言する場合などに使用する
 * @param obj
 * @returns
 */
export const onlyClient = <T extends object>(obj: T) => {
  const handler = {
    get(target: T, prop: string | symbol) {
      if (!isClient) throw new Error("This can be accessed only on the client side.");
      return target[prop as keyof T] as T[keyof T];
    },
    set(target: T, prop: string | symbol, value: T[keyof T]) {
      if (!isClient) throw new Error("This can be accessed only on the client side.");
      target[prop as keyof T] = value;
      return true;
    },
  };
  return new Proxy(obj, handler);
};
