import { Image } from "@/components";

export interface LineupSectionItemProps {
  category: string;
  title: string;
  imageUrl: string;
  description: string;
}

export function LineupSectionItem({
  category,
  title,
  imageUrl,
  description,
}: LineupSectionItemProps): React.ReactNode {
  return (
    <>
      <Image src={imageUrl} alt={title} />
      <p className="text__s text__bold text__white text__center mg__top__s">
        BASE {category}
        <sup className="sup">
          <i className="far fa-registered fa-fw"></i>
        </sup>
      </p>
      <p className="text__m text__bold text__center mg__bottom__s">{title}</p>
      <p className="text__m">{description}</p>
    </>
  );
}
