import { useMemo } from "react";

import { useGetMileGifts } from "@/generated/open-api/mile/mile";
import { convertObjToCamelCase } from "@/utils/converters";

export const useParsedGetMileGifts = () => {
  const { data, ...rest } = useGetMileGifts();

  const formattedData = useMemo(() => {
    if (!data) return;

    const formatted = convertObjToCamelCase(data);

    return formatted;
  }, [data]);

  return { data: formattedData?.mileGifts, ...rest };
};
