import { useMemo } from "react";

import { useGetMilesSuspense } from "@/generated/open-api/customer/customer";
import { convertApiMileToMile } from "@/models/mile/converters";

export const useParsedGetMiles = () => {
  const { data, ...rest } = useGetMilesSuspense();
  const parsed = useMemo(() => convertApiMileToMile(data), [data]);

  // 新ランクFirst以上あるいは旧ランクGold以上の場合、新商品発売1ヶ月以内30%オフ
  const isDiscountNewProductRank = useMemo(
    () =>
      ["First", "Vip"].includes(parsed.rankName) ||
      ["Gold", "Diamond"].includes(parsed.rankNameOld),
    [parsed.rankName, parsed.rankNameOld]
  );

  // 旧マイルプログラムでシルバーランク以上だったかどうか
  const isLegacySilverRankOrHigher = useMemo(
    () => ["Silver", "Gold", "Diamond"].includes(parsed.rankNameOld),
    [parsed.rankNameOld]
  );

  return { data: parsed, isDiscountNewProductRank, isLegacySilverRankOrHigher, ...rest };
};
