/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

export type SignupPassword200Status =
  (typeof SignupPassword200Status)[keyof typeof SignupPassword200Status];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SignupPassword200Status = {
  ok: "ok",
} as const;
