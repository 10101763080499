export type MoreVertIconProps = React.ComponentProps<"svg">;

export function MoreVertIcon({ ...rest }: MoreVertIconProps) {
  return (
    <svg
      width="8"
      height="44"
      viewBox="0 0 8 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#5A5A5A"
      {...rest}
    >
      <circle cx="4" cy="4" r="4" fill="currentColor" />
      <circle cx="4" cy="22" r="4" fill="currentColor" />
      <circle cx="4" cy="40" r="4" fill="currentColor" />
    </svg>
  );
}
