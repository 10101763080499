import clsx from "clsx";

import styles from "./InviteLimitReachedAlert.module.scss";

export function InviteLimitReachedAlert(): React.ReactNode {
  return (
    <div className={clsx("bg__white", styles.container)}>
      <p className="text__m text__bold text__red text__center">
        紹介人数の上限に達したため、
        <br />
        紹介URLが無効になっております。
      </p>
      <p className="text__s mg__top__s">
        2億袋突破送料無料キャンペーン期間中（2024年7月18日〜8月15日）は、紹介人数を制限させていただいております。キャンペーン終了後は制限が解除され、通常通りご利用いただけます。
      </p>
    </div>
  );
}
