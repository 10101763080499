import { forwardRef, useCallback } from "react";

import clsx from "clsx";

import styles from "./AcceptTermsInput.module.scss";

interface AcceptTermsInputProps {
  value: boolean;
  onChange: (value: boolean) => void;
  onClickLink: () => void;
}

export const AcceptTermsInput = forwardRef<HTMLInputElement, AcceptTermsInputProps>(
  function AcceptTermsInput(
    { value, onChange, onClickLink }: AcceptTermsInputProps,
    ref
  ): React.ReactNode {
    const handleInputChange = useCallback(() => {
      onChange(!value);
    }, [value, onChange]);

    return (
      <>
        <input
          id="accepts_terms"
          checked={value}
          type="checkbox"
          name="checkout_buyer_accepts_terms"
          className={clsx("checkbox__input", styles.checkboxInput)}
          onChange={handleInputChange}
          ref={ref}
        />
        <span
          role="link"
          onClick={onClickLink}
          className={clsx("text__s mg__top__s", styles.checkoutTermsButton)}
        >
          利用規約及びプライバシーポリシー
        </span>
        <label htmlFor="accepts_terms" className={clsx("text__s mg__top__s", styles.acceptTerms)}>
          に同意する
        </label>
      </>
    );
  }
);
