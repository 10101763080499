import { type RecommendSet } from "./types";

export const INVITE_SUBSCRIPTION_COUPON_DISCOUNT_REQUIRED: RecommendSet[] = [
  {
    name: "パン16袋セット",
    short_name: "パン 16袋",
    products: [
      { sku: "102008", quantity: 4 },
      { sku: "102009", quantity: 2 },
      { sku: "102010", quantity: 2 },
      { sku: "102011", quantity: 2 },
      { sku: "102012", quantity: 2 },
      { sku: "102013", quantity: 2 },
      { sku: "102014", quantity: 2 },
    ],
    is_best_value: false,
    subscription: true,
    images: [
      {
        src: "https://asset.basefood.co.jp/lp/invite/set1_bread_0626.png",
        aspectRatio: "542 / 701",
      },
    ],
  },
  {
    name: "パン8袋＆クッキー12袋セット",
    short_name: "パン＆クッキー 12袋",
    products: [
      { sku: "102008", quantity: 2 },
      { sku: "102009", quantity: 2 },
      { sku: "102011", quantity: 2 },
      { sku: "102014", quantity: 2 },
      { sku: "104005", quantity: 4 },
      { sku: "104006", quantity: 4 },
      { sku: "104007", quantity: 4 },
    ],
    is_best_value: false,
    subscription: true,
    images: [
      {
        src: "https://asset.basefood.co.jp/lp/invite/set2_bread_0626.png",
        aspectRatio: "559 / 461",
      },
      {
        src: "https://asset.basefood.co.jp/lp/invite/set2_cookies_1024.png",
        aspectRatio: "279 / 110",
      },
    ],
  },
  {
    name: "パン32袋セット",
    short_name: "パン 32袋",
    products: [
      { sku: "102008", quantity: 6 },
      { sku: "102009", quantity: 6 },
      { sku: "102010", quantity: 6 },
      { sku: "102011", quantity: 4 },
      { sku: "102013", quantity: 6 },
      { sku: "102014", quantity: 4 },
    ],
    is_best_value: true,
    subscription: true,
    images: [
      {
        src: "https://asset.basefood.co.jp/lp/invite/set3_bread_0626.png",
        aspectRatio: "542 / 461",
      },
    ],
  },
];
