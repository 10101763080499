import React from "react";

import clsx from "clsx";

import styles from "./PrivacyContent.module.scss";
// import styles from "./PrivacyPolicy.module.scss";

export function PrivacyContent(): React.ReactNode {
  return (
    <div>
      <dl className="mg__bottom__m">
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          ベースフード株式会社（以下「当社」といいます。）は、当社の提供する商品及びサービス（以下合わせて「商品等」といいます。）のご購入者様及びサービスの利用者様（以下合わせて「お客様」といいます。）についての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第1条 法令等の遵守</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          当社は、個人情報保護の実現のため、個人情報保護法、各省庁ガイドラインその他関連する法令等を遵守いたします。
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第2条 収集する利用者情報及び収集方法</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          <ul className={styles.policyList}>
            <li>
              1. 利用者情報
              <br />
              本ポリシーにおいて、「利用者情報」とは、商品等ご購入時、お申し込み時あるいは当社ウェブサイト訪問時におけるお客様の情報、お客様の識別に係る情報、通信サービス上の行動履歴、お客様が送受信するメールの情報、その他お客様のスマートフォン、パソコン等の端末においてお客様又はお客様の端末に関連して生成又は蓄積された情報であって、本ポリシーに基づき当社が適正に収集するものをいいます。
            </li>
            <li>
              2. 収集方法
              <br />
              当社は、以下の方法で利用者情報を取得します。
              <ul className={styles.policyList}>
                <li>① お客様から直接、利用者情報の提供を受ける方法</li>
                <li>
                  ②
                  お客様が当社サービスを利用する際に、お客様自身に直接ご入力いただき利用者情報をご登録いただく方法
                </li>
                <li>③ お客様による当社サービスの利用・閲覧の際に収集する方法</li>
                <li>④ 第三者から間接的にお客様の利用者情報の提供を受ける方法</li>
                <li>⑤ 刊行物やインターネット等で公開された利用者情報を取得する方法</li>
              </ul>
            </li>
          </ul>
          なお、当社は、その情報単体では個人情報に該当しない、属性情報（例：年齢・性別・職業・居住地域など）、Cookie情報、アクセスログ、IPアドレス、広告識別子（AAID・IDFA）及び、位置情報・行動履歴・インターネットの利用にかかるログ情報等の個人に関する情報（以下総称して「インフォマティブデータ」といいます。）をお客様又は第三者（パブリックDMP事業者及びアフィリエイト・サービス・プロバイダを含みますが、これらに限りません。）から取得しています。お客様が当社サービスの利用にあたり当社に個人情報を提供した場合、当社は、当該情報と、お客様のインフォマティブデータを紐付ける場合がありますが、この場合、当該インフォマティブデータも個人情報として取り扱います。
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第3条 情報の安全管理</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          <ul className={styles.policyList}>
            <li>
              1.
              当社は、利用者情報を利用目的の範囲内で正確かつ最新の状態に保つよう努め、利用者情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な安全管理措置を講じ、利用者情報の厳重な管理を行います。
            </li>
            <li>
              2.
              当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
            </li>
          </ul>
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第4条 利用目的</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          当社は、利用者情報を以下の目的で利用します。
          <ul className={clsx(styles.policyList, "mg__top__m")}>
            <li>1. 商品等の提供、維持、保護及び改善のため</li>
            <li>2. 商品等に関するご案内、お問い合せ等への対応のため</li>
            <li>3. 商品等に関する契約、規約、ポリシー等に違反する行為に対する対応のため</li>
            <li>4. 商品等に関する規約、ポリシー等の変更などを通知するため</li>
            <li>
              5.
              商品等、機能改善等お客様に有用と思われる情報提供のための郵便物、電話、電子メール等による営業活動及びマーケティング活動のため
            </li>
            <li>
              6.
              アンケート調査・モニター調査の実施（効果捕捉のための回答者への直接の連絡を含みます）、その他各種ご協力依頼や各種イベントへの参加を依頼し、その結果などを報告するため
            </li>
            <li>7. サービスの提供に当たり利用目的を公表の上、お客様の同意を得た利用目的のため</li>
            <li>
              8.
              利用者の当社ウェブサイトへのアクセス履歴、購入履歴を含む利用者の行動、入力情報その他の情報を使用して、ターゲティング広告等の広告を配信するため（当社の委託先又は取引先の第三者配信事業者が広告を配信する場合を含みます）
              <br />※
              Facebook・LINE・Xその他の広告関連事業者が保有するユーザーリストと当社が保有するメールアドレス・電話番号その他連絡先の情報を紐付けて、当社サービスのお客様のうち、当該広告関連事業者が提供するサービスを利用する広告関連事業者のユーザーを特定して当該サービス上でお客様に各種サービスの提案・情報提供・広告配信を行う場合があります
            </li>
            <li className="mg__top__s">
              9.
              お客様の趣向や、お客様の当社商品等に対する満足度等の把握のための当社が取得した属性情報・行動履歴・インフォマティブデータ等の分析
              <br />※
              当社が広告関連事業者に分析業務を委託する場合、当社が保有する個人情報に当該広告関連事業者が保有するユーザーの属性情報や位置情報・行動履歴といったインターネットの利用にかかるログ情報を付加して、当該情報のみでは当社において特定の個人を識別することができない情報に集計した分析結果を受領することがあります
            </li>
            <li className="mg__top__s">10. 契約や法律等に基づく権利の行使や義務の履行のため</li>
            <li>11. アフターサービス、各種問い合わせ対応のため</li>
            <li>12. 上記の利用目的に付随する利用目的のため</li>
            <li>
              13.
              お客様にメールマガジン、チラシ等の方法により当社提携先事業者の商品またはサービスに関する広告を送付または表示等するため
            </li>
          </ul>
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第5条 利用目的の変更</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          当社は、利用者情報の利用目的を相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に通知又は公表します。
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第6条 個人情報の定義</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          本ポリシーにおいて、利用者情報のうち、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、次の各号のいずれかに該当するものを意味するものとします。
        </dd>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          1.
          当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）
        </dd>
        <dd className={clsx("text__m", "text__justify")}>2. 個人識別符号が含まれるもの</dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第7条 個人情報利用の制限</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          当社は、個人情報保護法その他の法令により許容される場合を除き、お客様の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
          <ul className={clsx(styles.policyList, "mg__top__s")}>
            <li>1. 法令に基づく場合</li>
            <li>
              2.
              人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき
            </li>
            <li>
              3.
              公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき
            </li>
            <li>
              4.
              国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            </li>
            <li>
              5.
              商品等の提供において当社が必要と判断した際、弁護士等、当社に対して秘密保持義務を負う者に対して開示するとき
            </li>
          </ul>
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第8条 個人情報の第三者提供</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          <ul className={styles.policyList}>
            <li>
              1.
              当社は、以下の提供先に対して各号に従い、書面または電磁的な方法により個人情報を提供します。なお、提供する個人情報の項目には、インフォマティブデータを含む場合があります。
              <ul className={styles.policyList}>
                <li>
                  ■ 当社の広告の配信業務に関わる第三者に対する提供
                  <ul className={styles.policyList}>
                    <li>
                      ① 提供目的
                      <br />
                      利用者にどのような広告や情報、サービスなどを掲載又は提供するのが効果的であるかを判断させるため又はターゲティング広告等の広告配信のため
                    </li>
                    <li>
                      ② 提供先
                      <br />
                      当社の広告の配信業務に関わる第三者
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="mg__top__s">
              2.
              当社は、個人情報については、前項に定める場合及び個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめお客様の同意を得ないで、第三者に提供しません。但し、次に掲げる場合はこの限りではありません。
              <ul className={styles.policyList}>
                <li>
                  ①
                  当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合。なお、委託先への個人情報の提供が必要になった際には、委託先に対し情報管理に関する適切な監督を行ったうえで提供を行います。
                </li>
                <li>② 合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                <li>
                  ③
                  国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
                </li>
                <li>
                  ④
                  当社が運営するサービスの利用状況等を調査・分析するため、当社がかかる調査・分析等を委託する第三者に法令で認められる範囲で開示する場合
                </li>
                <li>⑤ その他、個人情報保護法その他の法令で認められる場合</li>
              </ul>
            </li>
          </ul>
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第9条 個人情報の開示</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          当社は、お客様から、個人情報保護法に基づき、個人情報の開示を求められたときは、そのお客様本人からの請求であることを確認の上で、そのお客様に対し、遅滞なく開示を行います（当該個人情報が存在しないとき、その他開示しないときにはその旨を通知いたします）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>
          第10条 個人情報の訂正、利用停止及び第三者への提供停止等
        </dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          <ul className={styles.policyList}>
            <li>
              1.当社は、お客様から、個人法保護法に基づき、⑴個人情報が真実でないという理由によりその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合、⑵あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により収集されたものであるという理由により、その利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合及び⑶同法又は第8条に違反して第三者に提供されているという理由により第三者への提供の停止を求められた場合は、お客様本人からの請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等、利用停止等又は第三者への提供停止を行い、その旨をお客様に通知します。なお、合理的な理由に基づいて訂正等、利用停止等又は第三者への提供停止を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。但し、個人情報保護法その他の法令により、当社が訂正等利用停止等又は第三者への提供停止の義務を負わない場合は、この限りではありません。
            </li>
            <li>
              2.当社は、お客様から、個人情報保護法に基づき、お客様の個人情報について利用目的の通知を求められた場合、お客様本人からの請求であることを確認の上で、個人情報の利用目的をお客様に通知します。なお、合理的な理由に基づいて利用目的の通知を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。但し、個人情報保護法その他の法令により、当社が利用目的の通知の義務を負わない場合は、この限りではありません。
            </li>
          </ul>
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第11条 商品等の取り扱い終了後の扱い</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          商品等の取り扱い終了等があったとしても、その時点までに当社が取得した各種情報について、当社は引き続き保存・利用できるものとします。
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>
          第12条 Cookie（クッキー）その他の技術の利用
        </dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          商品等において、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当社によるお客様の商品等の購入あるいは利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいお客様は、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、商品等の一部の機能をご利用いただけなくなる場合があります。
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第13条 お問い合わせ窓口</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          個人情報の利用目的の通知、開示、訂正等、利用停止等第三者提供の停止のお申し出、商品等に関するご意見、ご質問、苦情その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。
          <div className={clsx("policy__contact", "mg__top__m", "mg__bottom__m")}>
            <p>〒153-0061</p>
            <p>東京都目黒区中目黒5-25-2</p>
            <p className="text__bold">ベースフード株式会社 プライバシーポリシー管理担当</p>
            <a
              className={clsx("btn", "btn__l", "inline", "round", "yellow", "mg__top__s")}
              href="https://basefood.zendesk.com/hc/ja/requests/new"
            >
              お問い合わせはこちら
            </a>
          </div>
          なお、受付時間は、平日10時から18時までとさせていただきます。
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dt className={clsx("text__l", "text__bold")}>第14条 プライバシーポリシーの変更手続</dt>
        <dd className={clsx("text__m", "text__justify", "mg__top__s")}>
          <ul className={styles.policyList}>
            <li>
              1.
              当社は、利用者情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーを変更することがあります。
            </li>
            <li>
              2.
              変更した場合には、メールもしくは商品等を扱うサイトに掲載する方法でお客様に通知いたします。本ポリシーの変更内容は、商品等サイトに掲載された時点から変更の効力が生じます。変更の効力が生じた後、商品等を購入あるいは利用したお客様は、本ポリシーの変更内容に同意したものとみなします。
            </li>
            <li>
              3.
              前２項の規定にかかわらず、法令上、お客様の同意が必要となるような内容の変更の場合は、当社所定の方法でお客様の同意を得るものとします。
            </li>
          </ul>
        </dd>
      </dl>
      <dl className={clsx("mg__top__l", "mg__bottom__m")}>
        <dd className="text__m">
          2018年5月1日制定
          <br />
          2020年10月27日改定
          <br />
          2022年4月1日改定
          <br />
          2022年12月15日改定
          <br />
          2024年10月30日改定
        </dd>
      </dl>
    </div>
  );
}
