import { useEffect } from "react";

import { DetectDataChangeFunction, detectDataChangeFunctions } from "./detectDataChangeFunctions";

/**
 *
 * @param url 遷移先のURL（キーとして使う）
 * @param detectDataChangeFunc trueを返すとデータが変更されていると判断される関数
 */
export const useRegisterDetectDataChangeFunctions = (
  url: string,
  detectDataChangeFunc: DetectDataChangeFunction
) => {
  useEffect(() => {
    detectDataChangeFunctions.add(url, detectDataChangeFunc);

    return () => {
      detectDataChangeFunctions.remove(url);
    };
  }, [detectDataChangeFunc, url]);
};
