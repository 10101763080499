import { useMemo } from "react";

import { useGetFreezeSkipSurvey, useGetNormalSkipSurvey } from "@/generated/open-api/survey/survey";
import { convertApiSurveyResponseToSurveyResponse } from "@/models/survey/converters";

type Temperature = "normal" | "frozen";

export const useParsedGetSkipSurvey = (temperature: Temperature) => {
  const normalRes = useGetNormalSkipSurvey({
    query: { enabled: temperature === "normal" },
  });
  const freezeRes = useGetFreezeSkipSurvey({
    query: { enabled: temperature === "frozen" },
  });

  return useMemo(() => {
    if (temperature === "normal") {
      return { ...normalRes, data: convertApiSurveyResponseToSurveyResponse(normalRes.data) };
    } else {
      return { ...freezeRes, data: convertApiSurveyResponseToSurveyResponse(freezeRes.data) };
    }
  }, [freezeRes, normalRes, temperature]);
};
