"use client";
import { useMemo } from "react";

import { useCookies } from "react-cookie";

const isServer = typeof window === "undefined";

// クッキーをオブジェクトに変換する関数
const parseCookies = (cookieArray: { name: string; value: string }[]) => {
  return cookieArray.reduce(
    (acc, { name, value }) => {
      acc[name] = value;
      return acc;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as { [x: string]: any }
  );
};

/**
 * クライアントサイド、サーバーサイド(use client)の両方でクッキーを取得するカスタムフック
 * @warning
 * - サーバーコンポーネントでは利用できません。`next/headers`の`cookies`をご利用ください。
 * - サーバーサイドでのみ利用可能なクッキーを取得する場合は、`next/headers`の`cookies`をご利用ください。
 * @returns
 */
export function useCookiesAnywhere(dependencies?: string[]) {
  const [clientCookies, setCookie, removeCookie] = useCookies(dependencies);

  const cookies: Record<string, string | undefined> = useMemo(() => {
    if (isServer) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const serverCookies = parseCookies(require("next/headers").cookies().getAll());
      return serverCookies;
    } else {
      return clientCookies;
    }
  }, [clientCookies]);

  return [cookies, setCookie, removeCookie] as const;
}
