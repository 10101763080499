/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

export type ServerErrorResponseStatus =
  (typeof ServerErrorResponseStatus)[keyof typeof ServerErrorResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServerErrorResponseStatus = {
  ng: "ng",
} as const;
