/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  QueryClient,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type { GetProductsByNamesParams, GetProductsSkusParams, Product } from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get product information by product name
 */
export const getProductsName = (
  name: string,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<Product>(
    { url: `/products/name/${name}`, method: "GET", signal },
    options
  );
};

export const getGetProductsNameQueryKey = (name: string) => {
  return [`/products/name/${name}`] as const;
};

export const getGetProductsNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsName>>,
  TError = ErrorType<unknown>,
>(
  name: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductsName>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductsNameQueryKey(name);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsName>>> = ({ signal }) =>
    getProductsName(name, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!name,
    staleTime: 30000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getProductsName>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetProductsNameQueryResult = NonNullable<Awaited<ReturnType<typeof getProductsName>>>;
export type GetProductsNameQueryError = ErrorType<unknown>;

/**
 * @summary get product information by product name
 */
export const useGetProductsName = <
  TData = Awaited<ReturnType<typeof getProductsName>>,
  TError = ErrorType<unknown>,
>(
  name: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductsName>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsNameQueryOptions(name, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get product information by product name
 */
export const prefetchGetProductsName = async <
  TData = Awaited<ReturnType<typeof getProductsName>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  name: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductsName>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetProductsNameQueryOptions(name, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetProductsNameSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsName>>,
  TError = ErrorType<unknown>,
>(
  name: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProductsName>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductsNameQueryKey(name);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsName>>> = ({ signal }) =>
    getProductsName(name, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!name,
    staleTime: 30000,
    ...queryOptions,
  } as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProductsName>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetProductsNameSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductsName>>
>;
export type GetProductsNameSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get product information by product name
 */
export const useGetProductsNameSuspense = <
  TData = Awaited<ReturnType<typeof getProductsName>>,
  TError = ErrorType<unknown>,
>(
  name: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProductsName>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsNameSuspenseQueryOptions(name, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get product information by product name
 */
export const prefetchGetProductsNameSuspense = async <
  TData = Awaited<ReturnType<typeof getProductsName>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  name: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProductsName>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetProductsNameSuspenseQueryOptions(name, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get product information by product names
 */
export const getProductsByNames = (
  params: GetProductsByNamesParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<Product[]>(
    { url: `/products/names`, method: "GET", params, signal },
    options
  );
};

export const getGetProductsByNamesQueryKey = (params: GetProductsByNamesParams) => {
  return [`/products/names`, ...(params ? [params] : [])] as const;
};

export const getGetProductsByNamesQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsByNames>>,
  TError = ErrorType<unknown>,
>(
  params: GetProductsByNamesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductsByNames>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductsByNamesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsByNames>>> = ({ signal }) =>
    getProductsByNames(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProductsByNames>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProductsByNamesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductsByNames>>
>;
export type GetProductsByNamesQueryError = ErrorType<unknown>;

/**
 * @summary get product information by product names
 */
export const useGetProductsByNames = <
  TData = Awaited<ReturnType<typeof getProductsByNames>>,
  TError = ErrorType<unknown>,
>(
  params: GetProductsByNamesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductsByNames>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsByNamesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get product information by product names
 */
export const prefetchGetProductsByNames = async <
  TData = Awaited<ReturnType<typeof getProductsByNames>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetProductsByNamesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductsByNames>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetProductsByNamesQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetProductsByNamesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsByNames>>,
  TError = ErrorType<unknown>,
>(
  params: GetProductsByNamesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProductsByNames>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductsByNamesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsByNames>>> = ({ signal }) =>
    getProductsByNames(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getProductsByNames>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProductsByNamesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductsByNames>>
>;
export type GetProductsByNamesSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get product information by product names
 */
export const useGetProductsByNamesSuspense = <
  TData = Awaited<ReturnType<typeof getProductsByNames>>,
  TError = ErrorType<unknown>,
>(
  params: GetProductsByNamesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProductsByNames>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsByNamesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get product information by product names
 */
export const prefetchGetProductsByNamesSuspense = async <
  TData = Awaited<ReturnType<typeof getProductsByNames>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetProductsByNamesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProductsByNames>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetProductsByNamesSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get product information by product skus
 */
export const getProductsSkus = (
  params: GetProductsSkusParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<Product[]>(
    { url: `/products/skus`, method: "GET", params, signal },
    options
  );
};

export const getGetProductsSkusQueryKey = (params: GetProductsSkusParams) => {
  return [`/products/skus`, ...(params ? [params] : [])] as const;
};

export const getGetProductsSkusQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsSkus>>,
  TError = ErrorType<unknown>,
>(
  params: GetProductsSkusParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductsSkus>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductsSkusQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsSkus>>> = ({ signal }) =>
    getProductsSkus(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProductsSkus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProductsSkusQueryResult = NonNullable<Awaited<ReturnType<typeof getProductsSkus>>>;
export type GetProductsSkusQueryError = ErrorType<unknown>;

/**
 * @summary get product information by product skus
 */
export const useGetProductsSkus = <
  TData = Awaited<ReturnType<typeof getProductsSkus>>,
  TError = ErrorType<unknown>,
>(
  params: GetProductsSkusParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductsSkus>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsSkusQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get product information by product skus
 */
export const prefetchGetProductsSkus = async <
  TData = Awaited<ReturnType<typeof getProductsSkus>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetProductsSkusParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductsSkus>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetProductsSkusQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetProductsSkusSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsSkus>>,
  TError = ErrorType<unknown>,
>(
  params: GetProductsSkusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProductsSkus>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductsSkusQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsSkus>>> = ({ signal }) =>
    getProductsSkus(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getProductsSkus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProductsSkusSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductsSkus>>
>;
export type GetProductsSkusSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get product information by product skus
 */
export const useGetProductsSkusSuspense = <
  TData = Awaited<ReturnType<typeof getProductsSkus>>,
  TError = ErrorType<unknown>,
>(
  params: GetProductsSkusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProductsSkus>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsSkusSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get product information by product skus
 */
export const prefetchGetProductsSkusSuspense = async <
  TData = Awaited<ReturnType<typeof getProductsSkus>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetProductsSkusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getProductsSkus>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetProductsSkusSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};
