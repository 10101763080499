import { AddressValues } from "@/components/domains/checkout-form";

interface LocationSectionProps {
  shippingAddress: Partial<AddressValues>;
}

export function LocationSection({ shippingAddress }: LocationSectionProps): React.ReactNode {
  return (
    <section className="pd__top__m">
      <div className="row">
        <div className="col-12">
          <p className="text__l text__bold mg__bottom__xs">配送先住所</p>
          <p className="text__m ">{shippingAddress.zip}</p>
          <p className="text__m ">
            {shippingAddress.city}
            {shippingAddress.addressLine1}
            {shippingAddress.addressLine2}
          </p>
          <p className="text__m ">{shippingAddress.phone}</p>
          <p className="text__m text__bold">
            {shippingAddress.lastName} {shippingAddress.firstName}
          </p>
        </div>
      </div>
    </section>
  );
}
