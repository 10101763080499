import { Nutrient } from "@/generated/open-api/schemas";

import { NutrientModel } from "./type";

export const convertApiNutrientToNutrient = (apiNutrient: Nutrient): NutrientModel => {
  return {
    ingredient: apiNutrient.ingredient!,
    ingredientEn: apiNutrient.ingredient_en!,
    ingredientNum: apiNutrient.ingredient_num!,
    notationUnit: apiNutrient.notation_unit!,
    nutrient: apiNutrient.nutrient!,
    unitAnnotation: apiNutrient.unit_annotation!,
  };
};
