import { ProductLargeImage } from "@/components/domains";

export function NewLaunchedUmakaramazesoba(): React.ReactNode {
  return (
    <ProductLargeImage
      url="/products/deli/basepasta/umakaramazesoba"
      pcImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_mazesoba_W1920_H1080.jpg",
        alt: "BASE PASTA 旨辛まぜそば",
        aspectRatio: "1920/1080",
      }}
      spImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_mazesoba_W1080_H1080.jpg",
        alt: "BASE PASTA 旨辛まぜそば",
        aspectRatio: "1080/1080",
      }}
    />
  );
}
