import { forwardRef } from "react";
import type { HTMLProps } from "react";

import clsx from "clsx";

import styles from "./TextField.module.scss";

export interface TextFieldProps extends HTMLProps<HTMLInputElement> {
  label?: string;
  helperText?: string;
  error?: boolean;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(function TextField(
  { id, className, label, helperText, error, ...restProps },
  ref
): React.ReactNode {
  return (
    <div className="mg__bottom__m">
      {label && (
        <label className="text__m" htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        type="text"
        ref={ref}
        className={clsx("mg__bottom__xs mg__top__xs", styles.input, className, {
          [styles.inputError]: error,
        })}
        {...restProps}
      />
      {helperText && <p className={clsx("text__s", { text__red: error })}>{helperText}</p>}
    </div>
  );
});
