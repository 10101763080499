"use client";
import { SalesType } from "@/models/product/type";

import { LocalStagePath, NuxtStoreCart } from "./types";
import { setLocalStorageValue, getLocalStorageValue } from "./useClientLocalStorage/helpers";

const targetStoreKeys: LocalStagePath[] = [
  `vuex.cart.cart`,
  `vuex.front-store.cartItems`,
  `vuex.cart.form`,
];

const cartStoreKey = `vuex.cart.cart`;

export const clearClientOutletCart = () => {
  const cart = getLocalStorageValue<NuxtStoreCart["cart"] | undefined>(cartStoreKey);

  const hasOutlet = cart?.products?.some((product) => product.sales_type === SalesType.outlet);
  if (hasOutlet) {
    targetStoreKeys.forEach((storeKey) => {
      setLocalStorageValue(storeKey, undefined);
    });
  }
};
