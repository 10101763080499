import clsx from "clsx";

import { CheckoutFormValues } from "@/components/domains/checkout-form";
import { CartModel } from "@/models/cart/type";
import { deliveryReceiveOptionLabels } from "@/models/delivery/consts";
import { getDeliveryTimeZoneLabel } from "@/models/delivery/converters";

import styles from "./DeliverySection.module.scss";

interface DeliverySectionProps {
  cart: CartModel;
  form: CheckoutFormValues;
}

export function DeliverySection({ cart, form }: DeliverySectionProps): React.ReactNode {
  return (
    <section className="pd__top__m">
      <div className="row">
        <div className="col-12">
          <p className="text__l text__bold mg__bottom__xs">配送方法</p>
          <p className="mg__bottom__s">
            {cart.normalProductTotalPrice > 0 && (
              <span
                className={clsx(
                  "tag inline yellow text__white mini square",
                  styles.temperatureTagFirst
                )}
              >
                常温配送
              </span>
            )}
            {cart.freezeProductTotalPrice > 0 && (
              <span
                className={clsx(
                  "tag inline darkblue text__white mini square",
                  styles.temperatureTagFirst
                )}
              >
                冷凍配送
              </span>
            )}
          </p>
          <dl className={clsx("mg__bottom__xs", styles.table)}>
            <dt className={styles.cell}>
              <p className="text__m ">配送日：</p>
            </dt>
            <dd className={clsx("text__right", styles.cell)}>
              {form.deliveryOptions.deliveryDate && form.deliveryOptions.deliveryTimezone ? (
                <>
                  {form.deliveryOptions.deliveryDate.toLocaleDateString()}{" "}
                  <span>{getDeliveryTimeZoneLabel(form.deliveryOptions.deliveryTimezone)}</span>
                </>
              ) : (
                <p className="text__m ">日付を指定せずにお届け</p>
              )}
            </dd>
          </dl>
          <dl className={clsx("mg__bottom__xs", styles.table)}>
            <dt className={styles.cell}>受取方法：</dt>
            <dd className={clsx("text__right", styles.cell)}>
              {form.deliveryOptions.deliveryReceiveOption
                ? deliveryReceiveOptionLabels[form.deliveryOptions.deliveryReceiveOption]
                : ""}
            </dd>
          </dl>
          <p className="text__s text__gray__dark mg__top__s mg__bottom__m">
            {cart.freezeProductTotalPrice > 0 && cart.normalProductTotalPrice > 0 ? (
              <>
                <span className="text__red">※常温配送と冷凍配送は別便でお届けします。 </span>
                <br />
                ※2回目以降は常温配送と冷凍配送で別々の日時指定が可能になります。
                <br />
                ※2回目以降は常温配送のみ置き配の指定が可能になります。
              </>
            ) : (
              <>
                ※初回は上記でお届けします。2回目以降は初回お届け日より4週間後にお届けします。
                <br />
                ※お届け予定日の5日前までマイページにてお届け日や内容を変更可能です。
              </>
            )}
          </p>
        </div>
      </div>
    </section>
  );
}
