/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

export type RankName = (typeof RankName)[keyof typeof RankName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RankName = {
  ECONOMY: "ECONOMY",
  BUSINESS: "BUSINESS",
  FIRST: "FIRST",
  VIP: "VIP",
} as const;
