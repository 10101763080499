import { TimeStamp } from "@/generated/open-api/schemas";

export const convertDateStrToTimeStamp = <T extends string | undefined>(
  dateStr: T
): T extends undefined ? TimeStamp | undefined : TimeStamp => {
  if (!dateStr) return undefined as T extends undefined ? TimeStamp | undefined : TimeStamp;
  const date = new Date(dateStr);
  return {
    day: date.getDate(),
    dayOfWeek: date.getDay(),
    dayOfYear: 0,
    formatted: dateStr,
    hour: date.getHours(),
    micro: 0,
    minute: date.getMinutes(),
    month: date.getMonth(),
    second: date.getSeconds(),
    timestamp: date.getTime(),
    timezone: { timezone: "Asia/Tokyo", timezone_type: 3 },
    year: date.getFullYear(),
  };
};
