"use client";

import { PropsWithChildren, useCallback } from "react";

import Link, { LinkProps } from "next/link";
import { useRouter } from "next/navigation";

import { detectDataChangeFunctions } from "@/utils/hooks/useRegisterDetectDataChangeFunctions";

export function LinkConfirm({
  href,
  children,
  ...restProps
}: PropsWithChildren<LinkProps>): React.ReactNode {
  const { push } = useRouter();

  const handleClickLink = useCallback(
    async (event: React.MouseEvent<HTMLAnchorElement>) => {
      // デフォルトの挙動をキャンセル
      event.preventDefault();

      // 全ての廃棄確認関数を実行、いずれかがfalseの場合はリンク遷移をキャンセル
      const isDiscardConfirmed = await detectDataChangeFunctions.confirmDiscard();
      if (isDiscardConfirmed) {
        push(href.toString());
      }
    },
    [href, push]
  );

  return (
    <Link {...restProps} href={href} onClick={handleClickLink}>
      {children}
    </Link>
  );
}
