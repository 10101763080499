"use client";
import { forwardRef, useState } from "react";

import clsx from "clsx";

import { Checkbox } from "@/components/inputs";

import styles from "./PasswordInput.module.scss";

type BaseType = React.ComponentProps<"input">;

export interface PasswordInputProps extends BaseType {
  defaultShowPassword?: boolean;
  checkboxClassName?: string;
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  function PasswordInput(
    {
      defaultShowPassword = false,
      placeholder = "英数記号6文字以上",
      className,
      checkboxClassName,
      ...rest
    },
    ref
  ) {
    const [showPassword, setShowPassword] = useState(defaultShowPassword);
    return (
      <>
        <input
          {...rest}
          ref={ref}
          className={clsx("text__m", styles.default, className)}
          placeholder={placeholder}
          type={showPassword ? "text" : "password"}
        />
        <Checkbox
          className={clsx("mg__top__s", checkboxClassName)}
          onChange={(e) => setShowPassword(e.target.checked)}
        >
          パスワードを表示する
        </Checkbox>
      </>
    );
  }
);
