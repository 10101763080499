import { useCallback } from "react";

import clsx from "clsx";

import { Switch } from "@/components/inputs";
import { formatCurrency } from "@/utils/currency";

import styles from "./SubscriptionTogglePanel.module.scss";

type SubscriptionTogglePanelProps = {
  enabled: boolean;
  onChange: (value: boolean) => void;
  firstGapPrice: number;
  afterSecondGapPrice: number;
};

export function SubscriptionTogglePanel({
  enabled,
  onChange,
  firstGapPrice,
  afterSecondGapPrice,
}: SubscriptionTogglePanelProps) {
  const toggleSubscription = useCallback(() => {
    onChange(!enabled);
  }, [enabled, onChange]);

  return (
    <div className={clsx("mg__bottom__m mg__bottom__s__pc", styles.root)}>
      <dl className={styles.toggleSection}>
        <dt className={styles.description}>
          <p className="text__m text__bold mg__bottom__s">継続コースにする</p>
          <p className="text__s text__gray__dark">※カート内のすべての商品に適用されます。</p>
        </dt>
        <dd className={clsx("text__right", styles.toggle)}>
          <Switch value={enabled} onChange={toggleSubscription} />
        </dd>
      </dl>
      <p className="text__red text__bold text__m mg__top__m mg__bottom__s">
        {enabled ? (
          <>
            初回20%OFF、
            <br className="clear__sp" />
            2回目以降10%OFF
          </>
        ) : (
          <>
            初回{formatCurrency(firstGapPrice)}、
            <br className="clear__sp" />
            2回目以降{formatCurrency(afterSecondGapPrice)}お得
          </>
        )}
      </p>
      <p className="text__s">
        1回目お届け以降、いつでも商品内容やお届け日の変更、解約が可能。お手続きがない場合は4週間ごとにお届け。
      </p>
    </div>
  );
}
