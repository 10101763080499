import { useCallback } from "react";

import { useUpdateSubscription } from "@/generated/open-api/subscription/subscription";
import { convertSubscriptionToSubscriptionApi } from "@/models/subscription/converters";
import { SubscriptionModel } from "@/models/subscription/type";

interface UpdateSubscriptionParams {
  data: SubscriptionModel;
}

export const useParsedUpdateSubscription = () => {
  const { mutate, mutateAsync, ...rest } = useUpdateSubscription();

  const updateSubscription = useCallback(
    async (params: UpdateSubscriptionParams) =>
      await mutate({ data: convertSubscriptionToSubscriptionApi(params.data) }),
    [mutate]
  );

  const updateSubscriptionAsync = useCallback(
    async (params: UpdateSubscriptionParams) =>
      await mutateAsync({ data: convertSubscriptionToSubscriptionApi(params.data) }),
    [mutateAsync]
  );

  return { mutate: updateSubscription, mutateAsync: updateSubscriptionAsync, ...rest };
};
