import { useMemo } from "react";

import { useGetMileGiftsTrades } from "@/generated/open-api/mile/mile";
import { convertObjToCamelCase } from "@/utils/converters";

export const useParsedGetMileGiftsTrades = () => {
  const { data, ...rest } = useGetMileGiftsTrades();

  const formattedData = useMemo(() => {
    if (!data) return;

    const formatted = convertObjToCamelCase(data);

    return formatted;
  }, [data]);

  const transformedData = formattedData?.mileGiftsTrades.map((mileGiftTrade) => ({
    ...mileGiftTrade,
    // None かつ isFixed の場合は PartlyShippedと同じ、「配送準備中」として処理する
    deliveryStatus:
      mileGiftTrade.deliveryStatus === "None" && mileGiftTrade.isFixed
        ? "PartlyShipped"
        : mileGiftTrade.deliveryStatus,
  }));

  const normalGiftsTradesInProcess = transformedData?.filter(
    (mileGiftTrade) => !mileGiftTrade.isFreeze && mileGiftTrade.deliveryStatus === "None"
  );
  const freezeGiftsTradesInProcess = transformedData?.filter(
    (mileGiftTrade) => mileGiftTrade.isFreeze && mileGiftTrade.deliveryStatus === "None"
  );
  const hasNormalGiftsTradesInProcess =
    normalGiftsTradesInProcess && normalGiftsTradesInProcess.length > 0;
  const hasFreezeGiftsTradesInProcess =
    freezeGiftsTradesInProcess && freezeGiftsTradesInProcess.length > 0;

  return {
    data: transformedData,
    normalGiftsTradesInProcess,
    freezeGiftsTradesInProcess,
    hasNormalGiftsTradesInProcess,
    hasFreezeGiftsTradesInProcess,
    ...rest,
  };
};
