import clsx from "clsx";
import Link from "next/link";

import { CartBadge } from "@/components";
import { Image } from "@/components/displays";

import styles from "./PCHeader.module.scss";

interface PCHeaderProps {}

export function PCHeader({}: PCHeaderProps): React.ReactNode {
  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-12">
          <div className={styles.headerContainer}>
            <a className={styles.headerHome} href="https://basefood.co.jp">
              <Image
                src="https://asset.basefood.co.jp/images/logo/logo-color.svg"
                alt="ベースフード株式会社"
                size={{ width: 182, height: 30 }}
              />
            </a>

            <nav className={styles.navigation}>
              <ul className={styles.navMenu}>
                <li>
                  <Link
                    className={clsx(styles.navLink, "text__m text__bold text__left")}
                    href="/products/subscription/2"
                  >
                    スタートセット
                  </Link>
                </li>
                <li>
                  <Link className={clsx(styles.navLink, "text__m text__bold")} href="/">
                    商品一覧
                  </Link>
                </li>
                <li>
                  <a
                    className={clsx(styles.navLink, "text__m text__bold text__left")}
                    href="https://basefood.co.jp/brand"
                  >
                    ブランドについて
                  </a>
                </li>
                <li>
                  <a
                    className={clsx(styles.navLink, "text__m text__bold text__left")}
                    href="https://basefood.co.jp/safety"
                  >
                    安全安心への取り組み
                  </a>
                </li>
                <li>
                  <a
                    // ここだけボーダーを入れるデザインになっている
                    className={clsx(styles.navigationIcon, styles.navigationBorder)}
                    href="https://basefood.zendesk.com/hc/ja"
                    target="_blank"
                  >
                    <Image
                      src="https://asset.basefood.co.jp/images/navi/icon-question-pc.svg"
                      alt="ヘルプ"
                      size={{ width: 58, height: 28 }}
                      className={styles.navigationBorderImage}
                    />
                  </a>
                </li>
                <li>
                  <Link className={clsx(styles.navigationIcon)} href="/mypage">
                    <Image
                      src="https://asset.basefood.co.jp/images/navi/icon-user-pc.svg"
                      alt="マイページ"
                      size={{ width: 43, height: 28 }}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    className={clsx(styles.navigationIcon, styles.navigationIconLast)}
                    href="/cart"
                  >
                    <CartBadge
                      src="https://asset.basefood.co.jp/images/navi/icon-cart-pc.svg"
                      width={58}
                      height={28}
                      isPC={true}
                    />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
