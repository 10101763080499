import clsx from "clsx";
import Link from "next/link";

import { Skeleton } from "@/components/displays";
import { useParsedGetPoints } from "@/queries";
import { withSuspense } from "@/utils";

import styles from "./PointBox.module.scss";
import { useAuth } from "../../auth";

export interface PointBoxProps {
  type?: "products";
}

export const PointBox = withSuspense(
  function PointBox({ type }: PointBoxProps) {
    const { isLoggedIn } = useAuth();
    const { data: points } = useParsedGetPoints({ enabled: isLoggedIn });
    const point = points?.total ?? 0;

    // /products/pointpackページはNext化していないため
    const Component = isLoggedIn ? Link : "a";

    return !isLoggedIn || point === 0 ? (
      <Component
        className={clsx("bg__orange", styles.linkPointpack)}
        href={isLoggedIn ? "/yearplan" : "/products/pointpack"}
      >
        <p className="text__s text__bold">ポイントの事前購入で、最大¥6,600お得</p>
        <p className={clsx("text__s mg__top__xs", type === "products" && "clear__pc")}>
          レギュラープランなら¥6,600、ライトプランなら¥1,000お得！
        </p>
        <i className="fas fa-angle-right" />
      </Component>
    ) : (
      <div className={clsx("bg__gray", styles.showPoints)}>
        <p className={clsx("text__s", "text__center")}>
          お持ちの<span className="text__bold">{point}pt</span>でお得に購入できます
        </p>
      </div>
    );
  },
  <div className={clsx("bg__gray", styles.showPoints)}>
    <Skeleton />
  </div>
);
