import { ProductModel } from "@/models/product/type";

import { ProductListItem } from "./ProductListItem";

interface ProductListProps {
  products: ProductModel[];
  isFirstSubscription?: boolean;
}

export function ProductList({ products, isFirstSubscription }: ProductListProps): React.ReactNode {
  return (
    <div>
      {products.map((product) => (
        <ProductListItem
          product={product}
          isFirstSubscription={isFirstSubscription}
          key={product.variantId}
        />
      ))}
    </div>
  );
}
