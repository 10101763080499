import { useCallback, useRef, useState } from "react";

type Resolve<V> = (value: V | PromiseLike<V>) => void;

/**
 * @description
 * このフックは、モーダルの開閉状態を管理します。
 * `open`関数を呼び出すとモーダルが開き、Promiseを返します。
 * モーダルを閉じるときには`close`関数を呼び出し、Promiseが解決され、後続の処理が実行されます。
 * awaitで`open`関数を呼び出すことで、モーダルが閉じるまで処理を待機することができます。
 *
 * @template V - モーダルが閉じたときにPromiseで解決される値の型。
 * @returns [isOpen, open, close] - モーダルの開閉状態を示すブール値、モーダルを開く関数、モーダルを閉じる関数のタプル。
 * @example
 * const  [isOpenConfirm, openConfirm, closeConfirm] = useModalState<string>();
 * ...
 * const submit = useCallback(async () => {
 *   const confirmed = await openConfirm();
 *   if (!confirmed) return;
 *   ...
 * }, [])
 *
 */
export function useModalState<V = void>() {
  const [isOpen, setIsOpen] = useState(false);
  const promiseActionsRef = useRef<Resolve<V> | null>(null);

  const open = useCallback(async () => {
    setIsOpen(true);
    return new Promise<V>((resolve) => {
      promiseActionsRef.current = resolve;
    });
  }, []);

  const close = useCallback((value: V) => {
    setIsOpen(false);
    promiseActionsRef.current?.(value);
    promiseActionsRef.current = null;
  }, []);

  return [isOpen, open, close] as const;
}
