import { useCallback, useState } from "react";

import clsx from "clsx";

import { PrivacyPolicy } from "@/components/domains/privacy";
import {
  ContactlessDelivery,
  Invite,
  MileProgram,
  Point,
  Purchase,
} from "@/components/domains/terms";
import { Modal, type ModalProps } from "@/components/feedbacks";

import styles from "./ModalDialogTerms.module.scss";

interface ModalDialogTermsProps extends Omit<ModalProps, "footer"> {
  onAcceptTerms: () => void;
}

const termsList = {
  purchase: {
    title: "商品購入に関する利用規約",
    content: <Purchase />,
  },
  invite: {
    title: "友だち紹介制度に関する利用規約",
    content: <Invite />,
  },
  mileProgram: {
    title: "マイルプログラム利用規約",
    content: <MileProgram />,
  },
  point: {
    title: "ポイント利用規約",
    content: <Point />,
  },
  contactlessDelivery: {
    title: "置き配利用に関する規約",
    content: <ContactlessDelivery />,
  },
  privacyPolicy: {
    title: "プライバシーポリシー",
    content: <PrivacyPolicy />,
  },
};
type TermName = keyof typeof termsList;

export function ModalDialogTerms({
  open,
  closeModal,
  onAcceptTerms,
}: ModalDialogTermsProps): React.ReactNode {
  const [termName, setTermName] = useState<TermName>("purchase");

  const setCurrentTerm = useCallback(
    (name: TermName) => () => {
      setTermName(name);
    },
    []
  );

  return (
    <Modal
      closeModal={closeModal}
      open={open}
      footerClassName={styles.modalFooter}
      footer={
        <button
          type="button"
          className="btn gray inline round angle__right mg__bottom__s"
          onClick={onAcceptTerms}
        >
          すべてに同意して閉じる
        </button>
      }
    >
      <div className={styles.checkoutTerms}>
        {/* TODO: Accordionをリファクタリングして使えるように修正 */}
        {Object.entries(termsList).map(([name, { title, content }]) => (
          <dl key={name} className={styles.checkoutTermsBlock}>
            <dt
              className={clsx("text__bold", styles.checkoutTermsTitle)}
              onClick={setCurrentTerm(name as TermName)}
            >
              {title}
              <i className={clsx("fas", termName === name ? "fa-minus" : "fa-plus")} />
            </dt>
            {termName === name && <dd className={styles.checkoutTermsContent}>{content}</dd>}
          </dl>
        ))}
      </div>
    </Modal>
  );
}
