import clsx from "clsx";

import styles from "./Section.module.scss";

export interface SectionProps extends React.ComponentProps<"div"> {
  noMargin?: boolean;
}

export function Section({
  className,
  noMargin = false,
  ...restProps
}: SectionProps): React.ReactNode {
  return <div className={clsx(className, { [styles.root]: !noMargin })} {...restProps} />;
}
