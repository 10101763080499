import clsx from "clsx";

import { Chip } from "@/components/displays";
import { BasicParagraph } from "@/components/texts";
import { formatCurrency } from "@/utils/currency";

import styles from "./OrderItem.module.scss";

interface OrderItemProps {
  title: string;
  variantTitle: string;
  showVariantTitle: boolean;
  imageUrl: string;
  quantity: number;
  subTotalPrice: number;
  showInitialDiscount?: boolean;
  isSubscription?: boolean;
}

export function OrderItem({
  title,
  variantTitle,
  showVariantTitle,
  imageUrl,
  quantity,
  subTotalPrice,
  isSubscription,
  showInitialDiscount,
}: OrderItemProps) {
  return (
    <div className={clsx("pd__bottom__m", "mg__top__m", styles.container)}>
      <dl className={clsx("table__dl", "goods__table")}>
        <dt style={{ verticalAlign: "middle" }}>
          {imageUrl && <img src={imageUrl} alt={title} className="card-img-top" />}
        </dt>
        <dd style={{ verticalAlign: "middle" }}>
          <BasicParagraph>{title}</BasicParagraph>
          {showVariantTitle && <BasicParagraph>{variantTitle}</BasicParagraph>}
        </dd>
      </dl>
      <dl className="table__dl">
        <dt>
          <BasicParagraph>数量：{quantity}</BasicParagraph>
        </dt>
        <dd className="text__right">
          <BasicParagraph weight="bold">
            {isSubscription && showInitialDiscount && (
              <>
                <Chip color="red" variant="outlined" className={styles.chip}>
                  初回割引
                </Chip>{" "}
              </>
            )}
            <span className={clsx(isSubscription && "text__red", "text__l", "text__bold")}>
              {formatCurrency(subTotalPrice)}
              <sup className="sup">*</sup>
            </span>
          </BasicParagraph>
        </dd>
      </dl>
    </div>
  );
}
