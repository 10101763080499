import clsx from "clsx";
import Link from "next/link";

import { Image } from "@/components/displays";
import { CartBadge, useAuth } from "@/components/domains";
import { withCsr } from "@/utils";

import styles from "./Drawer.module.scss";

export interface DrawerProps {
  menuOpen: boolean;
  closeMenu: () => void;
}

/**
 * useAuthの中でcookieを参照しており、StaticでビルドするためwithCSRでラップする(cookieを参照するとDynamicになる)
 */
export const Drawer = withCsr(function Drawer({
  menuOpen,
  closeMenu,
}: DrawerProps): React.ReactNode {
  const { isLoggedIn } = useAuth();

  return (
    <div className={clsx(styles.menuAccordion, { [styles.menuAccordionOn]: menuOpen })}>
      <div className="container">
        <div className="row">
          <div className="col-12" onClick={closeMenu}>
            <ul className="mg__top__m">
              <li>
                <Link
                  href="/products/subscription/2"
                  className={clsx(styles.menuCategory, "text__xxl text__bold")}
                >
                  スタートセット
                </Link>
              </li>
              <li>
                <Link href="/" className={clsx(styles.menuCategory, "text__xxl text__bold")}>
                  商品一覧
                </Link>
              </li>
              {isLoggedIn && (
                <li>
                  <Link href="/invite" className={clsx(styles.menuCategory, "text__l text__bold")}>
                    友だち紹介キャンペーン
                  </Link>
                </li>
              )}
              <li>
                <a
                  href="https://basefood.co.jp/brand"
                  className={clsx(styles.menuCategory, "text__l text__bold")}
                >
                  ブランドについて
                </a>
              </li>
              <li>
                <a
                  href="https://basefood.co.jp/safety"
                  className={clsx(styles.menuCategory, "text__l text__bold")}
                >
                  安全安心への取り組み
                </a>
              </li>
            </ul>

            <ul className="mg__top__m">
              <li>
                <Link href="/mypage" className={clsx(styles.menuLink, "text__l text__bold")}>
                  <Image
                    src="https://asset.basefood.co.jp/images/navi/icon-user.png"
                    size={{ width: 44, height: 44 }}
                    alt="マイページ"
                    className={styles.menuLinkImage}
                  />
                  <span className={styles.menuLinkSpan}>マイページ</span>
                </Link>
              </li>
              <li>
                <Link className={clsx(styles.menuLink, "text__l text__bold")} href="/cart">
                  <CartBadge
                    src="https://asset.basefood.co.jp/images/navi/icon-cart.png"
                    width={44}
                    height={44}
                    isPC={false}
                  />
                  <span className={styles.menuLinkSpan}>カート</span>
                </Link>
              </li>
              <li>
                <a
                  className={clsx(styles.menuLink, "text__l text__bold")}
                  href="https://basefood.zendesk.com/hc/ja"
                  target="_blank"
                >
                  <Image
                    src="https://asset.basefood.co.jp/images/navi/icon-question.png"
                    size={{ width: 44, height: 44 }}
                    alt={"ヘルプ"}
                    className={styles.menuLinkImage}
                  />
                  <span className={styles.menuLinkSpan}>ヘルプ</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});
