export type ChatBubbleIconProps = React.ComponentProps<"svg">;

export function ChatBubbleIcon({ ...rest }: ChatBubbleIconProps) {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 120.789 6.34357 140.096 17.2006 156.091C19.5531 159.557 20.3536 163.9 19.029 167.873L14.7916 180.586C13.163 185.472 17.8113 190.12 22.6973 188.491L36.5805 183.864C40.3427 182.609 44.4503 183.259 47.8318 185.331C63.0197 194.636 80.8834 200 100 200Z"
        fill="currentColor"
      />
    </svg>
  );
}
