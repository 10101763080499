"use client";
import { LocalStagePath } from "./types";
import { setLocalStorageValue } from "./useClientLocalStorage/helpers";

const storeKey: LocalStagePath = `vuex.customer.access_token`;

/**
 * Nextから`vuex.customer.access_token`のストレージを参照することはない。
 * Nuxt側で参照することがあるため、`vuex.customer.access_token`のストレージを更新するための関数。
 * そのためhooksではなく、関数として定義している。
 * @param cart
 */
export const setClientCustomerAccessToken = (token: string | undefined) => {
  setLocalStorageValue<string | undefined>(storeKey, token);
};
