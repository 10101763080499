import { useCallback } from "react";

import { useAddSubscription } from "@/generated/open-api/subscription/subscription";
import { convertCartToApiCart } from "@/models/cart/converters";
import { CartModel } from "@/models/cart/type";

interface UpdateSubscriptionParams {
  data: CartModel;
}

export const useParsedAddSubscription = () => {
  const { mutate, mutateAsync, ...rest } = useAddSubscription();

  const addSubscription = useCallback(
    async (params: UpdateSubscriptionParams) =>
      await mutate({ data: convertCartToApiCart(params.data) }),
    [mutate]
  );

  const addSubscriptionAsync = useCallback(
    async (params: UpdateSubscriptionParams) =>
      await mutateAsync({ data: convertCartToApiCart(params.data) }),
    [mutateAsync]
  );

  return { mutate: addSubscription, mutateAsync: addSubscriptionAsync, ...rest };
};
