/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

/**
 * マイル区分は以下のいずれかになります
 */
export type MileLabelType = (typeof MileLabelType)[keyof typeof MileLabelType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MileLabelType = {
  ADDBYADMIN: "ADDBYADMIN",
  INITIAL: "INITIAL",
  INVALID: "INVALID",
  INVITE: "INVITE",
  PURCHASE: "PURCHASE",
  PURCHASE_FREEZE: "PURCHASE_FREEZE",
  SUBBYADMIN: "SUBBYADMIN",
  GIFT_TRADE: "GIFT_TRADE",
  SUMMARY_ON_RENEW: "SUMMARY_ON_RENEW",
} as const;
