"use client";

import { useState } from "react";

import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { animateScroll } from "react-scroll";

import { getIsAppWebView } from "@/utils";

import styles from "./theFooterJp.module.scss";

export function TheFooterJP(): React.ReactNode {
  const [showDetail1, setShowDetail1] = useState(false);
  const [showDetail2, setShowDetail2] = useState(false);
  const [showDetail3, setShowDetail3] = useState(false);
  const pathname = usePathname();

  const scroll = animateScroll.scrollToTop;
  const isAppWebView = getIsAppWebView();
  if (isAppWebView || pathname === "/cart") {
    return null;
  }

  return (
    <footer id="footer" className={styles.footer}>
      <div className="container pd__bottom__l">
        <div className="row">
          <div className="col-12 pd__top__m pd__bottom__l">
            <a
              // v-smooth-scroll
              className={styles.footerGoTop}
              href="#"
              onClick={() => scroll()}
            >
              <i className="fas fa-chevron-circle-up1" />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-m-4">
            <a className={styles.footerLogo} href="https://basefood.co.jp">
              <img
                // TODO: CloudFrontのURLを直接書いているので修正する
                src="https://d2rfvqe3k7slxp.cloudfront.net/_nuxt/src/assets/images/logo.svg"
                alt="ベースフード株式会社"
              />
            </a>
            <p className="text__m text__bold mg__top__l">ベースフード株式会社</p>
            <p className="text__m">BASE FOOD, Inc.</p>
            <div className={clsx(styles.footerSns, "mg__top__m")}>
              <a href="https://page.line.me/frw1401o" target="_blank">
                <i className="fa-brands fa-line" />
              </a>
              <a href="https://www.instagram.com/basefood_tokyo/" target="_blank">
                <i className="fab fa-instagram" />
              </a>
              <a href="https://twitter.com/BASEFOOD" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>
              </a>
              <a
                href="https://basefoodnote.com/"
                target="_blank"
                className={styles.footerSnsLinkWide}
              >
                <svg
                  width="82"
                  height="40"
                  viewBox="0 0 82 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5658 11.9396C7.47338 11.8537 3.14397 12.0255 0 12.0255V28.1061H4.50121V15.9426C4.50121 15.9426 8.50419 15.8052 9.94733 15.977C11.3046 16.1316 11.8028 16.9734 11.8543 18.7258C11.8887 20.7187 11.8543 21.7666 11.8543 28.1061H16.3555C16.3555 28.1061 16.4071 20.8561 16.3555 18.3306C16.2868 13.8466 14.7234 12.0427 10.5658 11.9396Z"
                    fill="white"
                  />
                  <path
                    d="M31.3881 11.8878C26.9385 11.8878 23.3134 15.53 23.3134 20.014C23.3134 24.498 26.9385 28.1402 31.3881 28.1402C35.8378 28.1402 39.4628 24.498 39.4628 20.014C39.4628 15.53 35.8378 11.8878 31.3881 11.8878ZM31.3881 24.2575C29.0516 24.2575 27.1618 22.3505 27.1618 19.9968C27.1618 17.6431 29.0516 15.7362 31.3881 15.7362C33.7246 15.7362 35.6145 17.6431 35.6145 19.9968C35.6145 22.3677 33.7246 24.2575 31.3881 24.2575Z"
                    fill="white"
                  />
                  <path
                    d="M82 20.014C82 15.53 78.375 11.8879 73.9253 11.8879C69.4756 11.8879 65.8506 15.53 65.8506 20.014C65.8506 22.6426 67.0532 24.8932 68.9259 26.3878C70.2316 27.4186 72.0527 28.1402 74.4923 28.1402C75.6777 28.1402 78.7529 27.8138 80.9692 25.357L79.1481 22.9175C78.375 23.6047 76.4336 24.5667 74.8874 24.5667C72.9289 24.5667 71.7434 24.1888 70.8844 23.3813C70.3518 22.9003 69.9739 22.1615 69.8021 21.251H81.8797C81.9485 20.8387 82 20.4435 82 20.014ZM69.8364 18.5365C70.2831 16.6296 71.6232 15.2208 73.9081 15.2208C76.2962 15.2208 77.5503 16.7326 77.9627 18.5365H69.8364Z"
                    fill="white"
                  />
                  <path
                    d="M54.754 10.3933H50.5448V14.9117H46.2154V18.8631H50.5448V23.5532H54.754V18.8631H59.0834V14.9117H54.754V10.3933Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="col-12 col-m-8">
            <div className="row">
              <div
                className="
                      col-12
                      text__center text__right__pc
                      mg__top__l mg__top__off__pc mg__bottom__l
                    "
              >
                <a
                  className="btn border__wh inline round text__m"
                  href="https://basefood.zendesk.com/hc/ja"
                  target="_blank"
                >
                  ヘルプセンター
                </a>
              </div>
              <div className="col-12 col-m-4">
                <p
                  className={clsx(styles.footerCategory, "text__l text__bold")}
                  onClick={() => {
                    setShowDetail1(!showDetail1);
                  }}
                >
                  会社情報
                  <i className={clsx("fas fa-xs", showDetail1 ? "fa-minus" : "fa-plus")} />
                </p>
                <ul className={clsx(styles.footerItems, showDetail1 ? styles.on : "")}>
                  <li>
                    <a
                      className={clsx(styles.footerLink, "text__m")}
                      href="https://basefood.co.jp/corporate"
                      target="_blank"
                    >
                      会社概要
                      <i className="fas fa-angle-right" />
                    </a>
                  </li>
                  <li>
                    <a
                      className={clsx(styles.footerLink, "text__m")}
                      href="https://hrmos.co/pages/basefood"
                      target="_blank"
                    >
                      採用情報
                      <i className="fas fa-angle-right" />
                    </a>
                  </li>
                  <li>
                    <a
                      className={clsx(styles.footerLink, "text__m")}
                      href="https://basefood.zendesk.com/hc/ja/articles/900001534763"
                      target="_blank"
                    >
                      製造所一覧
                      <i className="fas fa-angle-right" />
                    </a>
                  </li>
                  <li>
                    <a
                      className={clsx(styles.footerLink, "text__m")}
                      href="https://basefood.zendesk.com/hc/ja/articles/360026653612"
                      target="_blank"
                    >
                      取材・協業窓口
                      <i className="fas fa-angle-right" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-m-4">
                <p
                  className={clsx(styles.footerCategory, "text__l text__bold")}
                  onClick={() => {
                    setShowDetail2(!showDetail2);
                  }}
                >
                  IR
                  <i className={clsx("fas fa-xs", showDetail2 ? "fa-minus" : "fa-plus")} />
                </p>
                <ul className={clsx(styles.footerItems, showDetail2 ? styles.on : "")}>
                  <li>
                    <a
                      className={clsx(styles.footerLink, "text__m")}
                      href="https://basefood.co.jp/ir/"
                      target="_blank"
                    >
                      IRトップ
                      <i className="fas fa-angle-right" />
                    </a>
                  </li>
                  <li>
                    <a
                      className={clsx(styles.footerLink, "text__m")}
                      href="https://basefood.co.jp/ir/news/"
                      target="_blank"
                    >
                      IRニュース
                      <i className="fas fa-angle-right" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-m-4">
                <p
                  className={clsx(
                    styles.footerCategory,
                    "text__l text__bold",
                    showDetail3 ? "" : styles.footerCategoryBottom
                  )}
                  onClick={() => {
                    setShowDetail3(!showDetail3);
                  }}
                >
                  規約
                  <i className={clsx("fas fa-xs", showDetail3 ? "fa-minus" : "fa-plus")} />
                </p>
                <ul
                  className={clsx(
                    styles.footerItems,
                    styles.footerItemsBottom,
                    showDetail3 ? styles.on : ""
                  )}
                >
                  <li>
                    <Link
                      className={clsx(styles.footerLink, "text__m")}
                      href="/terms"
                      target="_blank"
                    >
                      利用規約
                      <i className="fas fa-angle-right" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={clsx(styles.footerLink, "text__m")}
                      href="/privacy"
                      target="_blank"
                    >
                      プライバシーポリシー
                      <i className="fas fa-angle-right" />
                    </Link>
                  </li>
                  <li>
                    <a
                      className={clsx(styles.footerLink, "text__m")}
                      href="https://basefood.co.jp/law"
                      target="_blank"
                    >
                      特定商取引法に基づく表示
                      <i className="fas fa-angle-right" />
                    </a>
                  </li>
                  <li>
                    <a
                      className={clsx(styles.footerLink, "text__m")}
                      href="https://basefood.co.jp/fund_settlement"
                      target="_blank"
                    >
                      資金決済法に基づく表示
                      <i className="fas fa-angle-right" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mg__top__l mg__bottom__xl mg__bottom__off__pc">
            <p className="text__m text__center text__left__pc">© BASE FOOD, Inc.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
