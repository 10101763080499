"use client";
import { useCallback } from "react";

import { LocalStagePath, NuxtStoreCart } from "./types";
import { useClientLocalStorage } from "./useClientLocalStorage";

const storeKey: LocalStagePath = `vuex.cart.form`;

/**
 * TODO: vuexのstoreを初期化し、formがundefinedにならないようにする
 * その後、useClientCartFormを使っているコンポーネントの form?.xxx の部分も修正する
 */
export const useClientCartForm = () => {
  const [form, setForm] = useClientLocalStorage<NuxtStoreCart["form"] | undefined>({
    key: storeKey,
  });

  const clearForm = useCallback(() => {
    setForm(undefined);
  }, [setForm]);

  return { form, setForm, clearForm };
};
