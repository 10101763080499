/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

export type FinancialStatus = (typeof FinancialStatus)[keyof typeof FinancialStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialStatus = {
  paid: "paid",
  pending: "pending",
  expired: "expired",
  failed: "failed",
} as const;
