interface ContractSectionProps {}

export function ContractSection({}: ContractSectionProps): React.ReactNode {
  return (
    <section className="pd__top__m">
      <div className="row">
        <div className="col-12">
          <p className="text__l text__bold mg__bottom__xs">契約期間</p>
          <p className="text__m mg__bottom__s">無期限(定期購入コース)</p>
          <p className="text__s text__gray__dark mg__top__s mg__bottom__m">
            ※配送予定日の5日前までにマイページから解約手続きが無い場合は4週間ごとに商品をお届けいたします。契約期間に縛りはなく、解約手続きはいつでも可能です。
          </p>
        </div>
      </div>
    </section>
  );
}
