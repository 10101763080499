import { type LineupSectionItemProps } from "./LineupSectionItem";

export const items: LineupSectionItemProps[] = [
  {
    category: "BREAD",
    title: "チョコレート",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_chocolate_v1_12_omote.png",
    description: "しっとりチョコが折り込まれたマーブルチョコパン。",
  },
  {
    category: "BREAD",
    title: "メープル",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_maple_v1_6_omote.png",
    description: "まろやかな甘みにほっとする、スティックタイプのメープルパン。",
  },
  {
    category: "BREAD",
    title: "こしあん",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_koshian_v1_1_omote.png",
    description: "北海道産小豆の上品な甘み広がるしっとり生地のあんぱん。",
  },
  {
    category: "BREAD",
    title: "コーヒー",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_coffee_v1_0_omote.png",
    description: "グアテマラ産コーヒーの香り広がる大人のスティックパン。",
  },
  {
    category: "BREAD",
    title: "ミルク",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_milk_v1_0_omote.png",
    description: "北海道産牛乳を使用した、ほんのり甘いミルク風味のもっちりスティックパン。",
  },
  {
    category: "BREAD",
    title: "ミニ食パン・レーズン",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_slice_raisin_v1_3_omote.png",
    description: "みずみずしいレーズンの甘みがじゅわっと広がる、サクもち食感のミニ食パン。",
  },
  {
    category: "BREAD",
    title: "シナモン",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_cinnamon_v1_6_omote.png",
    description: "本格シナモンの豊かな香りがたのしめるシナモン味。",
  },
  {
    category: "BREAD",
    title: "カレー",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_curry_v1_6_omote.png",
    description: "揚げずに焼いて仕上げた、やわもち食感の濃厚カレーパン。",
  },
  {
    category: "BREAD",
    title: "ミニ食パン・プレーン",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_slice_plain_v1_5_omote.png",
    description: "いそがしい朝にさっと食べられる、やさしい甘みの全粒粉ミニ食パン。",
  },
];
