import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import Link from "next/link";
import { Controller, useForm } from "react-hook-form";

import { Row } from "@/components/containers";
import {
  AcceptTermsInput,
  ModalDialogTerms,
  useModalDialogTerms,
} from "@/components/domains/registration";
import { Button } from "@/components/inputs";

import { CheckoutConfirmationFormSchema, CheckoutConfirmationFormSchemaValue } from "./schema";
import styles from "./SubmitSection.module.scss";

interface SubmitSectionProps {
  onClickBack: () => void;
  onClickSubmit: () => void;
  showReturnPolicy?: boolean;
}

export function SubmitSection({
  onClickBack,
  onClickSubmit,
  showReturnPolicy = false,
}: SubmitSectionProps): React.ReactNode {
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CheckoutConfirmationFormSchemaValue>({
    resolver: zodResolver(CheckoutConfirmationFormSchema),
    mode: "onChange",
    defaultValues: {
      hasAcceptedTerms: false,
    },
  });

  const { showTermsDialog, openTermsDialog, closeTermsDialog, onAcceptTerms } = useModalDialogTerms(
    {
      onAccept: () => {
        setValue("hasAcceptedTerms", true);
        trigger("hasAcceptedTerms");
      },
    }
  );

  return (
    <section className="pd__top__m">
      <div className="row">
        <form
          className="col-12 text__center"
          id="confirmation"
          onSubmit={handleSubmit(onClickSubmit)}
        >
          <div className="mg__bottom__m">
            <Controller
              name="hasAcceptedTerms"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AcceptTermsInput value={value} onChange={onChange} onClickLink={openTermsDialog} />
              )}
            />
            {errors.hasAcceptedTerms && (
              <p className="text__red text__s mg__top__xs">{errors.hasAcceptedTerms.message}</p>
            )}
          </div>
          <Row className={styles.submitButtons}>
            <Button variants="gray" rounded onClick={onClickBack}>
              <i className="fas fa-angle-left"></i>
              戻る
            </Button>
            <Button
              variants="blue"
              rounded
              form="confirmation"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              ご注文を完了する
            </Button>
          </Row>
          <p className="text__s mg__top__m">
            読込に時間がかかる場合がございます。
            <br className="clear__pc" />
            画面を閉じずにそのままお待ちください。
          </p>

          {showReturnPolicy && (
            <p className="text__s mg__top__l text__left text__center__pc">
              ※返品・キャンセルについては
              <Link href="/law" target="_blank" className={clsx("text__bold", styles.link)}>
                こちら
              </Link>
            </p>
          )}
        </form>
      </div>

      <ModalDialogTerms
        open={showTermsDialog}
        closeModal={closeTermsDialog}
        onAcceptTerms={onAcceptTerms}
      />
    </section>
  );
}
