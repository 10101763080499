declare global {
  interface Window {
    __letroUgcGadget: {
      init: () => void;
    };
  }
}

// letroのwidgetを初期化し話題の投稿コンテンツを表示する
export const initLetroWidget = () => {
  if (
    document.getElementsByClassName("letroUgcsetContainer").length === 0 &&
    window.__letroUgcGadget
  ) {
    try {
      window.__letroUgcGadget.init();
    } catch (e) {
      // 重要でない部分なので例外はつぶす(TODO: sentryに送る)
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
};
