"use client";
import { useEffect } from "react";

import { redirect, usePathname } from "next/navigation";

import { useAuth } from "../useAuth";

export function PathAutoRedirectManager(): React.ReactNode {
  const pathname = usePathname();
  const { clearAccessToken } = useAuth();
  useEffect(() => {
    // 画面到達時に、アクセストークンを削除する
    clearAccessToken();
  }, [clearAccessToken]);

  redirect(`/account/login?redirect_path=${pathname}`);
}
