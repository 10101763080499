"use client";

import React from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";

import form12 from "$/images/lp/set12_form.png";
import maru12 from "$/images/lp/set12_maru.png";
import form28 from "$/images/lp/set28_form.png";
import maru28 from "$/images/lp/set28_maru.png";
import form46 from "$/images/lp/set46_form.png";
import maru46 from "$/images/lp/set46_maru.png";

import styles from "./SetDetail.module.scss";

interface Props {
  bags: 12 | 28 | 46;
  lp?: "gothic" | "maru" | "form";
}

const base = "https://asset.basefood.co.jp/lp/common";
const imageMap = {
  gothic: {
    12: `${base}/0828_set12_gothic.jpg`,
    28: `${base}/0828_set28_gothic.jpg`,
    46: `${base}/0828_set46_gothic.jpg`,
  },
  maru: {
    12: maru12.src,
    28: maru28.src,
    46: maru46.src,
  },
  form: {
    12: form12.src,
    28: form28.src,
    46: form46.src,
  },
};

export function SetDetail({ bags, lp = "gothic" }: Props): React.ReactNode {
  return (
    <Image
      src={imageMap[lp][bags]}
      alt={`${bags}袋セット`}
      aspectRatio="750 / 1356"
      containerClassName={clsx(styles.image)}
    />
  );
}
