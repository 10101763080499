"use client";

import { useRef } from "react";

import clsx from "clsx";

import styles from "./PanelCheckbox.module.scss";
import { Checkbox, CheckboxProps } from "../Checkbox";

export interface PanelCheckboxProps extends Omit<CheckboxProps, "content"> {
  content?: React.ReactNode;
}

export function PanelCheckbox({
  content,
  checked,
  children,
  ...restProps
}: PanelCheckboxProps): React.ReactNode {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <div className={clsx(styles.wrapper, checked && styles.wrapperChecked)}>
      <Checkbox size="m" {...restProps} checked={checked} ref={ref}>
        {children}
      </Checkbox>
      {checked && content && <div>{content}</div>}
    </div>
  );
}
