import type { HTMLProps } from "react";

import clsx from "clsx";

import styles from "./VideoPlayer.module.scss";

export interface VideoPlayerProps extends HTMLProps<HTMLIFrameElement> {}

export function VideoPlayer({ className, title, ...restProps }: VideoPlayerProps): React.ReactNode {
  return (
    <iframe
      {...restProps}
      title={title || "video player"}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className={clsx(styles.root, className)}
    />
  );
}
