"use client";
import { useEffect, useState } from "react";

import { FOOTER_BUTTON_TARGET_ID } from "@/components/domains/lp/consts";

const VISIBILITY_BOUND_Y = 500;

// スクロールに追従して表示されるが、一定スクロールすると非表示になる動作を再現するためのフック
export const useIsVisible = (displayStartId?: string) => {
  // displayStartIdが指定されている場合は、初期表示を非表示にする
  const [isVisible, setIsVisible] = useState(!displayStartId);

  useEffect(() => {
    const handleScroll = () => {
      const lpform = document.getElementById(FOOTER_BUTTON_TARGET_ID);

      let isFormVisible = false;
      if (lpform) {
        const height = lpform.getBoundingClientRect().top - window.innerHeight - VISIBILITY_BOUND_Y;
        isFormVisible = height < 0;
      }

      let isStartPointVisible = true;
      if (displayStartId) {
        const displayStart = document.getElementById(displayStartId);
        if (displayStart) {
          const height =
            displayStart.getBoundingClientRect().top - window.innerHeight - VISIBILITY_BOUND_Y;
          isStartPointVisible = height < 0;
        }
      }

      // フォームが表示されていないかつ、スタート地点が通過している場合は表示する
      setIsVisible(!isFormVisible && isStartPointVisible);
    };

    /**
     * TODO: debounceを入れてパフォーマンスを向上させる。
     * あるいはIntersectionObserverを使う
     */
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [displayStartId]);

  return { isVisible };
};
