import { Cookies } from "react-cookie";

import { SYS_COOKIE } from "@/configs/system";

import { isClient } from "./base";

/**
 * cookieからアプリのWebViewであるかどうかを判定する
 */
export const getAppWebViewCookieValue = () => {
  const value = isClient
    ? new Cookies().get(SYS_COOKIE.salusAppTokenKey)
    : // eslint-disable-next-line @typescript-eslint/no-var-requires
      require("next/headers").cookies().get(SYS_COOKIE.salusAppTokenKey)?.value;

  return value === SYS_COOKIE.salusAppTokenValue ? value : undefined;
};

export const getIsAppWebView = () => getAppWebViewCookieValue() !== undefined;

/**
 * フロントエンド側のみで利用するCookieからsessionHashKeyを取得する
 *
 * サーバー側であれば 処理しないフラグ: from-next-node-server を返す
 *
 * @see Middleware/SessionUuidManager.php
 * @see https://bf-partner.slack.com/archives/CPKSLHN20/p1726638120024409?thread_ts=1726219636.403219&cid=CPKSLHN20
 *
 * @returns sessionHashKeyのcookieを取得する
 */
export const getPayseSessionHash = (): string | undefined => {
  return isClient ? new Cookies().get(SYS_COOKIE.sessionHashKey) : "from-next-node-server";
};

export const setPayseSessionHashCookie = (value: string) => {
  const isLocal = process.env.NEXT_PUBLIC_APP_ENV !== "production";
  const domain = window.location.hostname.match(/\.basefood.+?$/)?.[0] || "localhost";
  const options = {
    path: "/",
    domain,
    maxAge: 60 * 60 * 24 * 31, // バックエンドに合わせて31日に設定
    secure: !isLocal,
  };

  if (isClient) {
    new Cookies().set(SYS_COOKIE.sessionHashKey, value, options);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require("next/headers").cookies().set(SYS_COOKIE.sessionHashKey, value, options);
  }
};

/**
 * クライアント側からtracingIdのcookieを削除する
 */
export const deletePayseSessionHashCookie = () => {
  const cookieStoreClient = new Cookies();
  cookieStoreClient.remove(SYS_COOKIE.sessionHashKey);
};
