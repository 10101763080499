"use client";
import { NuxtStore } from "./types";
import { useClientLocalStorage } from "./useClientLocalStorage";

const storeKey = `vuex`;

export const useClientVuex = () => {
  const [vuex, setVuex] = useClientLocalStorage<NuxtStore | undefined>({
    key: storeKey,
  });
  return { vuex, setVuex };
};
