import { type RecommendSet } from "./types";

export const LP_DIET_01: RecommendSet[] = [
  {
    name: "定番のパンをおためし 14袋セット",
    products: [
      { sku: "102008", quantity: 4 },
      { sku: "102009", quantity: 2 },
      { sku: "102016", quantity: 2 },
      { sku: "102017", quantity: 2 },
      { sku: "102018", quantity: 2 },
      { sku: "102013", quantity: 2 },
    ],
    is_best_value: false,
    subscription: true,
  },
  {
    name: "からだと向き合いたいなら 28袋セット",
    products: [
      { sku: "102008", quantity: 6 },
      { sku: "102009", quantity: 4 },
      { sku: "102016", quantity: 4 },
      { sku: "102017", quantity: 4 },
      { sku: "102018", quantity: 4 },
      { sku: "102013", quantity: 6 },
    ],
    is_best_value: false,
    subscription: true,
  },
  {
    name: "家族の健康習慣のために 46袋セット",
    products: [
      { sku: "102008", quantity: 6 },
      { sku: "102009", quantity: 6 },
      { sku: "102016", quantity: 4 },
      { sku: "102017", quantity: 4 },
      { sku: "102018", quantity: 4 },
      { sku: "102013", quantity: 6 },
      { sku: "102010", quantity: 6 },
      { sku: "102014", quantity: 6 },
      { sku: "102012", quantity: 4 },
    ],
    is_best_value: false,
    subscription: true,
  },
];
