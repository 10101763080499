import clsx from "clsx";

import styles from "./Chip.module.scss";

type BaseProps = React.ComponentProps<"span">;

export interface ChipProps extends BaseProps {
  children?: React.ReactNode;
  color?: "yellow" | "red" | "green" | "gray";
  variant?: "outlined" | "filled";
}

export function Chip({
  children,
  color = "yellow",
  variant = "filled",
  className,
  ...rest
}: ChipProps): React.ReactNode {
  const colorValiantClass = styles[`${color}-${variant}`];
  return (
    <span className={clsx(styles.base, colorValiantClass, className)} {...rest}>
      {children}
    </span>
  );
}
