import Script from "next/script";

export const UKomiEmbedCode = (): React.ReactNode => {
  return (
    <Script
      src="//api.u-komi.com/f1d6d964908215bf3671f2abf9306d8ad01f71c4d104dfaaae10f6864f546af6/widget.js"
      strategy="lazyOnload"
    />
  );
};
