import { useMemo } from "react";

import { getMilesGiftUrl } from "@/generated/axios-functions/payseAPI";
import { useGetMilesGiftUrl } from "@/generated/open-api/customer/customer";
import { convertObjToCamelCase } from "@/utils/converters";

export const useParsedGetMilesGiftUrl = ({ rankId }: { rankId?: string }) => {
  const { data, ...rest } = useGetMilesGiftUrl(
    { rank_id: rankId! },
    { query: { enabled: !!rankId } }
  );
  const parsed = useMemo(() => {
    if (!data) return null;

    return convertObjToCamelCase(data);
  }, [data]);
  return { data: parsed, ...rest };
};

export const getParsedGetMilesGiftUrl = async (rankId: string) => {
  const data = await getMilesGiftUrl({ rank_id: rankId });
  if (!data) {
    throw new Error("ランクアッププレゼントのURLが取得できませんでした");
  }

  return { data: convertObjToCamelCase(data) };
};
