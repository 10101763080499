import clsx from "clsx";

import { Modal } from "@/components/feedbacks";

interface DaibikiFeeModalProps {
  open: boolean;
  onClose: () => void;
}

export function DaibikiFeeModal({ open, onClose }: DaibikiFeeModalProps): React.ReactNode {
  return (
    <Modal
      open={open}
      closeModal={onClose}
      footer={
        <button type="button" className={clsx("btn", "inline", "gray", "square")} onClick={onClose}>
          OK
        </button>
      }
    >
      <div>
        <p className={clsx("text__bold", "mg__bottom__m", "pd__top__m")}>代金引換の手数料</p>
        <p className="text__m">
          9,670円未満のご購入：手数料330円
          <br />
          9,670円～29,559円のご購入：手数料440円
          <br />
          29,560~54,339円のご購入：手数料660円
          <br />
          54,340円以上のご購入：手数料880円
          <br />
          ※配送費含む
        </p>
      </div>
    </Modal>
  );
}
