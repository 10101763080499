import { groupBy, prop, uniq, zipObj } from "ramda";

import { ProductModel } from "@/models/product/type";

import { RecommendSet } from "./settings/types";

export function getUniqSkus(recommendSetsSettings: Record<string, RecommendSet[]>): string[] {
  const skus = Object.values(recommendSetsSettings).flatMap((sets) =>
    sets.flatMap((set) => set.products.map(({ sku }) => sku))
  );

  return uniq(skus);
}

export const groupProductsByTitle = groupBy<ProductModel, string>(prop("title"));

/**
 * [{ sku: 100001, quantity: 2 }, { sku: 100002, quantity: 3 }], [{ sku: 100001, variant_id: 3000001}, { sku: 100002, variant_id: 3000002 }]
 *  => { 300001: 2, 300002: 3 }
 */
export function transformRecommendSetProductToSelectOption(
  products: ProductModel[],
  recommendProducts?: RecommendSet["products"]
): Record<string, number> {
  if (!recommendProducts?.length || !products.length) {
    return {};
  }

  return zipObj(
    recommendProducts.map(
      ({ sku }) => products.find((product) => product.sku === sku)?.variantId || ""
    ),
    recommendProducts.map(({ quantity }) => quantity)
  );
}
