import clsx from "clsx";

import { Image } from "@/components";
import { ProductsBySkuCalcPriceModel } from "@/models/product/type";
import { formatCurrency } from "@/utils/currency";

import styles from "./SectionItem.module.scss";

export interface SectionItemProps {
  productSet: ProductsBySkuCalcPriceModel;
}

export function SectionItem({ productSet }: SectionItemProps): React.ReactNode {
  return (
    <div className="col-12 col-m-4">
      <div className={styles.section}>
        <p className="text__bold mg__bottom__s text__center text__small">
          {productSet.subTitle}
          <br /> <span className="text__xxl text__red">{productSet.quantity}</span>
          <span className="text__xl text__red">袋セット</span>
        </p>
        <div
          className={clsx("pd__top__m pd__bottom__m text__center mg__bottom__s", styles.outline)}
        >
          <del className="text__s">
            通常価格{formatCurrency(productSet.defaultPrice)}（送料・税込）
          </del>
          <br />
          <span className={clsx("tag red radius text__white text__s", styles.percent)}>20%0FF</span>
          <span className="text__red text__xl text__bold">
            {formatCurrency(productSet.totalPrice)}
          </span>
          <span className="text__s text__red"> （送料・税込） </span>
          <p className="text__bold text__m text__center mg__top__s">
            当サイトでのご購入が、
            <br />
            送料含めお得です。
          </p>
          <p className="text__s text__gray__dark text__center mg__top__s">
            ※継続コース初回のみ※2回目以降のご購入から {formatCurrency(productSet.secondTotalPrice)}
            （送料・税込） ※1回目お届け以降、いつでも内容変更、解約が可能
          </p>
        </div>
        <p className="text__m text__bold mg__bottom__m">セット内容</p>
        <Image
          src={productSet.images[0].src}
          alt={productSet.name || "セット内容"}
          aspectRatio={productSet.images[0].aspectRatio}
        />
        <p className="text__s text__center text__gray__dark mg__top__m">
          BASE BREADの賞味期限は、
          <br />
          ご注文日から約1ヶ月前後です。
        </p>
      </div>
    </div>
  );
}
