"use client";
import { useLayoutEffect, useState } from "react";

import { useCookies } from "react-cookie";

import { SYS_COOKIE } from "@/configs/system";

/**
 * 現在の環境がSalusのWebViewであるかどうかを、特定のクッキーに基づいて判定します。
 * この関数は、Nuxtの`check-app-webview-cookie.js:checkAppWebviewCookie`から、移行されたものです。
 * NextJSでクライアントサイドのみで使用するため、useLayoutEffectでラップしています。
 * @returns {boolean} `salus_app_token`クッキーが存在し、その値が`app_ios_webview`である場合にtrueを返します。
 */
export const useIsAppWebView = () => {
  const [cookies] = useCookies([SYS_COOKIE.salusAppTokenKey]);
  const [isAppWebView, setIsAppWebView] = useState(false);
  useLayoutEffect(() => {
    const salusAppToken = cookies.salus_app_token;
    setIsAppWebView(!!salusAppToken && salusAppToken === SYS_COOKIE.salusAppTokenValue);
  }, [cookies]);
  return isAppWebView;
};
