import { useMemo } from "react";

import { useGetCustomerOrders } from "@/generated/open-api/customer/customer";
import { convertApiOrderToOrder } from "@/models/order/converters";
import { convertObjToCamelCase } from "@/utils/converters";

export const useParsedGetCustomerOrders = (page: number) => {
  const { data, ...rest } = useGetCustomerOrders({ page });

  const customerOrders = useMemo(() => {
    if (!data?.data || !data?.meta) return;
    return {
      orders: data.data.map((order) => convertApiOrderToOrder(order)),
      meta: convertObjToCamelCase(data.meta),
    };
  }, [data]);

  return { data: customerOrders, ...rest };
};
