export interface GtmEvent {
  event: string;
}

export const pushGtmEvent = <T extends GtmEvent>(event: T) => {
  try {
    if (!window.dataLayer) {
      // window.dataLayerが存在しない場合は無視する
      return;
    }
    window.dataLayer.push(event);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
