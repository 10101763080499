interface ContactSectionProps {
  email: string;
}

export function ContactSection({ email }: ContactSectionProps): React.ReactNode {
  return (
    <section className="pd__top__m">
      <div className="row">
        <div className="col-12">
          <p className="text__l text__bold mg__bottom__xs">連絡先情報</p>
          <p className="text__m">{email}</p>
        </div>
      </div>
    </section>
  );
}
