"use client";
import { memo, useEffect } from "react";

import { initUkomiWidget } from "@/utils/ukomi/ukomi";

export interface UKomiReviewProps {
  groupName: string;
  name: string;
  price: number;
}

export const UKomiReview = memo(function UKomiReview({
  groupName,
  name,
  price,
}: UKomiReviewProps): React.ReactNode {
  useEffect(() => {
    initUkomiWidget();
  }, [groupName, name, price]);

  return (
    <div
      data-gname={groupName}
      data-name={name}
      data-url={`/products/${name}`}
      data-price={price}
      className="review-container"
      data-currency="JPY"
      data-description=""
      data-action="widget"
      data-group="true"
    />
  );
});
