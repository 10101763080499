"use client";
import { useMemo } from "react";

import { useSearchParams } from "next/navigation";

import { AmazonPayGetCheckoutDataModel } from "@/models/amazonpay/models";
import { useAmazonGetCheckoutSuspense } from "@/queries/amazon/useAmazonGetCheckoutSuspense";
import { getErrorMessages } from "@/utils/error";

export type UseAmazonPayReturn =
  | {
      // 利用していない場合（正常系）
      amazonPayEnable: false;
      amazonPayCheckoutSessionId: null;
      amazonPayCheckoutError: null;
      amazonPayCheckoutData: null;
    }
  | {
      // 利用しようとしているがエラーが発生している場合（異常系）
      amazonPayEnable: false;
      amazonPayCheckoutSessionId: string;
      amazonPayCheckoutError: string;
      amazonPayCheckoutData: null;
    }
  | {
      // 利用しようとしている場合（正常系）
      amazonPayEnable: true;
      amazonPayCheckoutSessionId: string;
      amazonPayCheckoutError: null;
      amazonPayCheckoutData: AmazonPayGetCheckoutDataModel;
    };

/**
 * AmazonPayの状態、制御を担うカスタムフック
 * amazonCheckoutSessionIdの取得や、チェックアウトデータを取得を担う
 * prefetchAmazonGetCheckoutでキャッシュするか、Clientサイドでのみ利用可能
 * @returns
 */
export function useAmazonPay(): UseAmazonPayReturn {
  const searchParams = useSearchParams();
  const checkoutSessionId = searchParams.get("amazonCheckoutSessionId");
  const canceled = searchParams.get("cancel") === "true";
  const { data: checkoutData, error } = useAmazonGetCheckoutSuspense({
    amazonCheckoutSessionId: checkoutSessionId && !canceled ? checkoutSessionId : null,
  });

  const res = useMemo<UseAmazonPayReturn>(() => {
    if (!checkoutSessionId || canceled) {
      // そもそも利用していない場合
      return {
        amazonPayEnable: false,
        amazonPayCheckoutSessionId: null,
        amazonPayCheckoutError: null,
        amazonPayCheckoutData: null,
      };
    }

    if (checkoutData === null) {
      const errorMessage = getErrorMessages(error).join("\n");
      return {
        amazonPayEnable: false,
        amazonPayCheckoutSessionId: checkoutSessionId,
        amazonPayCheckoutError: errorMessage,
        amazonPayCheckoutData: null,
      };
    }

    if (!checkoutData) {
      const errorMessage = getErrorMessages(error).join("\n");
      return {
        amazonPayEnable: false,
        amazonPayCheckoutSessionId: checkoutSessionId,
        amazonPayCheckoutError: errorMessage,
        amazonPayCheckoutData: null,
      };
    }

    return {
      amazonPayEnable: true,
      amazonPayCheckoutSessionId: checkoutSessionId!,
      amazonPayCheckoutError: null,
      amazonPayCheckoutData: checkoutData,
    };
  }, [canceled, checkoutData, checkoutSessionId, error]);

  return res;
}
