"use client";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import {
  convertCartItemsToStoreCartItems,
  convertStoreCartItemToCartItems,
} from "@/models/cart/converters";
import { CartItemModel } from "@/models/cart/type";

import { LocalStagePath, NuxtCommonCartItem } from "./types";
import { useClientLocalStorage } from "./useClientLocalStorage";
import { getLocalStorageValue, setLocalStorageValue } from "./useClientLocalStorage/helpers";

const storeKey: LocalStagePath = `vuex.front-store.cartItems`;

/**
 * @remarks
 * Client Side renderingでのみ対応
 */
export const useClientFrontStoreCartItems = () => {
  const [storeCartItems, setStoreCartItems] = useClientLocalStorage<NuxtCommonCartItem[]>({
    key: storeKey,
    initialValue: [],
  });

  const cartItems = useMemo(
    () => (storeCartItems ? convertStoreCartItemToCartItems(storeCartItems) : []),
    [storeCartItems]
  );

  const setCartItems = useCallback<Dispatch<SetStateAction<CartItemModel[]>>>(
    (newCartItemsAction) => {
      setStoreCartItems((prev) => {
        const prevCartItems = convertStoreCartItemToCartItems(prev || []);
        const newCartItems =
          newCartItemsAction instanceof Function
            ? newCartItemsAction(prevCartItems)
            : newCartItemsAction;
        return convertCartItemsToStoreCartItems(newCartItems);
      });
    },
    [setStoreCartItems]
  );

  return { cartItems, setCartItems };
};

/**
 * hooksではないsetCartItemsを直接呼び出す関数
 * useClientFrontStoreCartItemsのsetCartItemsは宣言時点でlocalStorageへのアクセスが発生するため、
 * SSR対象のコンポーネントで利用できません。一方で、setClientFrontStoreCartItemsは
 * 宣言が不要であり、SSR対象のコンポーネント内の関数内で利用できます。
 */
export const setClientFrontStoreCartItems: Dispatch<SetStateAction<CartItemModel[]>> = (
  newCartItemsAction
) => {
  const prev = getLocalStorageValue<NuxtCommonCartItem[]>(storeKey) ?? [];
  const prevCartItems = convertStoreCartItemToCartItems(prev);
  const newCartItems =
    newCartItemsAction instanceof Function ? newCartItemsAction(prevCartItems) : newCartItemsAction;
  const storeCartItems = convertCartItemsToStoreCartItems(newCartItems);
  setLocalStorageValue(storeKey, storeCartItems);
};
