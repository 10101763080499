import { useMemo } from "react";

import { useGetCancellationSurvey } from "@/generated/open-api/survey/survey";
import { convertApiSurveyResponseToSurveyResponse } from "@/models/survey/converters";

export const useParsedGetCancellationSurvey = () => {
  const { data, ...rest } = useGetCancellationSurvey();

  const parsedData = useMemo(
    () => (data ? convertApiSurveyResponseToSurveyResponse(data) : undefined),
    [data]
  );

  return { ...rest, data: parsedData };
};
