import { forwardRef } from "react";

import clsx from "clsx";

import styles from "./Input.module.scss";

export type BaseProps = React.ComponentProps<"input">;
export interface InputProps extends BaseProps {
  error?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, error, ...rest },
  ref
): React.ReactNode {
  return (
    <input {...rest} className={clsx(error && styles.error, styles.root, className)} ref={ref} />
  );
});
