import { useMemo } from "react";

import clsx from "clsx";

import styles from "./ProgressBar.module.scss";

interface ProgressBarProps {
  num: number;
  max: number;
  className?: string;
  containerClassName?: string;
  children?: React.ReactNode;
}

export function ProgressBar({
  num,
  max,
  className,
  containerClassName,
  children,
}: ProgressBarProps): React.ReactNode {
  const progress = useMemo(() => {
    const v = (num / max) * 100;
    if (v > 100) {
      return 100;
    }

    return v;
  }, [max, num]);

  return (
    <div className={clsx("bg__white", styles.container, containerClassName)}>
      <div className={clsx(styles.bar, className)} style={{ width: `${progress}%` }}></div>
      <div className={styles.children}>{children}</div>
    </div>
  );
}
