import clsx from "clsx";

import styles from "./Paper.module.scss";

export interface PaperProps {
  className?: string;
  children: React.ReactNode;
  padding?: "responsive" | "none" | "fixedSp" | "fixed20" | "fixed40";
  style?: React.CSSProperties;
}

export function Paper({
  className,
  padding = "none",
  style,
  children,
}: PaperProps): React.ReactNode {
  return (
    <div className={clsx(styles.container, className, styles[`padding-${padding}`])} style={style}>
      {children}
    </div>
  );
}
