import { useMemo } from "react";

import { useGetMileSummary } from "@/generated/open-api/mile/mile";
import { convertObjToCamelCase } from "@/utils/converters";

export const useParsedGetMileSummary = () => {
  const { data, ...rest } = useGetMileSummary();

  const formattedData = useMemo(() => {
    if (!data) return;

    const formatted = convertObjToCamelCase(data);

    return formatted;
  }, [data]);

  return { data: formattedData, ...rest };
};
