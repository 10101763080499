import { forwardRef } from "react";

import clsx from "clsx";
import { Tooltip } from "react-tooltip";

import { Row } from "@/components/containers";
import { Checkbox, CheckboxProps } from "@/components/inputs";
import { nl2br } from "@/utils/jsx";

import styles from "./MarketingCheckbox.module.scss";

export interface MarketingCheckboxProps extends Omit<CheckboxProps, "children"> {
  tooltipId: string;
}

export const MarketingCheckbox = forwardRef<HTMLInputElement, MarketingCheckboxProps>(
  function MarketingCheckbox({ tooltipId, ...rest }, ref) {
    return (
      <Checkbox {...rest} ref={ref}>
        <Row>
          ベースフードからのお知らせを受け取る
          <i
            id={tooltipId}
            className={clsx("fas fa-question-circle text__m", styles.questionIcon)}
          />
          <Tooltip anchorSelect={`#${tooltipId}`}>
            {nl2br(
              `お得で便利な情報をお届けします。\n・新商品試食会へのご案内\n・限定割引クーポン\n・おいしい食べ方など`
            )}
          </Tooltip>
        </Row>
      </Checkbox>
    );
  }
);
