"use client";
import { useEffect, useRef } from "react";

/**
 * 指定された副作用（effect）をコンポーネントのライフサイクル中に一度だけ実行します。
 * `condition`パラメータが指定されている場合、`true`になったとき一度だけ副作用が実行されます。
 * このhooksはDestructor(callback関数の戻り値の関数)が動作しません。
 *
 * @param {VoidFunction} effect - コンポーネントがマウントされた後に実行する副作用の関数。
 *   この関数から返されるクリーンアップ関数は、このhooksではサポートされていません。
 *
 * @param {boolean} [condition=true] - 副作用が実行されるかどうかを決定する条件。
 *   デフォルトでは`true`です。
 *
 * @example
 * useOnce(() => {
 *   console.log("コンポーネントがマウントされた");
 * });
 */
export function useOnce(effect: VoidFunction, condition: unknown = true) {
  const isExecutedRef = useRef(false);

  useEffect(() => {
    if (isExecutedRef.current || !condition) return;
    isExecutedRef.current = true;
    effect();
  }, [condition, effect]);
}
