"use client";
import { LocalStagePath } from "./types";
import { useClientLocalStorage } from "./useClientLocalStorage";

// vuexからの移行なので、storeKeyはvuex.cartとしている
const storeKey: LocalStagePath = `vuex.cart`;

type AmazonPayData = {
  amazonPayEnable: boolean;
  amazonPayAccessToken: string | null;
  amazonPayCheckoutSessionId: string | null;
  amazonPayCheckoutUrl: string | null;
  amazonOrderReferenceId: string | null;
  amazonBillingAgreementId: string | null;
};

const defaultAmazonPayData: AmazonPayData = {
  amazonPayEnable: false,
  amazonPayAccessToken: null,
  amazonPayCheckoutSessionId: null,
  amazonPayCheckoutUrl: null,
  amazonOrderReferenceId: null,
  amazonBillingAgreementId: null,
};

/**
 * AmazonPayとローカルストレージを連携させるカスタムフック
 * Clientサイドでのみ利用可能
 * @returns
 */
export function useClientAmazonPay() {
  const [amazonPayData, setAmazonPayData] = useClientLocalStorage<AmazonPayData>({
    key: storeKey,
    initialValue: defaultAmazonPayData,
  });

  return {
    amazonPayData,
    setAmazonPayData,
  };
}
