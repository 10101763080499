/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

/**
 * ng
 */
export type SignupEmail422Status = (typeof SignupEmail422Status)[keyof typeof SignupEmail422Status];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SignupEmail422Status = {
  ng: "ng",
} as const;
