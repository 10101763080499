import { AmazonGetCheckout200OneOf, AmazonPayShippingAddress } from "@/generated/open-api/schemas";
import { getDomesticProvince } from "@/utils/province";

import { AmazonPayGetCheckoutDataModel } from "./models";

export const convertAmazonGetCheckoutResToAmazonPayGetCheckoutData = (
  data: AmazonGetCheckout200OneOf
): AmazonPayGetCheckoutDataModel => {
  return {
    email: data.email!,
    name: data.name!,
    paymentPreferences: data.payment_preferences!,
    shippingAddress: convertAmazonShippingAddressToFormShippingAddress(data.shipping_address),
  };
};

export const convertAmazonShippingAddressToFormShippingAddress = (
  shippingAddress?: AmazonPayShippingAddress
) => {
  return {
    firstName: null,
    lastName: shippingAddress?.name ?? "",
    zip: shippingAddress?.postalCode ?? "",
    province: getDomesticProvince(shippingAddress?.stateOrRegion ?? "")?.code ?? "",
    // 番号がずれているので注意
    city: shippingAddress?.addressLine1 ?? "",
    addressLine1: shippingAddress?.addressLine2 ?? "",
    addressLine2: shippingAddress?.addressLine3 ?? "",
    // customer.shippingAddress.phoneはハイフンが入っていないため、ハイフンを削除する
    phone: shippingAddress?.phoneNumber?.replace(/\-/g, "") ?? "",
  };
};
