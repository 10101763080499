import { PaymentMethod, paymentMethodLabels } from "@/models/payment/consts";

interface PaymentSectionProps {
  paymentMethod: PaymentMethod;
}

export function PaymentSection({ paymentMethod }: PaymentSectionProps): React.ReactNode {
  return (
    <section className="pd__top__m">
      <div className="row">
        <div className="col-12">
          <p className="text__l text__bold mg__bottom__xs">支払い方法</p>
          <p className="text__m text__bold">{paymentMethodLabels[paymentMethod]}</p>
          <p className="text__s text__gray__dark mg__top__s">
            ※支払い時期は初回、Amazon
            Pay・クレジット支払いの場合は申し込みと同時、代金引換の場合は商品受け取り時です。
            <br />
            2回目以降は、Amazon
            Pay・クレジット支払いの場合はお届け日の4日前、代金引換の場合は商品受け取り時です。
          </p>
        </div>
      </div>
    </section>
  );
}
