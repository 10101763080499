"use client";

import { Link, LinkProps } from "react-scroll";

import { AnchorId } from "./types";

export interface ScrollProps extends Omit<LinkProps, "to" | "ref"> {
  to: AnchorId;
}

export function Scroll(props: ScrollProps) {
  return <Link {...props} smooth />;
}
