import { List } from "@/components/displays";

export function Point(): React.ReactNode {
  return (
    <div>
      <dl className="mg__bottom__m">
        <dt className="text__m text__bold">第１条（規約）</dt>
        <dd className="text__m text__justify mg__top__s">
          ベースフード株式会社（以下「当社」といいます）は、当社が運営するウェブサイト（https://basefood.co.jp/
          。以下「本サイト」といいます）の利用規約（https://shop.basefood.co.jp/terms）に基づき会員登録をされた利用者に対し、本規約に基づいてポイントサービス（以下「本サービス」といいます）を提供します。本サービスは本規約に基づいて提供され、会員が本サービスを利用される場合は本規約に同意したものとみなされます。本規約に規定のない事項については、本サイトの利用規約ならびに本サイトの諸規約、および手続き等が適用されます。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第２条（資格）</dt>
        <dd className="text__m text__justify mg__top__s">
          当社は、ポイント及びキャンペーンポイント（以下、併せて「ポイント等」といいます）を会員に発行または付与します。ポイント等の購入、付与または利用には、アカウントを取得しマイページを開設する必要があります（以下、アカウントを取得した方を「会員」といいます）。また、ポイント等の購入、付与または利用には、会員であることに加え、当社が指定する条件を付加する可能性があります。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第３条（ポイントの購入）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. ポイント購入のための課金額は、ウェブサイトまたは携帯端末（フィーチャーフォン、スマートフォンおよびタブレット端末を含みますが、これらに限られません。）の支払画面に表示される最終購入確認画面に、その都度表示されます。",
              "2. 会員は、ウェブサイトまたは携帯端末の画面上に表示された課金額を支払うことで、ポイントを都度購入することができます。",
              <>
                3.
                会員は、下記の決済手段により、ポイント購入の支払を行うことができます。なお、当社は、会員による決済手段の選択および利用に関して責任を負担しません。
                <p className="text__center mg__top__xs">記</p>
                <List
                  contents={[
                    "・ クレジットカード（VISA・Master Card・AMEX・JCB）",
                    "・ Amazon Pay",
                  ]}
                />
                <p className="text__right">以上</p>
              </>,
              "4. ポイントの購入は、ポイントを購入する際の最終確認画面で会員が購入ボタンをクリックし、決済が完了した時点で完了します。",
              "5. 本規約に定めがある場合を除き、購入完了後は、当社は、ポイント購入代金の返金等には応じられません。",
              "6. 当社は、ポイントの購入完了を確認した後、速やかにポイントを発行しますが、システム上およびその他の理由で、ポイントの発行が遅延する場合があります。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第４条（キャンペーンポイント付与に関する注意事項）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. キャンペーンポイントは、当社が別途定める商品又はサービスを購入された会員に対し、当社の定める方法及び条件に基づいて付与します。",
              "2. 当社は、付与するキャンペーンポイントの数を別途定めるものとします。",
              "3. 当社は、会員がキャンペーンポイントを利用できる商品又はサービスを指定することができるものとします。",
              "4. 当社は、キャンペーンポイントを付与された会員が会員資格を失い、その後、再び会員資格を得た場合につき、キャンペーンポイントは付与しないことができるものとします。",
              "5. キャンペーンポイントは、当社が別途定める発行日を起算日として６ヶ月以内の期間に限り利用できるものとし、当該期間の経過をもって失効するものとします。",
              "6. ポイント及びキャンペーンポイントの双方を保有する会員がポイント等を利用する場合において、そのいずれを利用するかについては当社が指定できるものとし、会員はその利用順について異議なく従うものとします。",
              "7. 当社は、本サイトにて予告の上、キャンペーンポイントの付与を中止又は終了できるものとします。ただし、緊急の場合又は予告期間を置くことが適当でないと当社が判断した場合、当社は、キャンペーンポイントの付与を予告なく中止又は終了できるものとします。",
              "8. 当社は、失効したキャンペーンポイントについて何らの補償も行わず、一切の責任を負いません。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第５条（ポイント購入に関する注意事項）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. ポイントの購入等のご利用には、ポイントの購入代金の他に別途通信料がかかります。",
              "2. システム障害、システム保守の点検その他安全管理等のため、当社が必要と判断した場合には、ポイント等の購入または利用が停止される場合があります。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第６条（ポイント等の取消し）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              <>
                1.
                ポイント等の購入又は付与にあたり、下記の事由が発生・判明した場合、当社は会員に事前に通知することなく、会員が購入したポイント等の一部または全部を取り消す（正しい料金を課金することを含みます。）ことができます。
                <p className="text__center mg__top__xs">記</p>
                <List
                  contents={[
                    "・ 価格、説明、注文確認、注文処理、発行その他に関してエラーが生じた場合",
                    "・ 違法または不正行為があった場合",
                    "・ 本規約、本サイトの利用規約、その他当社が定める規約・ルール等に違反があった場合",
                    "・ 購入された商品の返品、キャンセルその他当社がポイント等の付与を取り消すことが適当と判断する事由があった場合",
                    "・ その他当社が合理的な理由により会員に付与されたポイント等を取り消すことが適当と判断した場合",
                  ]}
                />
                <p className="text__right">以上</p>
              </>,
              "2. 当社は、ポイント等の取り消しにより生じた損害等について何らの補償も行わず、一切の責任を負いません。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第７条（ポイント等の合算および複数登録の禁止）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 会員は、保有するポイント等を他の会員に譲渡または質入れし、あるいは会員間でポイント等を共有することはできません。",
              "2. 一人の会員が複数の会員登録をしている場合、会員はそれぞれの会員登録において保有するポイント等を合算することはできません。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第８条（保有ポイントの上限）</dt>
        <dd className="text__m text__justify mg__top__s">
          保有できるポイントの上限は100,000ポイントです。100,000ポイントを超えてポイントを追加購入することはできません。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第９条（ポイントの有効期限）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            className="mg__bottom__s"
            contents={[
              "1. ポイントの有効期限は、購入日を起算日として24か月です。",
              "2. ポイントを追加購入するとポイントすべての有効期限が24か月後に更新されます。",
              "3. キャンペーンなどで、無償でポイントを獲得した場合も、有償・無償を問わずポイントすべての有効期限が24か月後に更新されます。",
              "4. 当社は、失効したポイントについて何らの補償も行わず、一切の責任を負いません。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第１０条（決済におけるポイントの利用）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 1ポイント又は1キャンペーンポイント以上保有する会員がポイント等を利用できます。",
              "2. 会員は、保有するポイント等を本サイトにおいて1ポイント＝1円相当分又は1キャンペーンポイント＝1円相当として、本サイトにて販売する「継続コース」の商品、当社が指定した商品の代金の合計（消費税込み）の全部または一部の支払い及び配送料に利用することができます。なお、代引き手数料は対象外とします。",
              "3. 一回の注文で会員が利用できるポイント等の下限は1ポイント、上限は保有ポイントとなります。",
              "4. 店舗を含むその他のウェブサイト、関連会社、または本サイトからアクセスできる他の会社のサイトでは、ポイント等は利用できません。",
              "5. 当社は、一回の注文に使えるポイント等の下限、上限、一か月間に使えるポイント等の上限など、利用に制限を設ける権利を有します。また、この制限は、予告なく変更または追加される場合がありますが、会員は、いずれの場合についても、当社の決定に従うものとします。",
              "6. 会員が第１項による決済を取り消した場合、当該決済に利用されたポイント等は原則としてポイント等で返還され、現金による返還は行われません。",
              "7. 会員が決済代金の支払いにポイント等を利用した後、何らかの事情により決済代金が増額された場合は、会員は増額分を他の支払方法にて支払うものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第１１条（換金の不可）</dt>
        <dd className="text__m text__justify mg__top__s">
          会員は、いかなる場合でもポイント等を換金することはできません。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第１２条（第三者による利用）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. ポイント等の利用は、会員本人が行うものとし、当該会員以外の第三者が行うことはできません。",
              "2. 当社は、ポイント等利用時に入力された会員IDおよびパスワードが登録されたものと一致することを当社が所定の方法により確認した場合には、会員による利用とみなします。",
              "3. 第三者による不正利用であった場合でも、当社は利用されたポイント等を返却せず、会員に生じた損害について一切責任を負いません。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第１３条（税金および費用）</dt>
        <dd className="text__m text__justify mg__top__s">
          ポイント等の取得、ポイント等の利用にともない、税金や付帯費用が発生する場合には、会員がこれらを負担するものとします。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第１４条（会員資格の喪失・停止）</dt>
        <dd className="text__m text__justify mg__top__s">
          会員が会員の地位を喪失した場合には、保有するポイント等、その他本サービスの利用に関する一切の権利を失うものとし、また地位の喪失にともなって当社に対して何らの請求権も取得しないものとします。なお、「継続コース」のみ解約は可能であり、その場合にはポイント等は失効いたしません。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第１５条（免責事項）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 当社は、サービス提供時点での技術水準を前提に最善を尽くしますが、明示的であるか黙示的であるかにかかわらず、障害が発生しないことを保証するものではありません。停電、通信回線やコンピュータなどの障害によるシステムの中断・遅滞・中止・データの消失、ポイント等の利用に関する障害または損害、その他本サービスに関して会員に生じた損害について、当社は一切責任を負わないものとします。",
              "2. 当社、当社の役員、従業員または代理人は、当社の責に帰するべき事由による場合を除き、ポイント等の購入、利用または利用不能に起因または関連して生じた損害について責任を負担しません。また、当社が負う責任は、いかなる根拠による場合であっても、特別、間接的または派生的な損害その他の損害（逸失利益の損害およびデータの損失を含みますがこれらに限られません。）には及ばず、また、会員がポイント等の対価として当社に支払った金額を超えないものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第１６条（規約の変更）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 当社は、民法548条の4に基づき、当社が必要と判断する場合、利用者の事前の承諾を得ることなく、本規約の内容を変更することができます。",
              "2. 当社が本規約の内容を変更し、その変更内容を会員に通知した場合、通知において指定された期日以降（ただし、通知において期日を指定しない場合には、当社が通知を発した当日を期日とします。）に、本制度を利用した場合、変更後の本規約に同意したものとみなし、変更後の本規約が適用されます。会員が通知において指定された期日内に解約の手続きをとらなかった場合には、変更後の本規約に同意したものとみなされます。",
              "3. 前２項の規定にかかわらず、法令上、会員の同意やあらかじめの通知等が必要となるような内容の変更の場合は、当社の所定の方法で会員の同意を得、またはあらかじめの通知等を行うものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dd className="text__m">
          附則
          <br />
          2022年3月8日制定
          <br />
          2023年5月8日改定
          <br />
          2023年10月10日改定
        </dd>
      </dl>
    </div>
  );
}
