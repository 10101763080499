import { useMemo } from "react";

import { useGetPointMileSuspense } from "@/generated/open-api/customer/customer";
import { convertGetPointMile200ToPointMile } from "@/models/mile/converters";

export const useParsedGetPointMile = () => {
  const { data, ...rest } = useGetPointMileSuspense();

  const pointMile = useMemo(() => {
    return convertGetPointMile200ToPointMile(data);
  }, [data]);

  return { data: pointMile, ...rest };
};
