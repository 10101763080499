import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./HealthyBody.module.scss";

export function HealthyBody(): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-3 col-offset-m-1 text__center pd__bottom__l pd__bottom__off__pc">
          <Image
            src="https://asset.basefood.co.jp/lp/form/bb_maple_v1_6.png"
            alt="BASE BREAD メープル"
            aspectRatio="448 / 513"
            containerClassName={clsx(styles.image, styles.proteinImage, styles.proteinKvImage)}
          />
        </div>
        <div className="col-12 col-m-7">
          <p className="text__bold text__xl mg__bottom__m text__center">
            低糖質・高たんぱくで
            <br />
            理想のからだづくりを
            <span className="wsnr">サポート！</span>
          </p>
          <Image
            src="https://asset.basefood.co.jp/lp/form/0805_points.png"
            alt="3つの特徴"
            aspectRatio="992 / 305"
            containerClassName={clsx(styles.image, styles.proteinImage)}
          />
        </div>
      </div>
      <div className="row mg__top__xl">
        <div
          className={clsx(
            "col-12",
            "col-m-10",
            "col-offset-m-1",
            "bg__white",
            styles.proteinCompare
          )}
        >
          <p className="text__l text__bold text__center mg__bottom__l">
            プロテインと併用することで、
            <br />
            <span className={clsx("wsnr", styles.proteinMarkpen)}>約54g</span>
            のたんぱく質が
            <span className="wsnr">摂取できる！</span>
          </p>
          <Image
            src="https://asset.basefood.co.jp/lp/form/protein_bb_choco.png"
            alt="プロテインと併用することで、約53gのたんぱく質が摂取できる！"
            aspectRatio="11 / 5"
            containerClassName={clsx(styles.image, styles.proteinCompareImage)}
          />
          <div className={styles.proteinCompareImage}>
            <div className="row mg__top__s">
              <div className="col-6">
                <p className="text__m text__center mg__top__s">プロテイン 1杯※5</p>
              </div>
              <div className="col-6">
                <p className="text__m text__center">
                  プロテイン 1杯
                  <br />+ BASE BREAD
                </p>
              </div>
            </div>
            <div className="row mg__top__s">
              <div className="col-6">
                <p className="text__l text__center text__bold">
                  たんぱく質 約<span className="text__xl">26g</span>
                </p>
              </div>
              <div className="col-6">
                <p className="text__l text__center text__bold">
                  たんぱく質{" "}
                  <span className={styles.proteinMarkpen}>
                    約<span className="text__xl">54g</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <p className="text__m mg__top__l">
            さらに、BASE FOODに含まれる
            <span className={clsx(styles.proteinMarkpen, "text__bold wsnr")}>ビタミンB6</span>
            が、たんぱく質の働きを助け、効率のよい吸収をサポートします。
          </p>
        </div>
        <div className="col-12 col-m-10 col-offset-m-1 mg__top__l">
          <p className="text__s text__gray__dark protein__note">
            ※1
            1食分（2袋）で、栄養素等表示基準値に基づき、脂質・飽和脂肪酸・炭水化物・ナトリウム以外のすべての栄養素で1日分の基準値の1/3以上を含む。
            <br />
            <span className="clear__sp">
              ※2 Amazonストアのパン部門の売れ筋ランキングでBASE BREADが1位を獲得（2023年12月時点）
            </span>
            ※3 BASE BREAD チョコレートと、日本食品標準成分表2020年版（八訂）より、菓子パン
            あんなしとの比較（同量）
            <br />
            ※4 BASE BREAD 1食（2袋）の場合
            <br />
            ※5 推定値。カロリーSlismよりカゼイン30gの場合
          </p>
        </div>
      </div>
    </div>
  );
}
