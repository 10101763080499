"use client";
import { useMemo, useRef } from "react";

import clsx from "clsx";

import { Column } from "@/components/containers";
import { PanelSelector, Selector, SelectorOption } from "@/components/inputs";
import { getDomesticProvince } from "@/utils/province";

import { deliveryReceiveOptions, deliveryTimeZoneOptions } from "./consts";
import { DeliveryCautions } from "./DeliveryCautions";
import styles from "./DeliveryDateSelector.module.scss";
import { DeliveryOptionsValues } from "../CheckoutForm/schema";

interface DeliveryDateSelectorProps {
  onChangeDeliveryOptions: (option: DeliveryOptionsValues) => void;
  deliveryOptions: DeliveryOptionsValues;
  province?: string; // 置き配に対応しているかどうかに利用
  isFreezeDelivery?: boolean;
  isNormalDelivery?: boolean;
  isSubscription?: boolean;
  disabledReceiveOption?: boolean;
  disabledReceiveOptionReason?: string;
  /**
   * 日付選択のオプションを指定する場合に利用
   * 本来はバックエンドでのみコントロールすべきだが
   * アウトレット商品の場合のみバックエンドで制御できないため、このようなpropsを用意している
   */
  deliveryDateOptions: SelectorOption<string>[];
}

const dateToStr = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}/${month}/${day}`;
};

const strToDate = (str: string) => {
  const [year, month, date] = str.split("/").map((v) => parseInt(v, 10));
  return new Date(year, month - 1, date);
};

export function DeliveryDateSelector({
  onChangeDeliveryOptions,
  deliveryOptions,
  province,
  isFreezeDelivery = false,
  isNormalDelivery = false,
  isSubscription = false,
  disabledReceiveOption = false,
  disabledReceiveOptionReason,
  deliveryDateOptions,
}: DeliveryDateSelectorProps): React.ReactNode {
  const isSpecifiedDate = !!deliveryOptions.deliveryDate;
  const prevDeliveryDateRef = useRef<Date>();

  const supportDropOff = useMemo(() => {
    const provinceObj = province ? getDomesticProvince(province) : undefined;
    return provinceObj?.supportDropOff ?? false;
  }, [province]);

  return (
    <>
      <PanelSelector
        value={isSpecifiedDate}
        onChange={(value) =>
          onChangeDeliveryOptions({
            ...deliveryOptions,
            deliveryDate: value
              ? (prevDeliveryDateRef.current ?? strToDate(deliveryDateOptions[0].value))
              : null,
          })
        }
        options={[
          {
            title: "日付を指定せずにお届け",
            value: false,
            content: (
              <Column className={styles.itemContainer}>
                <Column className={clsx("text__m", styles.itemRow)}>
                  <p className={styles.deliveryTypeTag}>
                    {isNormalDelivery && (
                      <span className="tag inline yellow text__white mini square">常温配送</span>
                    )}
                    {isFreezeDelivery && (
                      <span className="tag inline darkblue text__white mini square">冷凍配送</span>
                    )}
                  </p>
                  <p>受取方法：</p>
                </Column>
                <Selector
                  className={styles.selector}
                  options={deliveryReceiveOptions}
                  value={isFreezeDelivery ? "00" : deliveryOptions.deliveryReceiveOption}
                  disabled={disabledReceiveOption}
                  onChange={(value) =>
                    onChangeDeliveryOptions({
                      ...deliveryOptions,
                      deliveryReceiveOption: value,
                    })
                  }
                />
                <div className="mg__top__s">
                  {disabledReceiveOption && (
                    <p className="text__s text__gray__dark">{disabledReceiveOptionReason}</p>
                  )}
                </div>
              </Column>
            ),
          },
          {
            title: "日付を指定してお届け",
            value: true,
            content: (
              <Column className={styles.itemContainer}>
                <p className={styles.itemRow}>
                  {isNormalDelivery && (
                    <span className="tag inline yellow text__white mini square">常温配送</span>
                  )}
                  {isFreezeDelivery && (
                    <span className="tag inline darkblue text__white mini square">冷凍配送</span>
                  )}
                </p>
                <div className={styles.dateSelectorsContainer}>
                  <Selector
                    className={styles.selector}
                    options={deliveryDateOptions}
                    value={
                      deliveryOptions.deliveryDate ? dateToStr(deliveryOptions.deliveryDate) : ""
                    }
                    onChange={(value) => {
                      const deliveryDate = strToDate(value);
                      prevDeliveryDateRef.current = deliveryDate;
                      onChangeDeliveryOptions({ ...deliveryOptions, deliveryDate });
                    }}
                  />
                  <Selector
                    className={styles.selector}
                    options={deliveryTimeZoneOptions}
                    value={deliveryOptions.deliveryTimezone}
                    onChange={(value) =>
                      onChangeDeliveryOptions({
                        ...deliveryOptions,
                        deliveryTimezone: value,
                      })
                    }
                  />
                </div>
                <p className={clsx("text__m", styles.itemRow)}>受取方法：</p>
                <Selector
                  className={styles.selector}
                  options={deliveryReceiveOptions}
                  value={isFreezeDelivery ? "00" : deliveryOptions.deliveryReceiveOption}
                  disabled={disabledReceiveOption}
                  onChange={(value) =>
                    onChangeDeliveryOptions({
                      ...deliveryOptions,
                      deliveryReceiveOption: value,
                    })
                  }
                />
                <div className="mg__top__s">
                  {disabledReceiveOption && (
                    <p className="text__s text__gray__dark">{disabledReceiveOptionReason}</p>
                  )}
                </div>
              </Column>
            ),
          },
        ]}
      />
      <DeliveryCautions
        supportDropOff={supportDropOff}
        isFreezeDelivery={isFreezeDelivery}
        isNormalDelivery={isNormalDelivery}
        isSubscription={isSubscription}
      />
    </>
  );
}
