import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./Faq.module.scss";
import { FaqSectionParam } from "../FaqSection";

export function Faq({ question, answer }: FaqSectionParam): React.ReactNode {
  return (
    <>
      <dl className={styles.proteinQa}>
        <dt>
          <Image
            src="https://asset.basefood.co.jp/lp/form_02/q.png"
            alt="Q"
            aspectRatio="1 / 1"
            size={{ width: 40, height: 40 }}
            containerClassName={styles.image}
          />
        </dt>
        <dd className={clsx("text__bold", styles.proteinMarkpen)}>{question}</dd>
      </dl>
      <dl className={styles.proteinQa}>
        <dt>
          <Image
            src="https://asset.basefood.co.jp/lp/form_02/a.png"
            alt="A"
            aspectRatio="1 / 1"
            size={{ width: 40, height: 40 }}
            containerClassName={styles.image}
          />
        </dt>
        <dd>{answer}</dd>
      </dl>
    </>
  );
}
