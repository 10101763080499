import { Stripe, StripeCardNumberElement, StripeError } from "@stripe/stripe-js";
import pRetry from "p-retry";

import { ValidationError } from "./error";

let stripe: Stripe | null = null;

export const loadStripe = async (): Promise<Stripe> => {
  if (stripe) return stripe;
  // 動的インポートにより、テスト環境でのエラーを回避
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { loadStripe: _loadStripe } = require("@stripe/stripe-js");
  const res = await pRetry(() => _loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!, {}), {
    retries: 3,
  });
  stripe = res!;
  return res!;
};

export const createStripeToken = async (cardElement: StripeCardNumberElement) => {
  try {
    const stripe = await loadStripe();
    const { token: stripeData, error } = await stripe.createToken(cardElement);
    const stripeToken = stripeData?.id;
    if (error) {
      throw new ValidationError(error.message);
    }
    if (!stripeToken) {
      throw new ValidationError("カードの情報が正しくありません");
    }
    return stripeToken;
  } catch (error) {
    if ("message" in (error as StripeError)) {
      throw new ValidationError((error as StripeError).message);
    }
    throw new ValidationError("カードの情報が正しくありません");
  }
};
