import { format } from "date-fns";
import { ja } from "date-fns/locale";

export type TimeZone = {
  tokyo: "Asia/Tokyo";
};

export type TimeZoneInfo = {
  timezone_type: number; //  snake_caseなのが絶妙にここだけなので共通で一旦snake_caseにする
  timezone: TimeZone;
};

export type DateTime = {
  year: number;
  month: number;
  day: number;
  dayOfWeek: number;
  dayOfYear: number;
  hour: number;
  minute: number;
  second: number;
  micro: number;
  timestamp: number;
  formatted: string;
  timezone: TimeZoneInfo;
};

export const getWeekDay = (dateString: string) => {
  return format(dateString, "E", { locale: ja });
};

export const toLocalISOString = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssXXX");
};

export const toJpDateAbbrString = (dateStr: string): string => {
  return format(dateStr, "MM/dd(E)", { locale: ja });
};
