/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type {
  DeleteMileGiftsTrades200,
  DeleteMileGiftsTradesParams,
  GetMileGifts200,
  GetMileGiftsTrades200,
  GetMileHistories200,
  GetMileHistoriesParams,
  GetMileSummary200,
  PostMileGiftsTrades200,
  PostMileGiftsTrades400,
  PostMileGiftsTrades500,
  PostMileGiftsTradesParams,
  UpdateMileShowStatusBody,
} from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary マイルの計算結果取得
 */
export const getMileSummary = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetMileSummary200>(
    { url: `/mile/summary`, method: "GET", signal },
    options
  );
};

export const getGetMileSummaryQueryKey = () => {
  return [`/mile/summary`] as const;
};

export const getGetMileSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getMileSummary>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileSummary>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMileSummaryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMileSummary>>> = ({ signal }) =>
    getMileSummary(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMileSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMileSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getMileSummary>>>;
export type GetMileSummaryQueryError = ErrorType<unknown>;

/**
 * @summary マイルの計算結果取得
 */
export const useGetMileSummary = <
  TData = Awaited<ReturnType<typeof getMileSummary>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileSummary>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMileSummaryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary マイルの計算結果取得
 */
export const prefetchGetMileSummary = async <
  TData = Awaited<ReturnType<typeof getMileSummary>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileSummary>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMileSummaryQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetMileSummarySuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMileSummary>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileSummary>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMileSummaryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMileSummary>>> = ({ signal }) =>
    getMileSummary(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMileSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMileSummarySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMileSummary>>
>;
export type GetMileSummarySuspenseQueryError = ErrorType<unknown>;

/**
 * @summary マイルの計算結果取得
 */
export const useGetMileSummarySuspense = <
  TData = Awaited<ReturnType<typeof getMileSummary>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileSummary>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMileSummarySuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary マイルの計算結果取得
 */
export const prefetchGetMileSummarySuspense = async <
  TData = Awaited<ReturnType<typeof getMileSummary>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileSummary>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMileSummarySuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary マイルの履歴取得
 */
export const getMileHistories = (
  params: GetMileHistoriesParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetMileHistories200>(
    { url: `/mile/histories`, method: "GET", params, signal },
    options
  );
};

export const getGetMileHistoriesQueryKey = (params: GetMileHistoriesParams) => {
  return [`/mile/histories`, ...(params ? [params] : [])] as const;
};

export const getGetMileHistoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getMileHistories>>,
  TError = ErrorType<unknown>,
>(
  params: GetMileHistoriesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileHistories>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMileHistoriesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMileHistories>>> = ({ signal }) =>
    getMileHistories(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMileHistories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMileHistoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getMileHistories>>>;
export type GetMileHistoriesQueryError = ErrorType<unknown>;

/**
 * @summary マイルの履歴取得
 */
export const useGetMileHistories = <
  TData = Awaited<ReturnType<typeof getMileHistories>>,
  TError = ErrorType<unknown>,
>(
  params: GetMileHistoriesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileHistories>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMileHistoriesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary マイルの履歴取得
 */
export const prefetchGetMileHistories = async <
  TData = Awaited<ReturnType<typeof getMileHistories>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetMileHistoriesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileHistories>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMileHistoriesQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetMileHistoriesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMileHistories>>,
  TError = ErrorType<unknown>,
>(
  params: GetMileHistoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileHistories>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMileHistoriesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMileHistories>>> = ({ signal }) =>
    getMileHistories(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMileHistories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMileHistoriesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMileHistories>>
>;
export type GetMileHistoriesSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary マイルの履歴取得
 */
export const useGetMileHistoriesSuspense = <
  TData = Awaited<ReturnType<typeof getMileHistories>>,
  TError = ErrorType<unknown>,
>(
  params: GetMileHistoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileHistories>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMileHistoriesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary マイルの履歴取得
 */
export const prefetchGetMileHistoriesSuspense = async <
  TData = Awaited<ReturnType<typeof getMileHistories>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetMileHistoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileHistories>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMileHistoriesSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary マイル交換対象ギフトの一覧取得
 */
export const getMileGifts = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetMileGifts200>(
    { url: `/mile/gifts`, method: "GET", signal },
    options
  );
};

export const getGetMileGiftsQueryKey = () => {
  return [`/mile/gifts`] as const;
};

export const getGetMileGiftsQueryOptions = <
  TData = Awaited<ReturnType<typeof getMileGifts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileGifts>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMileGiftsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMileGifts>>> = ({ signal }) =>
    getMileGifts(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMileGifts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMileGiftsQueryResult = NonNullable<Awaited<ReturnType<typeof getMileGifts>>>;
export type GetMileGiftsQueryError = ErrorType<unknown>;

/**
 * @summary マイル交換対象ギフトの一覧取得
 */
export const useGetMileGifts = <
  TData = Awaited<ReturnType<typeof getMileGifts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileGifts>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMileGiftsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary マイル交換対象ギフトの一覧取得
 */
export const prefetchGetMileGifts = async <
  TData = Awaited<ReturnType<typeof getMileGifts>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileGifts>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMileGiftsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetMileGiftsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMileGifts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileGifts>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMileGiftsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMileGifts>>> = ({ signal }) =>
    getMileGifts(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMileGifts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMileGiftsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getMileGifts>>>;
export type GetMileGiftsSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary マイル交換対象ギフトの一覧取得
 */
export const useGetMileGiftsSuspense = <
  TData = Awaited<ReturnType<typeof getMileGifts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileGifts>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMileGiftsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary マイル交換対象ギフトの一覧取得
 */
export const prefetchGetMileGiftsSuspense = async <
  TData = Awaited<ReturnType<typeof getMileGifts>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileGifts>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMileGiftsSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary マイル交換予定ギフトの一覧取得
 */
export const getMileGiftsTrades = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetMileGiftsTrades200>(
    { url: `/mile/gifts/trades`, method: "GET", signal },
    options
  );
};

export const getGetMileGiftsTradesQueryKey = () => {
  return [`/mile/gifts/trades`] as const;
};

export const getGetMileGiftsTradesQueryOptions = <
  TData = Awaited<ReturnType<typeof getMileGiftsTrades>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileGiftsTrades>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMileGiftsTradesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMileGiftsTrades>>> = ({ signal }) =>
    getMileGiftsTrades(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMileGiftsTrades>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMileGiftsTradesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMileGiftsTrades>>
>;
export type GetMileGiftsTradesQueryError = ErrorType<unknown>;

/**
 * @summary マイル交換予定ギフトの一覧取得
 */
export const useGetMileGiftsTrades = <
  TData = Awaited<ReturnType<typeof getMileGiftsTrades>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileGiftsTrades>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMileGiftsTradesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary マイル交換予定ギフトの一覧取得
 */
export const prefetchGetMileGiftsTrades = async <
  TData = Awaited<ReturnType<typeof getMileGiftsTrades>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMileGiftsTrades>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMileGiftsTradesQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetMileGiftsTradesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMileGiftsTrades>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileGiftsTrades>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMileGiftsTradesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMileGiftsTrades>>> = ({ signal }) =>
    getMileGiftsTrades(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMileGiftsTrades>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMileGiftsTradesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMileGiftsTrades>>
>;
export type GetMileGiftsTradesSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary マイル交換予定ギフトの一覧取得
 */
export const useGetMileGiftsTradesSuspense = <
  TData = Awaited<ReturnType<typeof getMileGiftsTrades>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileGiftsTrades>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMileGiftsTradesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary マイル交換予定ギフトの一覧取得
 */
export const prefetchGetMileGiftsTradesSuspense = async <
  TData = Awaited<ReturnType<typeof getMileGiftsTrades>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMileGiftsTrades>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMileGiftsTradesSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary マイルをギフトに交換
 */
export const postMileGiftsTrades = (
  params: PostMileGiftsTradesParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<PostMileGiftsTrades200>(
    { url: `/mile/gifts/trades`, method: "POST", params },
    options
  );
};

export const getPostMileGiftsTradesMutationOptions = <
  TError = ErrorType<PostMileGiftsTrades400 | PostMileGiftsTrades500>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postMileGiftsTrades>>,
    TError,
    { params: PostMileGiftsTradesParams },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postMileGiftsTrades>>,
  TError,
  { params: PostMileGiftsTradesParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postMileGiftsTrades>>,
    { params: PostMileGiftsTradesParams }
  > = (props) => {
    const { params } = props ?? {};

    return postMileGiftsTrades(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostMileGiftsTradesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postMileGiftsTrades>>
>;

export type PostMileGiftsTradesMutationError = ErrorType<
  PostMileGiftsTrades400 | PostMileGiftsTrades500
>;

/**
 * @summary マイルをギフトに交換
 */
export const usePostMileGiftsTrades = <
  TError = ErrorType<PostMileGiftsTrades400 | PostMileGiftsTrades500>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postMileGiftsTrades>>,
    TError,
    { params: PostMileGiftsTradesParams },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getPostMileGiftsTradesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 交換予定ギフトの取り消し
 */
export const deleteMileGiftsTrades = (
  params: DeleteMileGiftsTradesParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<DeleteMileGiftsTrades200>(
    { url: `/mile/gifts/trades`, method: "DELETE", params },
    options
  );
};

export const getDeleteMileGiftsTradesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMileGiftsTrades>>,
    TError,
    { params: DeleteMileGiftsTradesParams },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMileGiftsTrades>>,
  TError,
  { params: DeleteMileGiftsTradesParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMileGiftsTrades>>,
    { params: DeleteMileGiftsTradesParams }
  > = (props) => {
    const { params } = props ?? {};

    return deleteMileGiftsTrades(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMileGiftsTradesMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMileGiftsTrades>>
>;

export type DeleteMileGiftsTradesMutationError = ErrorType<unknown>;

/**
 * @summary 交換予定ギフトの取り消し
 */
export const useDeleteMileGiftsTrades = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMileGiftsTrades>>,
    TError,
    { params: DeleteMileGiftsTradesParams },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getDeleteMileGiftsTradesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update mile show
 */
export const updateMileShowStatus = (
  updateMileShowStatusBody: UpdateMileShowStatusBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/miles/show/status`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateMileShowStatusBody,
    },
    options
  );
};

export const getUpdateMileShowStatusMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMileShowStatus>>,
    TError,
    { data: UpdateMileShowStatusBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateMileShowStatus>>,
  TError,
  { data: UpdateMileShowStatusBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMileShowStatus>>,
    { data: UpdateMileShowStatusBody }
  > = (props) => {
    const { data } = props ?? {};

    return updateMileShowStatus(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMileShowStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateMileShowStatus>>
>;
export type UpdateMileShowStatusMutationBody = UpdateMileShowStatusBody;
export type UpdateMileShowStatusMutationError = ErrorType<unknown>;

/**
 * @summary update mile show
 */
export const useUpdateMileShowStatus = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMileShowStatus>>,
    TError,
    { data: UpdateMileShowStatusBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getUpdateMileShowStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
