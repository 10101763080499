import { useCallback, useState } from "react";

import { PanelCheckbox, TextField } from "@/components/inputs";

import styles from "./CouponCheckbox.module.scss";

export interface CouponCheckboxProps {
  coupon?: string;
  onChange?: (coupon: string) => void;
}

export function CouponCheckbox({ coupon = "", onChange }: CouponCheckboxProps): React.ReactNode {
  // チェックがついた時にクーポンを復帰させるために内部状態を持つ
  const [innerCoupon, setInnerCoupon] = useState(coupon);
  const [checked, setChecked] = useState(Boolean(coupon));

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setChecked(isChecked);
      // チェックが復帰したときにクーポンを直前の値に戻す
      onChange?.(isChecked ? innerCoupon : "");
    },
    [innerCoupon, onChange]
  );

  const handleCouponChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newCoupon = event.target.value;
      setInnerCoupon(newCoupon);
      onChange?.(newCoupon);
    },
    [onChange]
  );

  return (
    <PanelCheckbox
      checked={checked}
      onChange={handleCheckboxChange}
      content={
        <div className={styles.inputWrapper}>
          <TextField value={coupon} onChange={handleCouponChange} />
        </div>
      }
    >
      クーポンを持っている
    </PanelCheckbox>
  );
}
