import { format } from "date-fns";

import { Address, TimeStamp, CustomerResponseCustomer } from "@/generated/open-api/schemas";
import { NuxtStoreCustomer } from "@/storage/types";

import { AddressModel, CustomerModel } from "./type";
import { convertDateStrToTimeStamp } from "../common";

export const convertApiCustomerToCustomer = (
  apiCustomer: CustomerResponseCustomer
): CustomerModel => {
  return {
    acceptsMarketing: apiCustomer.accepts_marketing,
    address: convertApiAddressToAddress(apiCustomer.address),
    birthday: convertDateStrToTimeStamp(apiCustomer.birthday),
    createdAt: convertDateStrToTimeStamp(apiCustomer.created_at),
    deletedAt: convertDateStrToTimeStamp(apiCustomer.deleted_at),
    email: apiCustomer.email,
    firstName: apiCustomer.first_name,
    id: apiCustomer.id,
    isReceiveRemind: apiCustomer.is_receive_remind,
    isLineUserActivated: apiCustomer.is_line_user_activated,
    lastName: apiCustomer.last_name,
    password: apiCustomer.password,
    phone: apiCustomer.phone,
    shopifyId: apiCustomer.shopify_id,
    updatedAt: convertDateStrToTimeStamp(apiCustomer.updated_at),
    verifiedEmail: apiCustomer.verified_email,
  };
};

export const convertApiAddressToAddress = (
  apiAddress?: Address | null
): AddressModel | undefined => {
  if (!apiAddress || Object.keys(apiAddress).length === 0) {
    return undefined;
  }

  return {
    addressLine1: apiAddress.address_line1,
    addressLine2: apiAddress.address_line2,
    city: apiAddress.city,
    convertedProvince: apiAddress.converted_province,
    firstName: apiAddress.first_name,
    lastName: apiAddress.last_name,
    phone: apiAddress.phone,
    province: apiAddress.province,
    value: apiAddress.value,
    zip: apiAddress.zip,
  };
};

export const timeStampToString = <T extends TimeStamp | undefined>(
  timeStamp: T
): T extends undefined ? string | undefined : string => {
  if (!timeStamp) return undefined as T extends undefined ? string | undefined : string;
  const { year, month, day, hour, minute, second } = timeStamp;
  return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
};

export const convertCustomerToApiCustomer = (customer: CustomerModel): CustomerResponseCustomer => {
  return {
    accepts_marketing: customer.acceptsMarketing ?? false,
    address: convertAddressToApiAddress(customer.address),
    birthday: customer.birthday ? convertTimeStampToString(customer.birthday) : undefined,
    created_at: convertTimeStampToString(customer.createdAt),
    deleted_at: customer.deletedAt ? convertTimeStampToString(customer.deletedAt) : undefined,
    email: customer.email,
    first_name: customer.firstName,
    id: customer.id,
    is_receive_remind: customer.isReceiveRemind ?? false,
    is_line_user_activated: customer.isLineUserActivated,
    last_name: customer.lastName,
    password: customer.password,
    phone: customer.phone,
    shopify_id: customer.shopifyId,
    updated_at: convertTimeStampToString(customer.updatedAt),
    verified_email: customer.verifiedEmail,
  };
};

type ConvertAddressToApiAddressReturn<T extends Partial<AddressModel> | undefined> =
  T extends undefined ? Address | undefined : Address;

export const convertAddressToApiAddress = <T extends Partial<AddressModel> | undefined>(
  address: T
): ConvertAddressToApiAddressReturn<T> => {
  if (!address) {
    return undefined as ConvertAddressToApiAddressReturn<T>;
  }

  return {
    address_line1: address.addressLine1 || "",
    address_line2: address.addressLine2,
    city: address.city || "",
    converted_province: address.convertedProvince || "",
    first_name: address.firstName || "",
    last_name: address.lastName || "",
    phone: address.phone || "",
    province: address.province || "",
    value: address.value,
    zip: address.zip || "",
  };
};

export const convertTimeStampToString = (timeStamp: TimeStamp): string => {
  const { year, month, day, hour, minute, second } = timeStamp;
  const date = new Date(year, month, day, hour, minute, second);
  // "2024/04/30 09:31:24"のフォーマットで返す
  return format(date, "yyyy/MM/dd HH:mm:ss");
};

export const convertTimeStampToApiTimeStamp = (timeStamp?: TimeStamp): TimeStamp | undefined => {
  if (!timeStamp) {
    return undefined;
  }

  return {
    day: timeStamp.day,
    dayOfWeek: timeStamp.dayOfWeek,
    dayOfYear: timeStamp.dayOfYear,
    formatted: timeStamp.formatted,
    hour: timeStamp.hour,
    micro: timeStamp.micro,
    minute: timeStamp.minute,
    month: timeStamp.month,
    second: timeStamp.second,
    timestamp: timeStamp.timestamp,
    timezone: timeStamp.timezone,
    year: timeStamp.year,
  };
};

export const convertLocalStorageCustomerToCustomer = <
  T extends NuxtStoreCustomer["customer"] | undefined,
>(
  customer: T
): T extends undefined ? CustomerModel | undefined : CustomerModel => {
  if (!customer)
    return undefined as T extends undefined ? CustomerModel | undefined : CustomerModel;

  const res: CustomerModel = {
    acceptsMarketing: customer.accepts_marketing,
    address: convertApiAddressToAddress(customer.address),
    birthday: convertDateStrToTimeStamp(customer.birthday),
    createdAt: convertDateStrToTimeStamp(customer.created_at),
    updatedAt: convertDateStrToTimeStamp(customer.updated_at),
    deletedAt: convertDateStrToTimeStamp(customer.deleted_at),
    email: customer.email,
    firstName: customer.first_name,
    id: customer.id,
    isReceiveRemind: customer.is_receive_remind ?? false,
    lastName: customer.last_name,
    password: customer.password,
    phone: customer.phone,
    shopifyId: customer.shopify_id,
    verifiedEmail: customer.verified_email,
  };
  return res;
};

export const convertCustomerToLocalStorageCustomer = (
  customer: CustomerModel | undefined
): NuxtStoreCustomer["customer"] | undefined => {
  if (!customer) {
    return undefined;
  }
  return {
    id: customer.id!,
    email: customer.email!,
    shopify_id: customer.shopifyId!,
    accepts_marketing: customer.acceptsMarketing!,
    first_name: customer.firstName!,
    last_name: customer.lastName!,
    verified_email: customer.verifiedEmail!,
    is_receive_remind: customer.isReceiveRemind!,
    address: convertAddressToApiAddress(customer.address)!,
    birthday: customer.birthday ? timeStampToString(customer.birthday) : undefined,
    updated_at: customer.updatedAt ? timeStampToString(customer.updatedAt) : "",
    created_at: customer.createdAt ? timeStampToString(customer.createdAt) : "",
    phone: customer.phone!,
    password: customer.password!,
    deleted_at: customer.deletedAt ? timeStampToString(customer.deletedAt) : undefined,
  };
};

export const convertApiCustomerToLocalStorageCustomer = (
  customer: CustomerResponseCustomer
): NuxtStoreCustomer["customer"] | undefined => {
  if (!customer) {
    return undefined;
  }
  return {
    id: customer.id ?? "",
    first_name: customer.first_name ?? "",
    last_name: customer.last_name ?? "",
    email: customer.email ?? "",
    phone: customer.phone,
    birthday: customer.birthday,
    accepts_marketing: customer.accepts_marketing ?? false,
    verified_email: customer.verified_email ?? false,
    is_receive_remind: customer.is_receive_remind,
    password: customer.password,
    shopify_id: customer.shopify_id,
    created_at: customer.created_at,
    updated_at: customer.updated_at,
    deleted_at: customer.deleted_at,
    address: {
      first_name: customer.first_name!,
      last_name: customer.last_name!,
      province: customer.address?.province ?? "",
      zip: customer.address?.zip ?? "",
      city: customer.address?.city ?? "",
      address_line1: customer.address?.address_line1 ?? "",
      address_line2: customer.address?.address_line2 ?? "",
      phone: customer.phone ?? "",
      converted_province: customer.address?.province ?? "",
      // value: customer.value,
    },
  };
};
