import { OrderWithDeliveryDate } from "@/generated/open-api/schemas";

import { OrderWithDeliveryDateModel } from "./type";

export const convertApiOrderWithDeliveryDateToOrderWithDeliveryDate = (
  date: OrderWithDeliveryDate
): OrderWithDeliveryDateModel => {
  return {
    deliveryDate: date.delivery_date,
    deliveryStatus: date.delivery_status,
    deliveryTimezone: date.delivery_timezone,
    deliveryTimezoneStr: date.delivery_timezone_str,
    isFastDelivery: date.is_fast_delivery,
    displayDeliveryDate: date.display_delivery_date,
  };
};
