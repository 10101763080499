"use client";

import React from "react";

import clsx from "clsx";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./EgiftSection.module.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../template/lp/Form01/styles/swipper.scss";

export interface EgiftSectionProps {
  title: string;
  styleClass: "sender" | "receiver";
  items: Array<{ id: number; title: string; content: string; img: string }>;
  sectionId: string;
}

export function EgiftSection({
  title,
  styleClass,
  items,
  sectionId,
}: EgiftSectionProps): React.ReactNode {
  return (
    <>
      <div id={sectionId} className="row">
        <div className="col-12 text__center pd__top__xl pd__bottom__l">
          <span
            className={clsx(styles.title, styles[styleClass], "text__xl text__white text__bold")}
          >
            {title}
          </span>
        </div>
        <div className={clsx(styles.clearSpFlex, "row")}>
          {items.map((step) => (
            <div key={step.id} className="col-12 col-m-4">
              <div className={clsx(styles.step, styles[styleClass])}>
                <span
                  className={clsx(
                    styles.number,
                    styles[styleClass],
                    "text__m text__white text__bold"
                  )}
                >
                  {step.id}
                </span>
                <p
                  className={clsx(
                    styles.stepTitle,
                    "text__l text__bold text__center mg__top__s mg__bottom__m"
                  )}
                  dangerouslySetInnerHTML={{ __html: step.title }}
                />
                <p
                  className={clsx(styles.stepDetail, "text__m text__center")}
                  dangerouslySetInnerHTML={{ __html: step.content }}
                />
                <img src={step.img} className={styles.stepImage} alt={step.title} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={clsx(styles.slideWrap, "clear__pc")}>
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={1.02}
          className={styles.swiperWrapper}
          navigation={true}
        >
          {items.map((step) => (
            <SwiperSlide key={step.id}>
              <div className={clsx(styles.step, styles[styleClass])}>
                <span
                  className={clsx(
                    styles.number,
                    styles[styleClass],
                    "text__m text__white text__bold"
                  )}
                >
                  {step.id}
                </span>
                <p
                  className="text__l text__bold text__center mg__top__s mg__bottom__m"
                  dangerouslySetInnerHTML={{ __html: step.title }}
                />
                <p
                  className={clsx(styles.stepDetail, "text__m text__center")}
                  dangerouslySetInnerHTML={{ __html: step.content }}
                />
                <img src={step.img} className={styles.stepImage} alt={step.title} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
