/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type {
  CartCalcApiResponse,
  CartValidateResponse,
  GetCartParams,
  GetCartValidDates200,
  GetCheckoutProgress200,
  GetCheckoutProgress404,
  PostCartBody,
  SaveCheckoutProgress200,
  SaveCheckoutProgress400,
  SaveCheckoutProgressBody,
  ValidateCart400,
  ValidateCartBody,
} from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary calc cart product price
 */
export const postCart = (
  postCartBody: PostCartBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(postCartBody);
  return customAxiosInstance<CartCalcApiResponse>(
    {
      url: `/cart`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

export const getPostCartQueryKey = (postCartBody: PostCartBody) => {
  return [`/cart`, postCartBody] as const;
};

export const getPostCartQueryOptions = <
  TData = Awaited<ReturnType<typeof postCart>>,
  TError = ErrorType<unknown>,
>(
  postCartBody: PostCartBody,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof postCart>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPostCartQueryKey(postCartBody);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof postCart>>> = () =>
    postCart(postCartBody, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof postCart>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PostCartQueryResult = NonNullable<Awaited<ReturnType<typeof postCart>>>;
export type PostCartQueryError = ErrorType<unknown>;

/**
 * @summary calc cart product price
 */
export const usePostCart = <
  TData = Awaited<ReturnType<typeof postCart>>,
  TError = ErrorType<unknown>,
>(
  postCartBody: PostCartBody,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof postCart>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPostCartQueryOptions(postCartBody, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPostCartSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof postCart>>,
  TError = ErrorType<unknown>,
>(
  postCartBody: PostCartBody,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof postCart>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPostCartQueryKey(postCartBody);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof postCart>>> = () =>
    postCart(postCartBody, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof postCart>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PostCartSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof postCart>>>;
export type PostCartSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary calc cart product price
 */
export const usePostCartSuspense = <
  TData = Awaited<ReturnType<typeof postCart>>,
  TError = ErrorType<unknown>,
>(
  postCartBody: PostCartBody,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof postCart>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPostCartSuspenseQueryOptions(postCartBody, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary calc cart product price with query parameter
 */
export const getCart = (
  params: GetCartParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<CartCalcApiResponse>(
    { url: `/cart`, method: "GET", params, signal },
    options
  );
};

export const getGetCartQueryKey = (params: GetCartParams) => {
  return [`/cart`, ...(params ? [params] : [])] as const;
};

export const getGetCartQueryOptions = <
  TData = Awaited<ReturnType<typeof getCart>>,
  TError = ErrorType<unknown>,
>(
  params: GetCartParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCart>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCartQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCart>>> = ({ signal }) =>
    getCart(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCart>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCartQueryResult = NonNullable<Awaited<ReturnType<typeof getCart>>>;
export type GetCartQueryError = ErrorType<unknown>;

/**
 * @summary calc cart product price with query parameter
 */
export const useGetCart = <
  TData = Awaited<ReturnType<typeof getCart>>,
  TError = ErrorType<unknown>,
>(
  params: GetCartParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCart>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCartQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary calc cart product price with query parameter
 */
export const prefetchGetCart = async <
  TData = Awaited<ReturnType<typeof getCart>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCartParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCart>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCartQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCartSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCart>>,
  TError = ErrorType<unknown>,
>(
  params: GetCartParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCart>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCartQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCart>>> = ({ signal }) =>
    getCart(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCart>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCartSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCart>>>;
export type GetCartSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary calc cart product price with query parameter
 */
export const useGetCartSuspense = <
  TData = Awaited<ReturnType<typeof getCart>>,
  TError = ErrorType<unknown>,
>(
  params: GetCartParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCart>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCartSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary calc cart product price with query parameter
 */
export const prefetchGetCartSuspense = async <
  TData = Awaited<ReturnType<typeof getCart>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCartParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCart>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCartSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary validate user input as cart
 */
export const validateCart = (
  validateCartBody: ValidateCartBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(validateCartBody);
  return customAxiosInstance<CartValidateResponse>(
    {
      url: `/cart/validate`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

export const getValidateCartMutationOptions = <
  TError = ErrorType<ValidateCart400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateCart>>,
    TError,
    { data: ValidateCartBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof validateCart>>,
  TError,
  { data: ValidateCartBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateCart>>,
    { data: ValidateCartBody }
  > = (props) => {
    const { data } = props ?? {};

    return validateCart(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValidateCartMutationResult = NonNullable<Awaited<ReturnType<typeof validateCart>>>;
export type ValidateCartMutationBody = ValidateCartBody;
export type ValidateCartMutationError = ErrorType<ValidateCart400>;

/**
 * @summary validate user input as cart
 */
export const useValidateCart = <TError = ErrorType<ValidateCart400>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateCart>>,
    TError,
    { data: ValidateCartBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getValidateCartMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary save checkout prgress for email marketing
 */
export const saveCheckoutProgress = (
  saveCheckoutProgressBody: SaveCheckoutProgressBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(saveCheckoutProgressBody);
  return customAxiosInstance<SaveCheckoutProgress200>(
    {
      url: `/cart/in_progress`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

export const getSaveCheckoutProgressMutationOptions = <
  TError = ErrorType<SaveCheckoutProgress400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveCheckoutProgress>>,
    TError,
    { data: SaveCheckoutProgressBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof saveCheckoutProgress>>,
  TError,
  { data: SaveCheckoutProgressBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveCheckoutProgress>>,
    { data: SaveCheckoutProgressBody }
  > = (props) => {
    const { data } = props ?? {};

    return saveCheckoutProgress(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveCheckoutProgressMutationResult = NonNullable<
  Awaited<ReturnType<typeof saveCheckoutProgress>>
>;
export type SaveCheckoutProgressMutationBody = SaveCheckoutProgressBody;
export type SaveCheckoutProgressMutationError = ErrorType<SaveCheckoutProgress400>;

/**
 * @summary save checkout prgress for email marketing
 */
export const useSaveCheckoutProgress = <
  TError = ErrorType<SaveCheckoutProgress400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveCheckoutProgress>>,
    TError,
    { data: SaveCheckoutProgressBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getSaveCheckoutProgressMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get checkout prgress
 */
export const getCheckoutProgress = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetCheckoutProgress200>(
    { url: `/cart/get_progress`, method: "POST" },
    options
  );
};

export const getGetCheckoutProgressMutationOptions = <
  TError = ErrorType<GetCheckoutProgress404>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCheckoutProgress>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof getCheckoutProgress>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCheckoutProgress>>,
    void
  > = () => {
    return getCheckoutProgress(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetCheckoutProgressMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCheckoutProgress>>
>;

export type GetCheckoutProgressMutationError = ErrorType<GetCheckoutProgress404>;

/**
 * @summary get checkout prgress
 */
export const useGetCheckoutProgress = <
  TError = ErrorType<GetCheckoutProgress404>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCheckoutProgress>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getGetCheckoutProgressMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get cart valid dates
 */
export const getCartValidDates = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCartValidDates200>(
    { url: `/cart/valid_dates`, method: "GET", signal },
    options
  );
};

export const getGetCartValidDatesQueryKey = () => {
  return [`/cart/valid_dates`] as const;
};

export const getGetCartValidDatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getCartValidDates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCartValidDates>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCartValidDatesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCartValidDates>>> = ({ signal }) =>
    getCartValidDates(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCartValidDates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCartValidDatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCartValidDates>>
>;
export type GetCartValidDatesQueryError = ErrorType<unknown>;

/**
 * @summary get cart valid dates
 */
export const useGetCartValidDates = <
  TData = Awaited<ReturnType<typeof getCartValidDates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCartValidDates>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCartValidDatesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get cart valid dates
 */
export const prefetchGetCartValidDates = async <
  TData = Awaited<ReturnType<typeof getCartValidDates>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCartValidDates>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCartValidDatesQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCartValidDatesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCartValidDates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCartValidDates>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCartValidDatesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCartValidDates>>> = ({ signal }) =>
    getCartValidDates(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCartValidDates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCartValidDatesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCartValidDates>>
>;
export type GetCartValidDatesSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get cart valid dates
 */
export const useGetCartValidDatesSuspense = <
  TData = Awaited<ReturnType<typeof getCartValidDates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCartValidDates>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCartValidDatesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get cart valid dates
 */
export const prefetchGetCartValidDatesSuspense = async <
  TData = Awaited<ReturnType<typeof getCartValidDates>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCartValidDates>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCartValidDatesSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};
