"use client";
import { useCallback } from "react";

import { identity } from "ramda";

import { LocalStagePath, NuxtStoreFrontStore } from "./types";
import { useClientLocalStorage } from "./useClientLocalStorage";

const storeKey: LocalStagePath = `vuex.front-store.setNames`;

export const useClientFrontStoreSetNames = () => {
  const [setNames, setStoreSetNames] = useClientLocalStorage<NuxtStoreFrontStore["setNames"]>({
    key: storeKey,
    initialValue: [],
  });

  const addSetName = useCallback(
    (setName: string) => {
      setStoreSetNames((prev) => {
        // 重複を削除
        const newSetNames = Array.from(new Set([...(prev ?? []), setName])).filter(identity);
        return newSetNames;
      });
    },
    [setStoreSetNames]
  );
  return { setNames, addSetName };
};
