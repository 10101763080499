import toast, { ToastOptions } from "react-hot-toast";

export const htmlToast = {
  success: (messages: string[] | string, options?: ToastOptions) => {
    if (Array.isArray(messages)) {
      for (const message of messages) {
        toast.success(<div dangerouslySetInnerHTML={{ __html: message }} />, options);
      }
    } else {
      toast.success(<div dangerouslySetInnerHTML={{ __html: messages }} />, options);
    }
  },
  error: (messages: string[] | string, options?: ToastOptions) => {
    if (Array.isArray(messages)) {
      for (const message of messages) {
        toast.error(<div dangerouslySetInnerHTML={{ __html: message }} />, options);
      }
    } else {
      toast.error(<div dangerouslySetInnerHTML={{ __html: messages }} />, options);
    }
  },
  loading: (messages: string[] | string, options?: ToastOptions) => {
    if (Array.isArray(messages)) {
      for (const message of messages) {
        toast.loading(<div dangerouslySetInnerHTML={{ __html: message }} />, options);
      }
    } else {
      toast.loading(<div dangerouslySetInnerHTML={{ __html: messages }} />, options);
    }
  },
  custom: (messages: string[] | string, options?: ToastOptions) => {
    if (Array.isArray(messages)) {
      for (const message of messages) {
        toast.custom(<div dangerouslySetInnerHTML={{ __html: message }} />, options);
      }
    } else {
      toast.custom(<div dangerouslySetInnerHTML={{ __html: messages }} />, options);
    }
  },
};
