import { useId } from "react";

/**
 * Tooltipのidに`:`が含まれるとエラーになるため、`:`を削除する
 * [react-tooltip] "#:r1:" is not a valid CSS selector
 */
export const useTooltipId = (defaultId?: string | undefined) => {
  const id = useId();
  return defaultId ?? `tooltip-${id.replace(/\:/g, "")}`;
};
