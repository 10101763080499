"use client";

import { useEffect } from "react";

import ReCAPTCHA, { ReCAPTCHAProps } from "react-google-recaptcha";

export interface ReCaptchaProps extends ReCAPTCHAProps {}

export function ReCaptcha(props: ReCaptchaProps): React.ReactNode {
  useEffect(() => {
    /**
     * このコンポーネントは、Google reCAPTCHA Enterprise を使用するためのラッパーです。
     * @see https://github.com/dozoisch/react-google-recaptcha#global-properties-used-by-recaptcha
     */
    window.recaptchaOptions = {
      enterprise: true,
    };
  }, []);

  return <ReCAPTCHA {...props} />;
}
