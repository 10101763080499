import { useCallback, useState } from "react";

interface UseModalDialogTermsProps {
  onAccept: () => void;
}

export const useModalDialogTerms = ({ onAccept }: UseModalDialogTermsProps) => {
  const [showTermsDialog, setShowTermsDialog] = useState(false);

  const openTermsDialog = useCallback(() => {
    setShowTermsDialog(true);
  }, []);

  const closeTermsDialog = useCallback(() => {
    setShowTermsDialog(false);
  }, []);

  const onAcceptTerms = useCallback(() => {
    onAccept();
    setShowTermsDialog(false);
  }, [onAccept]);

  return {
    showTermsDialog,
    openTermsDialog,
    closeTermsDialog,
    onAcceptTerms,
  };
};
