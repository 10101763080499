/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

export type PointProductType = (typeof PointProductType)[keyof typeof PointProductType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PointProductType = {
  LIGHT: "LIGHT",
  REGULAR: "REGULAR",
} as const;
