"use client";
import { ComponentType, ReactNode, useLayoutEffect, useState } from "react";

export const withCsr = <P extends object>(
  Component: ComponentType<P>,
  fallback: ReactNode = null
) => {
  const WrappedComponent = (props: P) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [mountedInBrowser, setMountedInBrowser] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      setMountedInBrowser(true);
    }, []);
    return mountedInBrowser ? <Component {...props} /> : fallback;
  };
  WrappedComponent.displayName = Component.displayName;
  return WrappedComponent;
};
