import clsx from "clsx";

import styles from "./LineLoginButton.module.scss";

const devUrl =
  "https://line-api-dev.conel.jp/v2/pub/line-redirects/577303c8-fb21-4509-832e-4baa2f967393/redirect?r=24";
const prodUrl =
  "https://line-api.conel.jp/v2/pub/line-redirects/e258f7df-a4dd-4c37-936e-a331685b86b4/redirect?r=11";

const LineRedirectUrl = process.env.NODE_ENV === "production" ? prodUrl : devUrl;

export function LineLinkButton(): React.ReactNode {
  return (
    <a
      href={LineRedirectUrl}
      target="_blank"
      className={clsx("text__white btn inline round mg__bottom__s", styles.lineButton)}
      rel="noreferrer noopener"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="24"
        viewBox="0 0 27 24"
        fill="none"
        className={styles.lineIcon}
      >
        <g clipPath="url(#clip0_1141_2443)">
          <path
            d="M26.0944 10.2205C26.0944 4.58492 20.4447 0 13.5 0C6.55534 0 0.90564 4.58492 0.90564 10.2205C0.90564 15.2728 5.38668 19.5036 11.4389 20.304C11.8485 20.3925 12.4069 20.5743 12.5485 20.9249C12.676 21.2436 12.6312 21.7418 12.5899 22.064C12.5899 22.064 12.4423 22.9529 12.4104 23.1418C12.3549 23.4605 12.1578 24.3872 13.5012 23.8206C14.8457 23.2539 20.7539 19.5497 23.3958 16.5088C25.2208 14.5067 26.0955 12.4763 26.0955 10.2205H26.0944Z"
            fill="white"
          />
          <path
            d="M21.9037 13.4749H18.3659C18.2325 13.4749 18.1251 13.3675 18.1251 13.2341V13.2306V7.74379V7.73789C18.1251 7.60449 18.2325 7.49707 18.3659 7.49707H21.9037C22.0359 7.49707 22.1445 7.60567 22.1445 7.73789V8.6315C22.1445 8.76489 22.0371 8.87231 21.9037 8.87231H19.4991V9.80015H21.9037C22.0359 9.80015 22.1445 9.90876 22.1445 10.041V10.9346C22.1445 11.068 22.0371 11.1754 21.9037 11.1754H19.4991V12.1032H21.9037C22.0359 12.1032 22.1445 12.2118 22.1445 12.3441V13.2377C22.1445 13.3711 22.0371 13.4785 21.9037 13.4785V13.4749Z"
            fill="#06C755"
          />
          <path
            d="M8.81591 13.475C8.94813 13.475 9.05673 13.3676 9.05673 13.2342V12.3406C9.05673 12.2084 8.94813 12.0998 8.81591 12.0998H6.41131V7.73679C6.41131 7.60457 6.30271 7.49597 6.1705 7.49597H5.27689C5.14349 7.49597 5.03607 7.60339 5.03607 7.73679V13.2295V13.2342C5.03607 13.3676 5.14349 13.475 5.27689 13.475H8.81473H8.81591Z"
            fill="#06C755"
          />
          <path
            d="M10.9455 7.49707H10.0531C9.92007 7.49707 9.81226 7.60489 9.81226 7.73789V13.2341C9.81226 13.3671 9.92007 13.4749 10.0531 13.4749H10.9455C11.0785 13.4749 11.1863 13.3671 11.1863 13.2341V7.73789C11.1863 7.60489 11.0785 7.49707 10.9455 7.49707Z"
            fill="#06C755"
          />
          <path
            d="M17.026 7.49707H16.1324C15.999 7.49707 15.8916 7.60449 15.8916 7.73789V11.0019L13.3772 7.60567C13.3713 7.59741 13.3642 7.58915 13.3583 7.58088C13.3583 7.58088 13.3583 7.58088 13.3571 7.5797C13.3524 7.57498 13.3477 7.56908 13.343 7.56436C13.3418 7.56318 13.3394 7.562 13.3383 7.56082C13.3335 7.55727 13.33 7.55373 13.3253 7.55019C13.3229 7.54901 13.3206 7.54665 13.3182 7.54547C13.3147 7.54193 13.3099 7.53957 13.3052 7.53721C13.3028 7.53603 13.3005 7.53367 13.2981 7.53248C13.2934 7.53012 13.2899 7.52776 13.2851 7.5254C13.2828 7.52422 13.2804 7.52304 13.2781 7.52186C13.2733 7.5195 13.2686 7.51714 13.2639 7.51596C13.2615 7.51596 13.2592 7.5136 13.2556 7.5136C13.2509 7.51242 13.2462 7.51006 13.2415 7.50888C13.2391 7.50888 13.2356 7.50769 13.2332 7.50651C13.2285 7.50651 13.2238 7.50415 13.219 7.50297C13.2155 7.50297 13.212 7.50297 13.2084 7.50179C13.2037 7.50179 13.2001 7.50061 13.1954 7.50061C13.1907 7.50061 13.1872 7.50061 13.1824 7.50061C13.1801 7.50061 13.1765 7.50061 13.1742 7.50061H12.2865C12.1543 7.50061 12.0457 7.60803 12.0457 7.74143V13.2377C12.0457 13.3699 12.1531 13.4785 12.2865 13.4785H13.1801C13.3135 13.4785 13.4209 13.3711 13.4209 13.2377V9.97368L15.9388 13.3746C15.9565 13.3994 15.9778 13.4195 16.0014 13.4348C16.0014 13.4348 16.0037 13.436 16.0037 13.4372C16.0085 13.4407 16.0132 13.4431 16.0191 13.4466C16.0215 13.4478 16.0238 13.449 16.0262 13.4501C16.0297 13.4525 16.0344 13.4537 16.038 13.456C16.0415 13.4584 16.0462 13.4596 16.0498 13.4608C16.0521 13.4608 16.0545 13.4631 16.0569 13.4631C16.0628 13.4655 16.0675 13.4667 16.0734 13.4679C16.0734 13.4679 16.0758 13.4679 16.0769 13.4679C16.097 13.4726 16.1183 13.4761 16.1395 13.4761H17.0272C17.1594 13.4761 17.268 13.3687 17.268 13.2353V7.73907C17.268 7.60685 17.1606 7.49825 17.0272 7.49825L17.026 7.49707Z"
            fill="#06C755"
          />
        </g>
        <defs>
          <clipPath id="clip0_1141_2443">
            <rect width="25.1887" height="24" fill="white" transform="translate(0.90564)" />
          </clipPath>
        </defs>
      </svg>
      LINEでマイページにログイン
    </a>
  );
}
