import { List } from "@/components/displays";

export function ContactlessDelivery(): React.ReactNode {
  return (
    <div>
      <dl className="mg__bottom__m">
        <dt className="text__m text__bold">第1条（置き配）</dt>
        <dd className="text__m text__justify mg__top__s">
          置き配とは、当社が指定する配送会社の配送員が対面で商品を直接お客様に手渡しするのではなく、お客様があらかじめ指定された場所に非対面で商品をお届けする配送方法です。置き場所とは、置き配のためにお客様があらかじめ指定された場所をいいます。置き配を指定される場合には、以下の内容をご確認いただき、ご同意いただいたうえで、置き配を指定してください。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第2条（責任範囲）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 置き配を指定された場合には、お客様の在宅状況にかかわらず、置き場所に商品を置いた時点で配送が完了となり、当社はお客様に対する商品のお届け義務を果たしたものとします。置き場所への配送が完了した後の、商品の紛失、盗難、汚損、破損、商品貼付の送り状又は荷札に記載された情報の漏えい、商品貼付の送り状又は荷札の紛失等の置き場所に置き配したことにより生じた紛争や事故等による損害について、当社は一切の責任を負いません。",
              "2. 置き場所は、品質劣化や汚損を防ぐため、可能な限り直射日光や風雨の影響を受けない場所を選択してください。また、盗難や情報の漏えい等のリスクを低減させるため、置き場所は人目につきにくい場所を選択することをお勧めします。配送完了後は可能な限りすみやかに商品をお引き取りください。",
              "3. 商品配送時の包装にその取扱方法を示すケアマークシールを貼り付ける等、配送に必要な情報を添付することがあるのでご了承ください。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第3条（敷地内への立ち入り）</dt>
        <dd className="text__m text__justify mg__top__s">
          置き配を指定いただくことで、配送員が置き場所に商品を配送するために、お客様の居宅の敷地内や車庫、またはマンション等の共有の敷地内に立ち入ること、また、置き場所に商品を置くことにご同意いただいたものとします。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第4条（集合住宅における規約等の確認）</dt>
        <dd className="text__m text__justify mg__top__s">
          お届け先がマンション等の集合住宅の場合、管理組合によって定められた規約等における置き配の運用方法等をあらかじめご確認いただき、当該規約等に従って置き場所を指定してください。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第5条（写真撮影について）</dt>
        <dd className="text__m text__justify mg__top__s">
          置き配を指定された場合、配送完了時の商品の状況および配達状況の管理のため、配送完了時に配送員が商品および置き場所の写真を撮影します（ただし、置き場所として「宅配ボックス」、「建物内受付・管理人預け」を選択された場合、写真は撮影されない場合があります。）。撮影された写真は配送会社で適切に保管されます。ギフト商品等、ご購入者様とお届け先のお客様が異なる場合に置き配を指定されるときは、ご購入者様にてお届け先のお客様より「置き配指定」および「写真撮影」の同意を得たうえで、ご購入者様の責任においてご利用ください。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第6条（置き場所に置き配できない場合）</dt>
        <dd className="text__m text__justify mg__top__s">
          置き配を指定された場合であっても、以下のような場合には置き配を行わず、対面での商品のお届けを行うこと、お客様が集合住宅等に居住する場合はその管理人の承諾を得てお客様への荷物の引渡しを委託すること、または、ご不在の場合には商品を持ち戻ることがあります。
          <List
            className="mg__top__m"
            contents={[
              "1. 置き場所を配送員が特定できない場合（置き場所を配送員が見つけられない場合など）",
              "2. お届け先住所が正しいかどうか判断できない場合",
              "3. 置き場所に商品を置けない場合（商品が大きく宅配ボックスに入れられない場合、宅配ボックスの空きがない場合など）",
              "4. 置き場所への立入りができない、または困難と配送員が判断する場合（オートロックや鍵がかかっているために立ち入れない場合など）",
              "5. お客様に入力いただいた住所や置き場所の情報に誤り、不備がある場合",
              "6. 建物内受付・管理人預けの場合に商品の受取りを拒まれた場合",
              "7. その他、配送員が置き配が不可能または困難と合理的に判断する場合",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第7条（お支払方法の制限等）</dt>
        <dd className="text__m text__justify mg__top__s">
          置き配を指定された場合には、お支払方法として「代金引換え（代引き）」は選択いただけません。なお、お支払い方法にかかわらず、1回のご購入金額が15万円（税込）を超える場合や常温以外の商品は、配送会社の都合で置き配を選択いただけない場合がありますので予めご了承ください。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第8条（配送会社による配送方法の違い）</dt>
        <dd className="text__m text__justify mg__top__s">
          置き配を指定された場合であっても、配送時にインターホンでの呼出しを行う場合があるなど、配送会社により配送方法が異なる場合があります。なお、配送会社はご指定いただけません。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第9条（規約の変更）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 当社は、民法548条の4に基づき、当社が必要と判断する場合、お客様の事前の承諾を得ることなく、本規約の内容を変更することができます。",
              "2. 当社が本規約の内容を変更し、その変更内容をお客様に通知し周知した場合、通知において指定された変更後の規約の施行日以降（ただし、通知において期日を指定しない場合には、当社が通知を発した当日を施行日とします。）に、置き配を利用された場合、変更後の本規約に同意したものとみなし、変更後の本規約が適用されます。",
              "3. 前2項の規定にかかわらず、法令上、お客様の同意やあらかじめの通知等が必要となるような内容の変更の場合は、当社の所定の方法でお客様の同意を得、またはあらかじめの通知等を行うものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第10条（個人情報の取扱いについて）</dt>
        <dd className="text__m text__justify mg__top__s">
          置き配に関する個人情報の取扱いにつきましては、別途、当社のオフィシャルサイトで公表しているプライバシーポリシー（リンク&nbsp;
          <a href="/privacy" target="_blank">
            https://shop.basefood.co.jp/privacy
          </a>
          &nbsp;）によるものとします。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dd className="text__m">
          附則
          <br />
          2022年12月15日制定
          <br />
          2023年9月1日改定
        </dd>
      </dl>
    </div>
  );
}
