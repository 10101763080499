import type { HTMLProps } from "react";

import clsx from "clsx";

import { formatCurrency } from "@/utils/currency";

import styles from "./MoneySpan.module.scss";

export interface MoneySpanProps extends Omit<HTMLProps<HTMLSpanElement>, "prefix"> {
  children: number | string | undefined;
  priceClassName?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  prefixClassName?: string;
  suffixClassName?: string;
}

export function MoneySpan({
  children: price,
  prefix,
  suffix,
  className,
  priceClassName,
  prefixClassName,
  suffixClassName,
  ...restProps
}: MoneySpanProps): React.ReactNode {
  return (
    <span className={clsx(styles.root, className)} {...restProps}>
      {prefix && <span className={prefixClassName}>{prefix}</span>}
      <span className={priceClassName}>{price != null ? formatCurrency(price) : "-"}</span>
      {suffix && <span className={suffixClassName}>{suffix}</span>}
    </span>
  );
}
