"use client";
import { forwardRef, useId } from "react";

import clsx from "clsx";

import { Row } from "@/components/containers";

import styles from "./Checkbox.module.scss";

type BaseProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface CheckboxProps extends Omit<BaseProps, "size"> {
  children: React.ReactNode;
  className?: string;
  size?: "s" | "m" | "l";
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { children, className, size = "s", id, ...rest },
  ref
): React.ReactNode {
  const generatedId = useId();
  const inputId = id ?? generatedId;

  return (
    <Row className={clsx(styles.wrapper, className)}>
      <input id={inputId} type="checkbox" ref={ref} className={styles.checkboxInput} {...rest} />
      <label className={clsx(styles.label, styles[`font-${size}`])} htmlFor={inputId}>
        {children}
      </label>
    </Row>
  );
});
