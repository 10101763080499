import form12 from "$/images/lp/set12_form.png";
import form28 from "$/images/lp/set28_form.png";
import form46 from "$/images/lp/set46_form.png";

import { type RecommendSet } from "./types";

export const LP_FORM_01: RecommendSet[] = [
  {
    name: "定番のパンをおためし 14袋セット",
    products: [
      { sku: "102008", quantity: 4 },
      { sku: "102009", quantity: 2 },
      { sku: "102016", quantity: 2 },
      { sku: "102017", quantity: 2 },
      { sku: "102018", quantity: 2 },
      { sku: "102013", quantity: 2 },
    ],
    is_best_value: false,
    subscription: true,
    images: [
      {
        src: form12.src,
        aspectRatio: "164/135",
      },
    ],
    sub_title: "定番のパンをおためし",
  },
  {
    name: "からだと向き合いたいなら 28袋セット",
    products: [
      { sku: "102008", quantity: 6 },
      { sku: "102009", quantity: 4 },
      { sku: "102016", quantity: 4 },
      { sku: "102017", quantity: 4 },
      { sku: "102018", quantity: 4 },
      { sku: "102013", quantity: 6 },
    ],
    is_best_value: false,
    subscription: true,
    images: [
      {
        src: form28.src,
        aspectRatio: "164/135",
      },
    ],
    sub_title: "からだと向き合いたいなら",
  },
  {
    name: "家族の健康習慣のために 46袋セット",
    products: [
      { sku: "102008", quantity: 6 },
      { sku: "102009", quantity: 6 },
      { sku: "102016", quantity: 4 },
      { sku: "102017", quantity: 4 },
      { sku: "102018", quantity: 4 },
      { sku: "102013", quantity: 6 },
      { sku: "102010", quantity: 6 },
      { sku: "102014", quantity: 6 },
      { sku: "102012", quantity: 4 },
    ],
    is_best_value: false,
    subscription: true,
    images: [
      {
        src: form46.src,
        aspectRatio: "559/701",
      },
    ],
    sub_title: "家族の健康習慣のために",
  },
];
