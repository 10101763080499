import React from "react";

import { AnchorId } from "./types";

export type ScrollAnchorProps<T extends React.ElementType> = React.ComponentPropsWithoutRef<T> & {
  id: AnchorId;
  as?: T;
};

export function ScrollAnchor<T extends React.ElementType = "div">(
  props: ScrollAnchorProps<T>
): React.ReactNode {
  const { as: Component = "div", ...restProps } = props;
  return <Component {...restProps} />;
}
