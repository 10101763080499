import { useCallback, useMemo } from "react";

import {
  QueryObserverOptions,
  QueryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { isAxiosError } from "axios";

import { htmlToast } from "@/components";
import { postCart } from "@/generated/axios-functions/payseAPI";
import { Cart, PostCartBody } from "@/generated/open-api/schemas";
import { convertApiCartToCart } from "@/models/cart/converters";
import { CartModel } from "@/models/cart/type";
import { SnakeToCamelCaseNested } from "@/utils";
import { convertObjToSnakeCase } from "@/utils/converters";
import { getErrorMessages } from "@/utils/error";
import { objectKeys } from "@/utils/object";

type UsePostParsedCartParams = SnakeToCamelCaseNested<PostCartBody>;

type UsePostParsedCartOptions = Omit<QueryOptions, "queryKey" | "queryFn"> &
  Omit<QueryObserverOptions, "queryKey" | "queryFn">;

/**
 * HTTPメソッドがPOSTでSuspenseのhooksが生成されないため自作する
 */
export function usePostParsedCart(
  params: UsePostParsedCartParams,
  options?: UsePostParsedCartOptions
) {
  const queryKey = useMemo(() => getParsedCartKey(params), [params]);
  const queryFn = useCallback(() => postParsedCart(params), [params]);
  const { data, ...rest } = useQuery({ queryKey, queryFn, ...options });
  const cart = useMemo(() => data, [data]);
  return { data: cart as CartModel | undefined, ...rest };
}

export function usePostParsedCartSuspense(
  params: UsePostParsedCartParams,
  options?: UsePostParsedCartOptions
) {
  const queryKey = useMemo(() => getParsedCartKey(params), [params]);
  const queryFn = useCallback(() => postParsedCart(params), [params]);
  const { data, ...rest } = useSuspenseQuery({ queryKey, queryFn, ...options });
  const cart = useMemo(() => data, [data]);
  return { data: cart as CartModel | undefined, ...rest };
}

function getParsedCartKey(params: UsePostParsedCartParams) {
  const sortedKeys = objectKeys(params).sort();
  // ソートされたキーを使用して新しいURLSearchParamsを生成
  const sortedParams = new URLSearchParams();
  sortedKeys.forEach((key) => {
    sortedParams.append(key, JSON.stringify(params[key]));
  });
  return [`/cart`, sortedParams.toString()];
}

async function postParsedCart(params: UsePostParsedCartParams) {
  let cart: Cart | undefined;
  try {
    cart = (await postCart(convertObjToSnakeCase(params))).cart;
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 404) {
      // 404が返った場合はリクエストパラメータからクーポンを除去して、もう一回POSTリクエストを投げる(※Nuxtの仕様をそのまま踏襲)
      const { coupon, ...paramsWithoutCoupon } = params;
      cart = (await postCart(convertObjToSnakeCase(paramsWithoutCoupon))).cart;
    }

    htmlToast.error(getErrorMessages(err));
  }

  return cart ? convertApiCartToCart(cart) : undefined;
}
