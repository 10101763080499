import {
  GetPoints200 as ApiPointResponse,
  GetPoints200ExpiryPointsItem as ApiPoint,
} from "@/generated/open-api/schemas";
import { PointResponseModel } from "@/models/point/type";

export const convertApiPointResponseToPoints = (data: ApiPointResponse): PointResponseModel => {
  return {
    total: Number(data.total),
    expiryDate: data.expiry_date,
    expiryPoints:
      data.expiry_points?.map((value: ApiPoint) => ({
        expiryDate: value.expiry_date,
        expiryPoint: value.expiry_point ? value.expiry_point : 0,
      })) || [],
  };
};
