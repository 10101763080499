import React from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./SafetyBanner.module.scss";

interface SafetyBannerProps {
  lpType: "diet" | "senior" | "form" | "form01" | "form02" | "form03pc" | "invite" | "form03sp";
}

export function SafetyBanner({ lpType = "diet" }: SafetyBannerProps): React.ReactNode {
  const containerClasses = clsx(
    "pd__top__l",
    { pd__bottom__l: ["diet", "senior", "form02"].includes(lpType) },
    { bg__yellow: ["form", "form03pc", "invite"].includes(lpType) },
    { clear__sp: lpType === "form03pc" },
    { "bg__red clear__pc": lpType === "form03sp" }
  );

  const columnClasses = clsx("col-12", {
    "col-m-6 col-offset-m-3": ["form", "form01", "form03pc", "form03sp", "invite"].includes(lpType),
  });

  const imageClasses = clsx({
    [styles.bnrBorder]: !["form", "form03pc", "form03sp", "invite"].includes(lpType),
  });

  return (
    <section className={containerClasses}>
      <div className="container">
        <div className="row">
          <div className={columnClasses}>
            <a href="https://basefood.co.jp/safety" target="_blank" rel="noopener noreferrer">
              <Image
                src="https://asset.basefood.co.jp/images/bnr/bnr_safety_W960_H300_2.jpg"
                alt="安心・安全の取り組み"
                aspectRatio="16 / 5"
                containerClassName={clsx(styles.image, imageClasses)}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
