"use client";

import clsx from "clsx";
import { usePathname } from "next/navigation";

import { FEATURE_FLAG } from "@/configs/system";

import styles from "./FreeShippingCampaignBar.module.scss";

// 送料無料キャンペーンバーを表示するpathを取得
const isFreeShippingCampaignBarPath = (pathname: string): boolean => {
  // カートページのみ送料無料キャンペーンバーを表示
  if (pathname.startsWith("/cart")) {
    return true;
  }

  // 冷凍食品と冷凍セット以外の商品ページのみ送料無料キャンペーンバーを表示
  const excludeProductsPaths = [
    "/subscription/4",
    "/deli/basepasta/bolognese",
    "/deli/basepasta/tarako",
    "/deli/basepasta/creamymushroom",
  ];
  if (
    pathname.startsWith("/products") &&
    !excludeProductsPaths.find((path) => pathname.endsWith(path))
  ) {
    return true;
  }

  return false;
};

export function FreeShippingCampaignBar(): React.ReactNode {
  const pathname = usePathname();
  if (!FEATURE_FLAG.isFreeShippingCampaignActive || !isFreeShippingCampaignBarPath(pathname)) {
    return null;
  }

  return (
    <p
      className={clsx(
        "text__s text__bold text__center pd__top__s pd__bottom__s",
        styles.freeShippingCampaignBg
      )}
    >
      継続コース常温便が今なら<span className="text__red__dark">初回送料無料</span>
    </p>
  );
}
