import { LetroSection } from "../../product/ProductBase/FeatureSections/LetroSection";

interface ProductSnsPostsSectionProps {
  letroId: string;
}

export function ProductSnsPostsSection({ letroId }: ProductSnsPostsSectionProps): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1">
          <LetroSection letroId={letroId} />
        </div>
      </div>
    </div>
  );
}
