import { Row, RowProps } from "@/components/containers";

import styles from "./SpotlightFrame.module.scss";

interface SpotlightFrameProps extends RowProps {
  children: React.ReactNode;
  color?: string;
  height?: number | string;
}

export function SpotlightFrame({
  children,
  color = "white",
  height = 30,
  ...rest
}: SpotlightFrameProps): React.ReactNode {
  return (
    <Row align="center" {...rest}>
      <div className={styles.spotlightLeft} style={{ backgroundColor: color, height }} />
      {children}
      <div className={styles.spotlightRight} style={{ backgroundColor: color, height }} />
    </Row>
  );
}
