import { useMemo } from "react";

import { useClientFrontStoreCartItems } from "@/storage";

import { usePostParsedCart } from "./usePostParsedCart";

/**
 * @remarks
 * Client Side renderingでのみ対応
 */
export function usePostParsedCartFromCartItems(options?: Parameters<typeof usePostParsedCart>[1]) {
  const { cartItems } = useClientFrontStoreCartItems();
  const params = useMemo(
    () => ({
      products: cartItems.map((item) => ({
        variantId: item.variantId.toString(),
        quantity: item.quantity.toString(),
        subscription: item.subscription,
      })),
    }),
    [cartItems]
  );
  return usePostParsedCart(params, options);
}
