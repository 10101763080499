import * as jsonwebtoken from "jsonwebtoken";

/**
 * この関数は、与えられたJWTを検証します。
 *
 * @param {string} [token] - 検証するJWTトークン。
 * @returns {boolean} JWTが有効な場合はtrue、無効な場合はErrorをスローします。
 * @throws {Error} JWTが有効でない場合に、Errorをスローします。
 *
 * @remarks
 * この関数はJWTが有効であるかどうかを確認します。
 * 具体的には以下の内容を検証します。
 * - トークンが提供されているか
 * - デコードに成功し、フォーマットが正しいか
 * - トークンが有効期限内であるか
 * - 発行者が正しいか
 * jsonwebtoken.verifyは、シークレットキーの運用方法が決まっていないため使用していません。
 */
export function verify(token?: string): boolean {
  if (!token) {
    throw new Error("JWT is not provided");
  }
  const decoded = jsonwebtoken.decode(token);
  if (!decoded || typeof decoded === "string") {
    throw new Error("Failed to decode JWT");
  }
  const { exp, iss } = decoded;
  if (typeof exp !== "number" || typeof iss !== "string") {
    throw new Error("JWT is invalid format");
  }

  if (exp < Date.now() / 1000) {
    throw new Error("JWT is expired");
  }

  const apiHostName = new URL(process.env.NEXT_PUBLIC_APP_URL!).hostname;
  const issHostName = new URL(iss).hostname;
  if (apiHostName !== issHostName) {
    throw new Error("JWT issuer is invalid");
  }
  return true;
}

/**
 * この関数は、与えられたJWTを検証し、検証に失敗した場合でもエラーをスローせずにfalseを返します。
 *
 * @param {string} [token] - 検証するJWTトークン。
 * @returns {boolean} JWTが有効な場合はtrue、無効な場合はfalseを返します。
 */
export function tryVerify(token?: string): boolean {
  try {
    return verify(token);
  } catch (_e) {
    return false;
  }
}
