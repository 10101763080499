/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

export type FulfillmentStatus = (typeof FulfillmentStatus)[keyof typeof FulfillmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FulfillmentStatus = {
  none: "none",
  fulfilled: "fulfilled",
  partly_fulfilled: "partly_fulfilled",
  cancelled: "cancelled",
  ship: "ship",
  null: "null",
} as const;
