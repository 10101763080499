import { Image } from "@/components";

import styles from "./LineupSectionItemSp.module.scss";

export interface LineupSectionItemSpProps {
  category: string;
  title: string;
  imageUrl: string;
  description: string;
}

export function LineupSectionItemSp({
  category,
  title,
  imageUrl,
  description,
}: LineupSectionItemSpProps): React.ReactNode {
  return (
    <dl className={styles.flavorLineup}>
      <dt className={styles.flavorLineupImage}>
        <Image src={imageUrl} alt={title} />
      </dt>
      <dd className={styles.flavorLineupContent}>
        <p className="text__s text__bold text__white">
          BASE {category}
          <sup className="sup">
            <i className="far fa-registered fa-fw"></i>
          </sup>
        </p>
        <p className="text__m text__bold mg__bottom__s">{title}</p>
        <p className="text__m">{description}</p>
      </dd>
    </dl>
  );
}
