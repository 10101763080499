import home from "$/images/egift/home.svg";
import mail from "$/images/egift/mail.svg";
import message from "$/images/egift/message.svg";
import suprise from "$/images/egift/suprise.svg";

export const egiftPoints = {
  point1: {
    id: 1,
    title: "住所を知らなくて\nも贈れる",
    icon: home.src,
  },
  point2: {
    id: 2,
    title: "サプライズで\n贈れる",
    icon: suprise.src,
  },
  point3: {
    id: 3,
    title: "LINE等で\n今すぐ贈れる",
    icon: message.src,
  },
  point4: {
    id: 4,
    title: "気持ちも一緒に\n伝えられる",
    icon: mail.src,
  },
};

export const egiftPointItems = [
  egiftPoints.point1,
  egiftPoints.point2,
  egiftPoints.point3,
  egiftPoints.point4,
];
