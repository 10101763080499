import { type HTMLProps } from "react";

import clsx from "clsx";

import styles from "./Hr.module.scss";

export interface HrProps extends HTMLProps<HTMLHRElement> {
  noMargin?: boolean;
  dashed?: boolean;
}

export function Hr({ className, noMargin, dashed, ...restProps }: HrProps): React.ReactNode {
  return (
    <hr
      {...restProps}
      className={clsx(styles.hr, noMargin && styles.noMargin, dashed && styles.dashed, className)}
    />
  );
}
