import { List } from "@/components/displays";

export function Purchase(): React.ReactNode {
  return (
    <div>
      <dl className="mg__bottom__m">
        <dd className="text__m text__justify mg__top__s">
          本利用規約は、利用者が本ウェブサイトをご利用になる場合及び当社で商品をご購入される場合に適用されます。事前に必ず全文をご確認いただき、これに同意の上、ご利用ください。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第1条（定義）</dt>
        <dd className="text__m text__justify mg__top__s">
          本利用規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
          <List
            className="mg__top__m"
            contents={[
              <>
                1. 「本ウェブサイト」とは、当社が運営するウェブサイト（ドメイン名&nbsp;
                <a href="https://basefood.co.jp/" target="_blank">
                  https://basefood.co.jp/
                </a>
                &nbsp;）を意味します。
              </>,
              "2. 「本サービス」とは、本ウェブサイトを通じて当社が提供する一切のサービスを意味します。なお、本ウェブサイトには、当社のモバイルサイトも含むものとします。なお、本サービスの名称等に変更があった場合でも適用されます。",
              "3. 「利用者」とは、本サービスを利用する利用者を意味します。",
              "4. 「商標」とは、当社の商標、ロゴマーク、商号を意味します。",
              "5. 「当社」とはベースフード株式会社を意味します。",
              "6. 「再登録者」とは、会員登録した利用者が、第5条に定める退会、第16条に定める会員の停止・除名等により、会員の身分を失った後、再び会員登録を経て会員になった者を意味します。なお、当社の合理的な判断により、再登録を認めない場合がありますので、ご了承ください。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第2条（本ウェブサイトの利用）</dt>
        <dd className="text__m text__justify mg__top__s">
          本ウェブサイトの利用には、インターネットのアクセスに必要な環境が必要となります。なお、本ウェブサイトは以下のブラウザの最新バージョンに対応しております。
          <List
            className="mg__top__m"
            contents={[
              "• Microsoft Edge",
              "• Google Chrome(Desktop及びmobile版)",
              "• Safari",
              "• Mobile Safari",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第3条（利用者資格）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 利用者は、本ウェブサイトにおいて、本利用規約に基づき本サービスを利用することができます。",
              <>
                2.
                当社は、利用者が以下の各号の一に該当することが判明した場合、当該利用者の本ウェブサイト利用停止、及び利用者の申込み等に対する商品等の給付停止等をする場合があります。
                <List
                  contents={[
                    "① 利用者が実在しない場合",
                    "② 入力事項の全部又は一部につき虚偽、誤記又は記載漏れがあった場合",
                    "③ 以前に当社への支払を怠ったことがある場合",
                    "④ 他の利用者の本ウェブサイト利用を妨害する迷惑行為を行った場合",
                    "⑤ 本利用規約及びその他の当社が定める規約に違反した場合",
                    "⑥ その他当社が合理的な根拠に基づき不適当と合理的に判断した場合",
                  ]}
                />
              </>,
              "3. 当社は、利用者が前項の定めによりサービスを利用できなくなり、利用者や第三者に損害が生じても当該損害を賠償する責任を負いません。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第4条（会員登録・登録内容の変更）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 利用者は本ウェブサイトにて、本ウェブサイトからの商品の購入又はメールマガジンの配信などを目的として、氏名、住所、電話番号、メールアドレス等の当社が指定する情報を登録することで会員登録を行うことができます。",
              "2. 会員登録を完了した者は、以降の商品購入において、登録したメールアドレス等を入力することにより、商品を購入することができます。",
              <>
                3.
                当社は、会員登録の申請者が、以下の各号のいずれかの事由に該当する場合には、登録を拒否することがあります。
                <List
                  contents={[
                    "① 登録事項の全部又は一部につき虚偽、誤記又は記載漏れがあった場合",
                    "② 未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合",
                    "③ 反社会的勢力（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合",
                    "④ 会員登録の希望者が過去当社との契約に違反した者又はその関係者であると当社が判断した場合",
                    "⑤ その他当社が登録を合理的な根拠に基づき不適当と合理的に判断した場合",
                  ]}
                />
              </>,
              "4. 会員は、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第5条（退会）</dt>
        <dd className="text__m text__justify mg__top__s">
          会員が退会を希望する場合には、当社の定める方法により、会員自ら退会の届出を当社に対して行うものとします。なお、再登録者は、新規会員向けの各種特典の対象外になります。
        </dd>
      </dl>

      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第6条（パスワード及び利用者IDの管理）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 会員は、自己の責任において、本サービスに関するパスワード及び利用者IDを適切に管理及び保管するものとします。",
              "2. 会員は、パスワード及び利用者IDを第三者に使用させることはできず、第三者と共有あるいは譲渡、売買、質入等をしたりすることはできないものとします。",
              "3. 会員が本サービスを利用するにあたって入力したパスワード及び利用者IDが登録されたものと一致することを当社所定の方法により確認した場合、会員による利用があったものとみなします。なお、これらが盗用、不正使用その他の事情により会員以外の者が利用している場合であっても、これにより生じた損害については当社は一切責任を負いません。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第7条（配送）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 本サービスにおける商品の配送先は、日本国内かつ当社が定める配送可能地域に限るものとします。",
              "2. 当社は、定められた配送日に商品を配送いたします。商品の配送にあたっては、配送会社の責任において利用者に配送を行いますので、配送にかかる事項は配送会社が定める約款に従い、配送会社が対応します。",
              "3. 利用者は、配送会社に起因するトラブル（配送遅延・解凍事故・その他配送トラブルを含む）があった場合は、すみやかに配送会社へ申し出るものとします。",
              "4. 利用者は、本サービスにおける商品の納期や内容については、必ずしも保証されるものではなく、注文のタイミング、本ウェブサイトの更新、在庫状況、出荷元又は運送会社の都合その他の理由により、実際の状況と異なることを了承するものとします。",
              "5. 利用者の記載した配送先住所の誤り、利用者の不在、利用者が受け取らない等の事由により、当社が定める期限を超えて利用者が商品を受け取らなかった場合には、利用者は、当該商品を当社に対して無償譲渡したものとみなし、当社は、商品の破棄その他必要な措置を行うことができます。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第8条（配送の不能）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            className="mg__top__m"
            contents={[
              "1. 予期できない天候の変化、天災地変、不慮の事故、提携事業者の事故、商品の在庫切れその他の事由により、当社又は当社の委託する業者による商品の配送が不可能になった場合、当社は、その裁量により、代替品の配送を行うことができるものとします。",
              "2. 利用者は、当社による商品の配送が不可能となった場合、当社の合理的な根拠に基づく合理的な判断により、前項の対応を採らず、当該商品につき、売買契約の取消し等の措置を採る場合があることを、あらかじめ了承するものとします。",
              "3. 当社が合理的な根拠に基づき合理的に判断して、前2項に基づく措置を行った又は行わなかったことによって利用者に生じた損害に関して、当社は一切責任を負わないものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第9条（商品の返品・交換）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 利用者は配送された商品について、商品に品質又は数量に関して契約の内容に適合しない(「契約不適合」といいます)場合を除き、原則として返品・交換等を請求することはできません。",
              "2. 利用者は、配送された商品に契約不適合があった場合には、当社が定める方法により、商品が到着した日又はその翌日中に当社に申し出るものとします。",
              "3. 当社は、前項に基づき申し出を受けた商品に契約不適合があったと認めた場合には、当社が定める方法により、商品の交換、代替商品の配送を行うものとします。また、この場合の交換・代替商品の配送のための送料は当社が負担します。",
              "4. 利用者の都合による配送会社での長期保管（宅配ボックスを含みます。）に起因する商品の品質の劣化及び利用者の都合による冷凍商品の解凍に起因する商品の品質の劣化については、本条第2項の商品の契約不適合にはあたらないものとし、当社は一切の責任を負わないものとします。",
              "5. 当社の責めに帰すべき事由がある場合を除き、長期不在及び受け取り拒否により商品が返送された場合でも、原則として商品代金、送料往復分及びその他実費については申し受けます。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第10条（出荷停止措置及び所有権放棄について）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 当社は、利用者が代金引換をもって商品の支払を行う旨を選択していた場合であって、利用者が長期不在又は届先住所が不明等の理由により商品の受取りができず、2回以上商品が倉庫へ返送になったときは、出荷の一時停止の措置をとることとし、利用者はあらかじめこれに同意するものとします。",
              <>
                2.
                当社は、以下の事由に該当する場合、利用者に対して出荷の一時停止の措置に関する通知を行い、当社が当該通知上で定める一定の期間内に利用者から異議がない場合又は継続コースにおいて次回の出荷分についても商品が倉庫へ返送になった場合には出荷の一時停止の措置をとることとし、利用者はあらかじめこれに同意するものとします。
                <List
                  className="mg__top__m"
                  contents={[
                    "① 利用者が長期不在又は届先住所が不明等の理由により商品の受取りができず、1回以上商品が倉庫へ返送になった場合",
                    "② 利用者が商品の受取りの拒否（配送センターの保管期間切れでの返送された場合も含みます。）をして、1回以上商品が倉庫へ返送になった場合",
                  ]}
                />
              </>,
              "3. 代金支払い済みの利用者については、前項の出荷の一時停止の措置に関する通知到着後3週間以内にご連絡を頂けない場合、商品の所有権を放棄したものとみなし、この場合、当社は利用者に対して代金を返金することはできないことをあらかじめ承諾するものとします。",
              "4. 前3項の出荷の一時停止の措置及び前項の所有権放棄により利用者に生じた損害について、当社は一切の責任を負わないものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第11条（利用者の責任）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 利用者は、本サービスの利用に伴い、自己の責めに帰すべき事由で第三者に対して損害を与えた場合、又は第三者からクレーム等の請求がなされた場合、自己の責任と費用をもって処理、解決するものとします。利用者が本サービスの利用に伴い、第三者から損害を被った場合、又は第三者に対してクレーム等の請求を行う場合においても同様とします。",
              "2. 本サービスを利用して当社が提供する商品の賞味期限、消費期限及び解凍のタイミング等については、利用者が自らの責任において管理するものとし、賞味期限又は消費期限を経過した商品を利用する等、商品の管理に起因して利用者に発生した損害について、当社はいかなる責任も負わないものとします。",
              "3. 商品の管理に起因し、又は関連して第三者との間でトラブルが生じた場合、利用者が自らの責任と費用において解決するものとします。この場合において、当社が、これに対応するために費用（和解金、弁護士費用等が含まれますが、これらに限られません。）を支出したときは、利用者は、当該費用の全額を、当社に対して補償するものとします。",
              "4. 利用者は、利用者が本契約に違反し、又はその故意若しくは過失により当社に損害を与えた場合、当社に対して、当該損害の賠償を行うものとします。",
              "5. 商品の管理に関連した不具合が生じた場合、当社は、自らの合理的根拠に基づく合理的判断において、当社より提供及び貸与する機器及び備品、並びに商品を利用者から回収することができるものとし、利用者は、かかる回収を可能とするために必要なあらゆる手段（自社内への立ち入りの許可や、備品の運び出し等にかかる社内手続等が含まれますが、これらに限られません。）を講じるものとします。この場合において、利用者がかかる手段を講じなかったとき、当社はこれにより利用者に生じた損害その他の不利益の一切を補償すべき義務を負いません。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第12条（情報提供）</dt>
        <dd className="text__m text__justify mg__top__s">
          利用者は、当社からメール、FAX、ダイレクトメール等の手段を用いて商品情報等の情報提供が行なわれることをあらかじめ承諾するものとします。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第13条（秘密保持）</dt>
        <dd className="text__m text__justify mg__top__s">
          利用者は、本サービスの利用に関連して当社が利用者に対して秘密に取り扱うことを求めて開示した情報について、当社の事前の書面による承諾がある場合を除き、第三者に開示又は漏洩してはならないものとします。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第14条（個人情報の取扱い）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              <>
                1.
                当社は、本ウェブサイトの利用サービスの一部において、必要な個人情報を入力頂く場合があります。当該個人情報を含む利用者情報の取扱いについては、別途、当社のオフィシャルサイトで公表しているプライバシーポリシー（リンク&nbsp;
                <a href="/privacy" target="_blank">
                  https://shop.basefood.co.jp/privacy
                </a>
                &nbsp;）によるものとします。
              </>,
              "2. 当社は、利用者が当社に提供した情報やデータ等を、個人を特定できない形での統計的な情報として当社の裁量で利用及び公開することができるものとし、利用者はこれに異議を唱えないものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第15条（禁止事項）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              <>
                1.
                利用者及び会員は、本サービスの利用に際し、以下の各号の一に該当する行為又はそのおそれがある行為を行ってはならないものとします。
                <List
                  contents={[
                    "① 法令、条例等違反又は公序良俗に反する行為",
                    "② 当社、本サービスの他の利用者又はその他の第三者の著作権、商標権、意匠権、特許権などの知的財産権、肖像権、パブリシティ権その他の権利を侵害する行為、並びに本ウェブサイトの画像及び文章を無断で転載する行為",
                    "③ 当社その他の第三者を差別、脅迫、誹謗中傷し、又はその信用もしくは名誉を毀損する行為",
                    "④ 当社その他の第三者に不利益又は損害を与える行為",
                    "⑤ 第三者になりすまして本ウェブサイト等を利用する行為",
                    "⑥ コンピュータウイルスなど有害なプログラム等を送信もしくは提供する行為又は推奨する行為",
                    "⑦ 当社及びその他の第三者の本ウェブサイト等で利用し得る情報を改ざん、消去する行為",
                    "⑧ 当社及びその他の第三者の設備を不正に利用し又はその運営に支障を与える行為",
                    "⑨ 本ウェブサイトを不正の目的をもって利用する行為",
                    "⑩ 本ウェブサイト等及び当社の運営を妨げる行為",
                    "⑪ 犯罪行為、犯罪的行為を助長又はその実行を暗示する行為",
                    "⑫ 本利用規約その他当社の定める規約に反する行為",
                    "⑬ 反社会的勢力等への利益供与",
                    "⑭ 政治、プロモーション、宗教目的に本ウェブサイトを利用する行為",
                    "⑮ その他当社が合理的な根拠に基づき不適切と判断する行為",
                  ]}
                />
              </>,
              "2. 前項の他、利用者及び会員は、営利を目的として、当社あるいは当社が販売を委託しているサイト等にて商品を代理で購入し、第三者に転売する行為その他これに類似する行為は一切禁止いたします。同禁止事項に違反し、営利の目的で転売を行ったこと、転売を試みたこと、又は商品の大量購入や短期間での発注が継続するなど営利の目的の疑いがあることが判明した場合、当社の判断で同時点以降のご注文はお受けできないようにすることができるものとし、利用者及び会員はあらかじめこれに同意することとします。なお、当社あるいは当社が販売を委託しているサイト等以外から購入した商品に関して、品質保証・品質管理、その他転売に関して生じた紛争等について当社は一切の責任を負いません。",
              "3. 第1項各号及び前項に該当する行為によって、当社が何らかの損害を被った場合には、当社は利用者に対し、当社に生じた損害賠償を請求することができるものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第16条（会員の停止・除名）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              <>
                1.
                当社は、以下の各号の一に該当する事由がある場合、会員に何ら事前の通知又は催告をすることなく、かつ、会員の承諾を得ることなく、本ウェブサイト等利用の一時停止、当該会員のパスワード等変更、又は当該会員の会員資格の一時停止、又は除名をすることができるものとします。
                <List
                  contents={[
                    "① 会員登録情報（パスワードを含む）又は本ウェブサイト等を不正に使用し又は使用させた場合",
                    "② 当社への支払を怠った場合",
                    "③ 会員の登録事項の全部又は一部につき虚偽、誤記又は記載漏れがあることが判明した場合",
                    "④ 商品の購入にあたり、当社の与信審査の結果、当社が会員に対して商品の支払い方法を指定したときに、会員がこれを拒否した場合",
                    "⑤ 商品の購入にあたり、当社によるカード決済ができない場合",
                    "⑥ 会員がカードを不正に使用した場合",
                    "⑦ 商品の購入が転売を目的としたものである場合",
                    "⑧ 会員が注文に関する問い合わせに回答しない場合",
                    "⑨ 会員に対し、差押、仮差押、仮処分、強制執行、破産、民事再生の申し立てがなされた場合、又は会員が申し立てをした場合",
                    "⑩ 当社の定める回数以上のパスワードの入力ミスがある場合",
                    "⑪ 本ウェブサイト等によって提供された情報を不正に使用した場合",
                    "⑫ 当社の定める期間内に本ウェブサイト等の利用がなかった場合",
                    "⑬ 会員から通知された手段で連絡がとれない場合",
                    "⑭ 会員が当社その他の第三者に損害を与える危険があると当社が判断した場合",
                    "⑮ 会員に当社への営業妨害となる行為があった場合",
                    "⑯ 会員が前条に定める禁止行為を行った場合",
                    "⑰ 会員が本利用規約その他当社の定める規約のいずれかの条項に違反した場合",
                    "⑱ その他会員の本ウェブサイトの利用が不適格と当社が合理的根拠に基づき合理的に判断した場合",
                  ]}
                />
              </>,
              "2. 前項各号のいずれかの事由に該当した場合、利用者会員は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務を支払わなければなりません。",
              "3. 当社は、会員に第１項各号に該当する事由が生じたため、第１項に定める措置をとった場合に、当該会員に損害が発生したとしても、一切の責任を負わず、会員への何らの補償も行わないものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第17条（免責）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 当社は、商品のお届けについて、利用者に指示された送付先に指示された商品を配送することにより、その義務を果たしたものとします。",
              "2. 本サービスにおいて当社が利用者に対して通知義務を負う場合、利用者が登録している利用者の住所、電話、メールアドレス、又は会員マイページ上のお知らせ欄のいずれかへ通知を発信することにより、その義務を果たしたものとします。利用者が利用者の住所、電話番号、メールアドレスを当社の指定する方法に従って登録しなかったことにより、当該通知が利用者に到達しなかったことにより利用者に生じた損害について、当社の責任による場合を除き、一切責任を負わないものとします。",
              "3. 当社は、本ウェブサイトの内容及び利用者が本ウェブサイトを通じて得る情報等について、その正確性、完全性、有用性、最新性、適切性、確実性、動作性等、その内容について何ら保証をするものではありません。当該情報に起因して利用者及び第三者に損害が発生したとしても、当社は責任を負わないものとします。",
              "4. 本ウェブサイトがリンクしているウェブサイト又はページについては、情報提供のみを目的としており、当社はこれらのウェブサイトやページの内容を確認していません。当社は、本ウェブサイトへリンクする、又は本ウェブサイトからリンクしているウェブサイトやページの内容について何ら保証するものではなく、一切責任を負いません。従って、当社は、本ウェブサイトにリンクされたウェブサイト又はそのいずれかのロケーションへリンクした結果生じるいかなる損害、損失その他の不利益に対しても責任を負いません。",
              "5. 当社は、利用者に対し、本ウェブサイト等の品質又は性能については如何なる保証も行いません。また、当社は、利用者に対し、本ウェブサイト等の一時停止、中止、欠陥及びそれらに起因又は関連して発生した全ての損失や損害（入力したデータが何らかの原因で消失した場合を含む）については、責任を負いません。",
              "6. 当社は、利用者が本ウェブサイトを利用する際に、コンピュータウイルスなど有害なプログラム等による損害を受けないことを保証しないものとします。",
              "7. 当社は、利用者に対し、本ウェブサイト等の利用に起因又は関連して利用者が被った損害について、何ら責任を負わないものとします。",
              "8. 当社は、利用者による本サービスの利用に起因又は関連して利用者が他の利用者又は第三者に及ぼした損害、及び第三者による本サービスの閲覧に起因又は関連して第三者が利用者に及ぼした損害、並びに利用者と他の利用者又は第三者との間の紛争について、責任を負わないものとします。",
              "9. 本利用規約の他の定めにかかわらず、当社が本サービスに関連して当社の責めに帰すべき事由により利用者に損害を与えた場合における損害賠償義務の範囲は、当社が利用者から本サービスに関連して受領した料金の総額を上限とし、かつ本サービスの利用により利用者に直接的に生じた通常損害（付属的損害、特別損害、逸失利益、その他間接的に発生した損害については、それらの予見又は予見可能性の有無にかかわらず含まない）に限定されるものとします。ただし、当該損害の発生が当社の故意又は重過失に起因する場合をこの限りではありません。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第18条（本サービス等の中断・停止）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              <>
                1.
                当社は、利用者への以下の各号の一に該当する場合、事前の通知了解を得ることなく、本サービス等の一部又は全部を一時中断又は停止することがあります。
                <List
                  contents={[
                    "① 本サービスに係るコンピューター・システムの点検その他メンテナンス等を行う場合",
                    "② 地震、落雷、火災、風水害、停電、天災地変等の不可抗力により本サービスの運営ができなくなった場合",
                    "③ その他当社が本サービス等の一時中断又は停止が必要であると合理的な根拠に基づき合理的に判断した場合",
                  ]}
                />
              </>,
              "2. 当社は、本サービス等の提供の一時中断又は停止により、利用者が被ったいかなる損害についても責任を負わないものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第19条（知的財産権）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 本ウェブサイト及びそれを構成する各要素（商標、画像、テキスト、動画等）は、当社の知的所有権によって保護されています。従って、本利用規約で明示的に規定されている場合を除いて、当社は、個人的かつ私的目的でのみ本ウェブサイトを閲覧する権利以外のいかなる権利も利用者に付与しません。メディアの如何を問わず、本ウェブサイトの全体又は一部、もしくは本ウェブサイトを構成する各要素を商業的目的等で使用又は複製することは禁止されています。",
              "2. 利用者が当該使用又は複製を希望する場合、事前に末尾に記載するお問い合わせ先にご連絡いただき当社の許可を得る必要があります。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第20条（個別規約ついて）</dt>
        <dd className="text__m text__justify mg__top__s">
          本サービスは複数の個別サービスで構成されます。当社は、一部の個別サービスの利用に際して、本利用規約のほか、個別サービスごとに別途利用規約（以下「個別規約等」といいます。）を掲載する場合があります。その場合、個別規約等は本利用規約の一部として適用されます。なお、本利用規約と個別規約等が抵触する場合は、別段の定めがない限り本利用規約が優先するものとします。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第21条（本ウェブサイト上の情報について）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              `1. 一般的条項
              当社は、正確で最新な情報を提供することに努めていますが、技術的信頼性のないインターネット上でデータや情報が送信される場合などにおいて、本ウェブサイトに含まれる全てのデータの正確性を保証することはできません。情報の誤りや漏れにお気付きの場合は、末尾に記載するお問い合わせ先までご連絡ください。`,
              `2. 商品及びサービスに関する情報
              特段の定めがない限り、オンラインストアを除き、本ウェブサイトは当社の商品及びサービスの種類に関する情報を提供することを目的としており、販売を目的としたものではありません。`,
              `3. ハイパーテキストリンク
              本ウェブサイト上のハイパーテキストリンクによって利用者は、当社がコンテンツを管理していない、第三者が管理するウェブサイトに導かれる場合があります。利用者のインターネット・ナビゲーションを容易にするためにこうしたハイパーテキストリンクが本ウェブサイトに設けられている場合、リンク先のウェブサイトは、利用者の選択及び責任負担によりご利用下さい。`,
              `4. クッキー
              クッキーとは、利用者が本ウェブサイトを利用された場合に利用者のコンピュータに残される小さなファイルのことで（閲覧したページ、閲覧時日時等が記録されます）、本ウェブサイトを再度利用する際に読み取られることがあります。`,
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第22条（本サービス等の変更及び終了）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 当社は、利用者への事前の通知了解を得ることなく、いつでも本サービス等（商品の価格、本サービスに係る送料及び手数料その他の料金を含みます。）の一部もしくは全部を変更し又は提供を終了することができるものとします。",
              "2. 当社は、本条に基づき当社が行った措置に基づき利用者に損害が発生した場合でも、当社に故意又は過失がある場合を除いて一切責任を負わないものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第23条（規約の変更）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 当社は、民法548条の4に基づき、当社が必要と判断する場合、利用者の事前の承諾を得ることなく、本利用規約の内容を変更することができます。",
              "2. 当社が本利用規約の内容を変更し、その変更内容を利用者に通知し周知した場合、通知において指定された変更後の規約の施行日以降（ただし、通知において期日を指定しない場合には、当社が通知を発した当日を施行日とします。）に、本サービスを利用した場合、変更後の本利用規約に同意したものとみなし、変更後の本利用規約が適用されます。",
              "3. 前2項の規定にかかわらず、法令上、利用者の同意やあらかじめの通知等が必要となるような内容の変更の場合は、当社の所定の方法で利用者の同意を得、又はあらかじめの通知等を行うものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第24条（利用契約上の地位の譲渡等）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 会員は、当社の書面による事前の承諾なく、利用契約上の地位又は本利用規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。",
              "2. 当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本利用規約に基づく権利及び義務並びに会員の登録事項その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、会員は、かかる譲渡につき、本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第25条（準拠法及び合意管轄）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 本利用規約の準拠法は日本法とします。",
              "2. 本利用規約及び利用契約に起因し、又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dd className="text__m">
          附則
          <br />
          2021年6月9日制定
          <br />
          2021年9月21日改定
          <br />
          2022年3月8日改定
          <br />
          2022年12月15日改定
          <br />
          2023年9月1日改定
          <br />
          2024年10月4日改定
        </dd>
      </dl>
    </div>
  );
}
