"use client";
import { type PropsWithChildren } from "react";

import clsx from "clsx";
import dynamic from "next/dynamic";

import styles from "./ToastProvider.module.scss";

export interface ToastProviderProps extends PropsWithChildren {}

export const defaultToastClassNames = {
  success: clsx(styles.common, styles.success),
  error: clsx(styles.common, styles.error),
};

const Toaster = dynamic(() => import("react-hot-toast").then((c) => c.Toaster), {
  ssr: false,
});

export function ToastProvider({ children }: ToastProviderProps): React.ReactNode {
  return (
    <>
      {children}
      <Toaster
        position="bottom-center"
        containerClassName={styles.container}
        gutter={10}
        toastOptions={{
          icon: null,
          duration: 5000,
          success: { className: defaultToastClassNames.success },
          error: { className: defaultToastClassNames.error },
        }}
      />
    </>
  );
}
