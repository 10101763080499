import { removeEmpty } from "./array";
import { getDomesticProvince, provinces } from "./province";

interface PostalAddress {
  provinceId: number;
  province: string;
  locality: string; // => city
  street: string; // => addressLine1
  extended: string;
}

interface CacheType {
  [key: string]: {
    [key: string]: string[];
  };
}

// 同じ郵便番号で都道府県が異なる場合があるコード
export const duplicatedZipCodeProvinces = [
  { zip: "4980000", provinceId: 24 }, // 三重県（愛知と競合）
  { zip: "6180000", provinceId: 27 }, // 大阪府（京都と競合）
  { zip: "8710000", provinceId: 44 }, // 大分県（福岡と競合）
].map((code) => ({
  ...code,
  province: provinces.find((province) => province.id === code.provinceId)!,
}));

const CACHE: CacheType = {};
const URL = "https://yubinbango.github.io/yubinbango-data/data";
const REGION = [null, ...provinces.map((prefecture) => prefecture.jp)];

function validateFormat(code: string): boolean {
  // 数字7桁か、数字3桁-数字4桁の形式
  return /^\d{7}$|^\d{3}-\d{4}$/.test(code);
}

function selectAddress(addressParts: string[]): PostalAddress | null {
  if (!addressParts || !addressParts[0] || !addressParts[1]) {
    return null;
  }
  const provinceIndex = parseInt(addressParts[0], 10);
  const provinceName = REGION[provinceIndex] ?? "";
  return {
    province: provinceName,
    provinceId: parseInt(addressParts[0]),
    locality: addressParts[1],
    street: addressParts[2],
    extended: addressParts[3] ?? "",
  };
}

function fetchZipCodeData(url: string): Promise<{ [zipCode: string]: string[] }> {
  return new Promise((resolve, reject) => {
    const callbackName = `$yubin`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)[callbackName] = function (data: { [zipCode: string]: string[] }) {
      resolve(data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (window as any)[callbackName];
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.charset = "UTF-8";
    script.src = `${url}?callback=${callbackName}`;
    script.onerror = () => {
      reject(new Error("JSONP request failed"));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (window as any)[callbackName];
    };
    document.head.appendChild(script);
  });
}

export async function getAddressByZipCode(zipCode: string): Promise<PostalAddress | null> {
  if (!validateFormat(zipCode)) {
    return null;
  }
  const normalizedZipCode = zipCode.replace("-", "");
  const regionCode = normalizedZipCode.slice(0, 3);
  if (CACHE[regionCode]) {
    return selectAddress(CACHE[regionCode][normalizedZipCode] ?? []);
  }
  try {
    const addresses = await fetchZipCodeData(`${URL}/${regionCode}.js`);
    CACHE[regionCode] = {
      ...addresses,
      // asをつけないと、CACHE[regionCode]がobject型として推論されない
      ...((CACHE[regionCode] as CacheType[keyof CacheType]) || {}),
    };
    return selectAddress(addresses[normalizedZipCode] || []);
  } catch (error) {
    throw error; // 外部でキャッチ可能にする
  }
}

export async function getProvinceIdsByZipCode(zipCode: string): Promise<number[]> {
  const primary = await getAddressByZipCode(zipCode);
  const secondary = duplicatedZipCodeProvinces.find((code) => code.zip === zipCode);
  return removeEmpty([primary?.provinceId, secondary?.provinceId]);
}

export async function isValidZipCode(zipCode: string): Promise<boolean> {
  try {
    const address = await getAddressByZipCode(zipCode);
    return Boolean(address);
  } catch {
    return false;
  }
}

export function isValidProvince(provinceName: string): boolean {
  const province = getDomesticProvince(provinceName);
  return Boolean(province);
}

export function formatZipCode(value: string) {
  // 全角数字を半角に変換
  const halfWidthValue = value.replace(/[０-９]/g, (s) =>
    String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  );

  // 数字以外を除去してハイフンを取り除き、7桁まで取得
  return halfWidthValue.replace(/[^0-9]/g, "").slice(0, 7);
}
