/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type { GetSurveyResponse, SaveSurvey200, SaveSurveyBody } from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get cancellation survey data
 */
export const getCancellationSurvey = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetSurveyResponse>(
    { url: `/survey/cancellation`, method: "GET", signal },
    options
  );
};

export const getGetCancellationSurveyQueryKey = () => {
  return [`/survey/cancellation`] as const;
};

export const getGetCancellationSurveyQueryOptions = <
  TData = Awaited<ReturnType<typeof getCancellationSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCancellationSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCancellationSurveyQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCancellationSurvey>>> = ({ signal }) =>
    getCancellationSurvey(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCancellationSurvey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCancellationSurveyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCancellationSurvey>>
>;
export type GetCancellationSurveyQueryError = ErrorType<unknown>;

/**
 * @summary get cancellation survey data
 */
export const useGetCancellationSurvey = <
  TData = Awaited<ReturnType<typeof getCancellationSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCancellationSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCancellationSurveyQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get cancellation survey data
 */
export const prefetchGetCancellationSurvey = async <
  TData = Awaited<ReturnType<typeof getCancellationSurvey>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCancellationSurvey>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCancellationSurveyQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCancellationSurveySuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCancellationSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCancellationSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCancellationSurveyQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCancellationSurvey>>> = ({ signal }) =>
    getCancellationSurvey(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCancellationSurvey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCancellationSurveySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCancellationSurvey>>
>;
export type GetCancellationSurveySuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get cancellation survey data
 */
export const useGetCancellationSurveySuspense = <
  TData = Awaited<ReturnType<typeof getCancellationSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCancellationSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCancellationSurveySuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get cancellation survey data
 */
export const prefetchGetCancellationSurveySuspense = async <
  TData = Awaited<ReturnType<typeof getCancellationSurvey>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCancellationSurvey>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCancellationSurveySuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get normal skip survey
 */
export const getNormalSkipSurvey = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetSurveyResponse>(
    { url: `/survey/normal_skip`, method: "GET", signal },
    options
  );
};

export const getGetNormalSkipSurveyQueryKey = () => {
  return [`/survey/normal_skip`] as const;
};

export const getGetNormalSkipSurveyQueryOptions = <
  TData = Awaited<ReturnType<typeof getNormalSkipSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNormalSkipSurvey>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNormalSkipSurveyQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNormalSkipSurvey>>> = ({ signal }) =>
    getNormalSkipSurvey(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNormalSkipSurvey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNormalSkipSurveyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNormalSkipSurvey>>
>;
export type GetNormalSkipSurveyQueryError = ErrorType<unknown>;

/**
 * @summary get normal skip survey
 */
export const useGetNormalSkipSurvey = <
  TData = Awaited<ReturnType<typeof getNormalSkipSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNormalSkipSurvey>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNormalSkipSurveyQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get normal skip survey
 */
export const prefetchGetNormalSkipSurvey = async <
  TData = Awaited<ReturnType<typeof getNormalSkipSurvey>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getNormalSkipSurvey>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetNormalSkipSurveyQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetNormalSkipSurveySuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getNormalSkipSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNormalSkipSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNormalSkipSurveyQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNormalSkipSurvey>>> = ({ signal }) =>
    getNormalSkipSurvey(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getNormalSkipSurvey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNormalSkipSurveySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNormalSkipSurvey>>
>;
export type GetNormalSkipSurveySuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get normal skip survey
 */
export const useGetNormalSkipSurveySuspense = <
  TData = Awaited<ReturnType<typeof getNormalSkipSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNormalSkipSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNormalSkipSurveySuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get normal skip survey
 */
export const prefetchGetNormalSkipSurveySuspense = async <
  TData = Awaited<ReturnType<typeof getNormalSkipSurvey>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNormalSkipSurvey>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetNormalSkipSurveySuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get freeze skip survey
 */
export const getFreezeSkipSurvey = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetSurveyResponse>(
    { url: `/survey/freeze_skip`, method: "GET", signal },
    options
  );
};

export const getGetFreezeSkipSurveyQueryKey = () => {
  return [`/survey/freeze_skip`] as const;
};

export const getGetFreezeSkipSurveyQueryOptions = <
  TData = Awaited<ReturnType<typeof getFreezeSkipSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFreezeSkipSurvey>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFreezeSkipSurveyQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFreezeSkipSurvey>>> = ({ signal }) =>
    getFreezeSkipSurvey(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFreezeSkipSurvey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFreezeSkipSurveyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFreezeSkipSurvey>>
>;
export type GetFreezeSkipSurveyQueryError = ErrorType<unknown>;

/**
 * @summary get freeze skip survey
 */
export const useGetFreezeSkipSurvey = <
  TData = Awaited<ReturnType<typeof getFreezeSkipSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFreezeSkipSurvey>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFreezeSkipSurveyQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get freeze skip survey
 */
export const prefetchGetFreezeSkipSurvey = async <
  TData = Awaited<ReturnType<typeof getFreezeSkipSurvey>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFreezeSkipSurvey>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetFreezeSkipSurveyQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetFreezeSkipSurveySuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getFreezeSkipSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFreezeSkipSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFreezeSkipSurveyQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFreezeSkipSurvey>>> = ({ signal }) =>
    getFreezeSkipSurvey(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getFreezeSkipSurvey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFreezeSkipSurveySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFreezeSkipSurvey>>
>;
export type GetFreezeSkipSurveySuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get freeze skip survey
 */
export const useGetFreezeSkipSurveySuspense = <
  TData = Awaited<ReturnType<typeof getFreezeSkipSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFreezeSkipSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFreezeSkipSurveySuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get freeze skip survey
 */
export const prefetchGetFreezeSkipSurveySuspense = async <
  TData = Awaited<ReturnType<typeof getFreezeSkipSurvey>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getFreezeSkipSurvey>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetFreezeSkipSurveySuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get after purchase survey data
 */
export const getAfterPurchaseSurvey = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetSurveyResponse>(
    { url: `/survey/after_purchase`, method: "GET", signal },
    options
  );
};

export const getGetAfterPurchaseSurveyQueryKey = () => {
  return [`/survey/after_purchase`] as const;
};

export const getGetAfterPurchaseSurveyQueryOptions = <
  TData = Awaited<ReturnType<typeof getAfterPurchaseSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getAfterPurchaseSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAfterPurchaseSurveyQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAfterPurchaseSurvey>>> = ({ signal }) =>
    getAfterPurchaseSurvey(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAfterPurchaseSurvey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAfterPurchaseSurveyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAfterPurchaseSurvey>>
>;
export type GetAfterPurchaseSurveyQueryError = ErrorType<unknown>;

/**
 * @summary get after purchase survey data
 */
export const useGetAfterPurchaseSurvey = <
  TData = Awaited<ReturnType<typeof getAfterPurchaseSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getAfterPurchaseSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAfterPurchaseSurveyQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get after purchase survey data
 */
export const prefetchGetAfterPurchaseSurvey = async <
  TData = Awaited<ReturnType<typeof getAfterPurchaseSurvey>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAfterPurchaseSurvey>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetAfterPurchaseSurveyQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetAfterPurchaseSurveySuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAfterPurchaseSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAfterPurchaseSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAfterPurchaseSurveyQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAfterPurchaseSurvey>>> = ({ signal }) =>
    getAfterPurchaseSurvey(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getAfterPurchaseSurvey>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAfterPurchaseSurveySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAfterPurchaseSurvey>>
>;
export type GetAfterPurchaseSurveySuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get after purchase survey data
 */
export const useGetAfterPurchaseSurveySuspense = <
  TData = Awaited<ReturnType<typeof getAfterPurchaseSurvey>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAfterPurchaseSurvey>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAfterPurchaseSurveySuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get after purchase survey data
 */
export const prefetchGetAfterPurchaseSurveySuspense = async <
  TData = Awaited<ReturnType<typeof getAfterPurchaseSurvey>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAfterPurchaseSurvey>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetAfterPurchaseSurveySuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary save survey data
 */
export const saveSurvey = (
  saveSurveyBody: SaveSurveyBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(saveSurveyBody);
  return customAxiosInstance<SaveSurvey200>(
    {
      url: `/survey/save`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

export const getSaveSurveyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveSurvey>>,
    TError,
    { data: SaveSurveyBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof saveSurvey>>,
  TError,
  { data: SaveSurveyBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveSurvey>>,
    { data: SaveSurveyBody }
  > = (props) => {
    const { data } = props ?? {};

    return saveSurvey(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveSurveyMutationResult = NonNullable<Awaited<ReturnType<typeof saveSurvey>>>;
export type SaveSurveyMutationBody = SaveSurveyBody;
export type SaveSurveyMutationError = ErrorType<unknown>;

/**
 * @summary save survey data
 */
export const useSaveSurvey = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveSurvey>>,
    TError,
    { data: SaveSurveyBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getSaveSurveyMutationOptions(options);

  return useMutation(mutationOptions);
};
