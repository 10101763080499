import urlJoin from "url-join";

import { Image } from "@/components";

import styles from "./Footer.module.scss";

export function Footer(): React.ReactNode {
  return (
    <footer>
      <ul className={styles.footerLink}>
        <li className={styles.link}>
          <a
            href={process.env.NEXT_PUBLIC_FRONT_BASE_URL || "/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            会社概要
          </a>
        </li>
        <li className={styles.link}>
          <a href="/privacy" target="_blank" rel="noopener noreferrer">
            プライバシーポリシー
          </a>
        </li>
        <li className={styles.link}>
          <a
            href={urlJoin(process.env.NEXT_PUBLIC_FRONT_BASE_URL ?? "", "law")}
            target="_blank"
            rel="noopener noreferrer"
          >
            特定商取引法に基づく表示
          </a>
        </li>
        <li>
          <a href="tel:08001235610">
            <Image
              src="https://asset.basefood.co.jp/images/parts/icon_phone.png"
              alt="電話番号（自動応答）0800-123-5610"
              size={{ width: 24, height: 24 }}
              className={styles.image}
            />
            0800-123-5610
          </a>
        </li>
      </ul>
      <div className={styles.copy}>Copyright&copy; BASE FOOD, Inc. All Rights Reserved.</div>
    </footer>
  );
}
