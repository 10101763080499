"use client";
import { createTheme } from "@mui/material/styles";
import { LocalizationProviderProps } from "@mui/x-date-pickers";
import { jaJP } from "@mui/x-date-pickers/locales";
import localJa from "date-fns/locale/ja";

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fac83c",
        light: "#fdfdee",
        dark: "#ff9b00",
      },
      secondary: {
        main: "#00508c",
        light: "#8cb4dc",
        dark: "#003264",
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: "44px",
            width: "100%",
          },
          input: {
            paddingTop: "10.5px",
            paddingBottom: "10.5px",
            color: "#5a5a5a",
            fontWeight: "100",
          },
          notchedOutline: {
            outline: "1px solid #c8c8c8",
            height: "44px",
            border: "none",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
        },
      },
    },
  },
  jaJP
);

export const dateFormats = {
  fullDate: "yyyy年MM月dd日",
  normalDate: "yyyy/MM/dd (E)",
  month: "MM月",
  monthShort: "M月",
  year: "yyyy年",
  dayOfMonth: "d",
  weekday: "EEEE",
  weekdayShort: "E",
  hours24h: "HH時",
  hours12h: "hh時",
  meridiem: "a",
  minutes: "mm分",
  seconds: "ss秒",
  fullTime: "HH:mm",
  fullTime12h: "ah:mm",
  fullTime24h: "HH:mm",
  keyboardDate: "yyyy/MM/dd (E)",
  keyboardDateTime: "yyyy/MM/dd HH:mm",
  keyboardDateTime12h: "yyyy/MM/dd ah:mm",
  keyboardDateTime24h: "yyyy/MM/dd HH:mm",
  shortDate: "M月d日",
  normalDateWithWeekday: "M月d日 (E)",
} as const satisfies LocalizationProviderProps<Date, typeof localJa>["dateFormats"];
