import { useCallback } from "react";

import { useUpdateSubscriptionPoint } from "@/generated/open-api/subscription/subscription";
import { convertApiCartToCart } from "@/models/cart/converters";
import { convertSubscriptionToSubscriptionApi } from "@/models/subscription/converters";
import { SubscriptionModel } from "@/models/subscription/type";

interface UpdateSubscriptionParams {
  data: SubscriptionModel;
}

export const useParsedUpdateSubscriptionPoint = () => {
  const { mutate, mutateAsync, ...rest } = useUpdateSubscriptionPoint();

  const updatePoint = useCallback(
    async (params: UpdateSubscriptionParams) =>
      await mutate({ data: convertSubscriptionToSubscriptionApi(params.data) }),
    [mutate]
  );

  const updatePointAsync = useCallback(
    async (params: UpdateSubscriptionParams) => {
      const res = await mutateAsync({ data: convertSubscriptionToSubscriptionApi(params.data) });
      return convertApiCartToCart(res);
    },
    [mutateAsync]
  );

  return { mutate: updatePoint, mutateAsync: updatePointAsync, ...rest };
};
