import { useCallback, useMemo } from "react";

import { FEATURE_FLAG } from "@/configs/system";
import { useGetSubscriptionSuspense } from "@/generated/open-api/subscription/subscription";
import { convertApiSubscriptionToSubscription } from "@/models/subscription/converters";

export const useParsedGetSubscription = () => {
  const { data, refetch, ...rest } = useGetSubscriptionSuspense();
  const parsed = useMemo(() => convertApiSubscriptionToSubscription(data), [data]);
  // 送料無料キャンペーン時のみ表示
  const isLimitReachedInFreeShippingCampaign =
    FEATURE_FLAG.isFreeShippingCampaignActive && parsed.countRestOfInviteLimit <= 0;

  const parsedRefetch = useCallback(async () => {
    const res = await refetch();
    return convertApiSubscriptionToSubscription(res.data!);
  }, [refetch]);

  return {
    data: parsed,
    refetch: parsedRefetch,
    isLimitReachedInFreeShippingCampaign,
    ...rest,
  };
};
