import clsx from "clsx";

import { ProductModel } from "@/models/product/type";
import { formatCurrency } from "@/utils/currency";

import styles from "./UnitPrice.module.scss";

export interface UnitPriceProps {
  product: ProductModel;
  isSubscription: boolean;
  isFirstSubscriptionOrder: boolean;
}

export function UnitPrice({
  product,
  isSubscription,
  isFirstSubscriptionOrder,
}: UnitPriceProps): React.ReactNode {
  const unitPart = (
    <span className={clsx("text__s text__gray__dark text__normal", styles.meal)}>
      {["/", product.meal].join("")}
    </span>
  );

  return isSubscription ? (
    <>
      <span
        className={clsx("text__s text__gray__dark text__normal text__left", styles.normalPrice)}
      >
        {formatCurrency(product.normalPrice ?? 0)}
      </span>
      <span className={clsx("text__m text__bold text__red", styles.subscriptionPrice)}>
        {formatCurrency(
          isFirstSubscriptionOrder
            ? product.firstSubscriptionPrice
            : (product.afterSecondSubscriptionPrice ?? 0)
        )}
        {unitPart}
      </span>
    </>
  ) : (
    <span className="text__m text__bold price__single">
      {formatCurrency(product.normalPrice ?? 0)}
      {unitPart}
    </span>
  );
}
