import { Product } from "@/generated/open-api/schemas";
import { NuxtCommonProduct } from "@/storage/types/common";

import { ProductModel } from "./type";

export const convertApiProductToProduct = (response: Product): ProductModel => {
  return {
    id: Number(response.id),
    name: response.name!,
    title: response.title!,
    variantId: response.variant_id!,
    variantTitle: response.variant_title!,
    sku: response.sku!,
    images: response.images!,
    variantImageIndex: response.variant_image_index!,
    tags: response.tags!,
    meal: response.meal!,
    price: Number(response.price),
    normalPrice: Number(response.normal_price),
    normalTotalPrice: Number(response.normal_total_price),
    firstSubscriptionPrice: Number(response.first_subscription_price),
    firstSubscriptionTotalPrice: Number(response.first_subscription_total_price),
    firstSubscriptionPercentage: Number(response.first_subscription_percentage),
    afterSecondSubscriptionPrice: Number(response.after_second_subscription_price),
    afterSecondSubscriptionTotalPrice: Number(response.after_second_subscription_total_price),
    secondSubscriptionPercentage: Number(response.second_subscription_percentage),
    productBrandNewDiscountPrice: Number(response.product_brand_new_discount_price),
    productBrandNewDiscountTotalPrice: Number(response.product_brand_new_discount_total_price),
    productBrandNewDiscountPercentage: response.product_brand_new_discount_percentage,
    outOfStock: response.out_of_stock!,
    inventoryQuantity: Number(response.inventory_quantity),
    subscription: !!response.subscription,
    vailSubscription: !!response.vail_subscription,
    hasSubscription: !!response.has_subscription,
    quantity: Number(response.quantity),
    unit: response.unit!,
    unitMultiple: Number(response.unit_multiple),
    selectOptions: response.select_options!,
    shopUrl: response.shop_url!,
    royaltyMileNum: Number(response.royalty_mile_num),
    isFreeze: !!response.is_freeze,
    isSuspension: !!response.is_suspension,
    isPreSale: !!response.is_pre_sale,
    isBrandNewDiscount: !!response.is_brand_new_discount,
    displayOrder: Number(response.display_order),
    salesType: response.sales_type,
    isSetProduct: false,
    isOutlet: response.sales_type === 2,
    maxQuantity:
      response.sales_type === 2
        ? response.select_options[response.select_options.length - 1]
        : undefined,
  };
};

export const convertProductToApiProduct = (product: ProductModel): Product => {
  return {
    id: product.id,
    name: product.name,
    title: product.title,
    variant_id: product.variantId,
    variant_title: product.variantTitle,
    sku: product.sku,
    images: product.images,
    variant_image_index: product.variantImageIndex,
    tags: product.tags,
    meal: product.meal,
    price: product.price.toString(),
    normal_price: product.normalPrice.toString(),
    normal_total_price: product.normalTotalPrice.toString(),
    first_subscription_price: product.firstSubscriptionPrice.toString(),
    first_subscription_total_price: product.firstSubscriptionTotalPrice.toString(),
    first_subscription_percentage: product.firstSubscriptionPercentage,
    after_second_subscription_price: product.afterSecondSubscriptionPrice.toString(),
    after_second_subscription_total_price: product.afterSecondSubscriptionTotalPrice.toString(),
    second_subscription_percentage: product.secondSubscriptionPercentage,
    product_brand_new_discount_price: product.productBrandNewDiscountPrice?.toString() ?? "0",
    product_brand_new_discount_total_price:
      product.productBrandNewDiscountTotalPrice?.toString() ?? "0",
    product_brand_new_discount_percentage: product.productBrandNewDiscountPercentage ?? 0,
    out_of_stock: product.outOfStock,
    inventory_quantity: product.inventoryQuantity,
    subscription: product.subscription,
    vail_subscription: product.vailSubscription,
    has_subscription: product.hasSubscription,
    quantity: product.quantity,
    unit: product.unit,
    unit_multiple: product.unitMultiple,
    select_options: product.selectOptions,
    shop_url: product.shopUrl,
    royalty_mile_num: product.royaltyMileNum,
    is_freeze: product.isFreeze,
    is_suspension: product.isSuspension,
    is_pre_sale: product.isPreSale,
    is_brand_new_discount: product.isBrandNewDiscount,
    display_order: product.displayOrder,
    sales_type: product.salesType,
  };
};

export const convertProductToNuxtStoreProduct = (product: ProductModel): NuxtCommonProduct => {
  return {
    id: product.id,
    name: product.name,
    title: product.title,
    variant_id: product.variantId,
    variant_title: product.variantTitle,
    sku: product.sku,
    images: product.images,
    variant_image_index: product.variantImageIndex,
    tags: product.tags,
    meal: product.meal,
    price: product.price.toString(),
    normal_price: product.normalPrice.toString(),
    normal_total_price: product.normalTotalPrice.toString(),
    first_subscription_price: product.firstSubscriptionPrice.toString(),
    first_subscription_total_price: product.firstSubscriptionTotalPrice.toString(),
    first_subscription_percentage: product.firstSubscriptionPercentage,
    after_second_subscription_price: product.afterSecondSubscriptionPrice.toString(),
    after_second_subscription_total_price: product.afterSecondSubscriptionTotalPrice.toString(),
    second_subscription_percentage: product.secondSubscriptionPercentage,
    product_brand_new_discount_price: product.productBrandNewDiscountPrice?.toString() ?? "0",
    product_brand_new_discount_total_price:
      product.productBrandNewDiscountTotalPrice?.toString() ?? "0",
    product_brand_new_discount_percentage: product.productBrandNewDiscountPercentage ?? 0,
    out_of_stock: product.outOfStock,
    inventory_quantity: product.inventoryQuantity,
    subscription: product.subscription,
    vail_subscription: product.vailSubscription,
    has_subscription: product.hasSubscription,
    quantity: product.quantity,
    unit: product.unit,
    unit_multiple: product.unitMultiple,
    select_options: product.selectOptions,
    shop_url: product.shopUrl,
    royalty_mile_num: product.royaltyMileNum,
    is_freeze: product.isFreeze,
    is_suspension: product.isSuspension,
    is_pre_sale: product.isPreSale,
    is_brand_new_discount: product.isBrandNewDiscount,
    display_order: product.displayOrder,
    sales_type: product.salesType,
  };
};

export const convertNuxtStoreProductToProduct = (product: NuxtCommonProduct): ProductModel => {
  return {
    id: product.id,
    name: product.name!,
    title: product.title,
    variantId: product.variant_id,
    variantTitle: product.variant_title,
    sku: product.sku,
    images: product.images,
    variantImageIndex: product.variant_image_index,
    tags: product.tags,
    meal: product.meal,
    price: parseFloat(product.price),
    normalPrice: parseFloat(product.normal_price),
    normalTotalPrice: parseFloat(product.normal_total_price),
    firstSubscriptionPrice: parseFloat(product.first_subscription_price),
    firstSubscriptionTotalPrice: parseFloat(product.first_subscription_total_price),
    firstSubscriptionPercentage: product.first_subscription_percentage,
    afterSecondSubscriptionPrice: parseFloat(product.after_second_subscription_price),
    afterSecondSubscriptionTotalPrice: parseFloat(product.after_second_subscription_total_price),
    secondSubscriptionPercentage: product.second_subscription_percentage,
    productBrandNewDiscountPrice:
      product.product_brand_new_discount_price !== "0"
        ? parseFloat(product.product_brand_new_discount_price)
        : undefined,
    productBrandNewDiscountTotalPrice:
      product.product_brand_new_discount_total_price !== "0"
        ? parseFloat(product.product_brand_new_discount_total_price)
        : undefined,
    productBrandNewDiscountPercentage:
      product.product_brand_new_discount_percentage !== 0
        ? product.product_brand_new_discount_percentage
        : undefined,
    outOfStock: product.out_of_stock,
    inventoryQuantity: product.inventory_quantity,
    subscription: product.subscription,
    vailSubscription: product.vail_subscription,
    hasSubscription: product.has_subscription,
    quantity: product.quantity,
    unit: product.unit,
    unitMultiple: product.unit_multiple,
    selectOptions: product.select_options,
    shopUrl: product.shop_url,
    royaltyMileNum: product.royalty_mile_num,
    isFreeze: product.is_freeze,
    isSuspension: product.is_suspension,
    isPreSale: product.is_pre_sale,
    isBrandNewDiscount: product.is_brand_new_discount,
    displayOrder: product.display_order,
    salesType: product.sales_type,
    isSetProduct: false,
    isOutlet: product.sales_type === 2,
    maxQuantity:
      product.sales_type === 2
        ? product.select_options[product.select_options.length - 1]
        : undefined,
  };
};
