import clsx from "clsx";

import styles from "./FixedContainer.module.scss";

interface FixedContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

export function FixedContainer({ className, children }: FixedContainerProps): React.ReactNode {
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
}
