import { deliveryReceiveOptionLabels, deliveryTimeZoneLabels } from "@/models/delivery/consts";
import { objectEntries } from "@/utils/object";

export const deliveryReceiveOptions = objectEntries(deliveryReceiveOptionLabels).map(
  ([value, label]) => ({ value, label })
);

export const deliveryTimeZoneOptions = objectEntries(deliveryTimeZoneLabels).map(
  ([value, label]) => ({ value, label })
);
