import { useMemo } from "react";

import { useClientFrontStoreCartItems } from "@/storage";

import { useGetParsedCart, useGetParsedCartSuspense } from "./useGetParsedCart";

/**
 * @remarks
 * Client Side renderingでのみ対応
 */
export function useGetParsedCartFromCartItems(options?: Parameters<typeof useGetParsedCart>[1]) {
  const { cartItems } = useClientFrontStoreCartItems();
  const params = useMemo(
    () => ({
      products: cartItems.map((item) => ({
        variant_id: item.variantId.toString(),
        quantity: item.quantity.toString(),
        subscription: item.subscription,
      })),
    }),
    [cartItems]
  );
  return useGetParsedCart(params, options);
}

export function useGetParsedCartFromCartItemsSuspense(
  options?: Parameters<typeof useGetParsedCartSuspense>[1]
) {
  const { cartItems } = useClientFrontStoreCartItems();
  const params = useMemo(
    () => ({
      products: cartItems.map((item) => ({
        variant_id: item.variantId.toString(),
        quantity: item.quantity.toString(),
        subscription: item.subscription,
      })),
    }),
    [cartItems]
  );
  return useGetParsedCartSuspense(params, options);
}
