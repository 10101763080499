export type KarteEvent = "click_invite_share" | "invite_url_share" | "buy_yearplan";

export function fireKarteEvent(eventName: KarteEvent, values = {}) {
  if (typeof window.tracker === "undefined") {
    return;
  }

  try {
    window.tracker.track(eventName, values);
  } catch (_) {
    // 一旦nuxtそのままにしている
    // eslint-disable-next-line no-console
    console.error("tracker not found ");
  }
}
