"use client";

import { useCallback, useMemo } from "react";

import { useSuspenseQuery } from "@tanstack/react-query";

import {
  getCustomer,
  getGetCustomerQueryKey,
  useGetCustomerSuspense,
} from "@/generated/open-api/customer/customer";
import { convertApiCustomerToCustomer } from "@/models/customer/converters";

export const useParsedGetCustomer = () => {
  const { data, ...rest } = useGetCustomerSuspense();
  const parsed = useMemo(() => convertApiCustomerToCustomer(data.customer), [data]);
  return { data: parsed, ...rest };
};

export const useParsedGetCustomerIfLoggedIn = (isLoggedIn: boolean) => {
  const queryKey = useMemo(
    () => (isLoggedIn ? getGetCustomerQueryKey() : [...getGetCustomerQueryKey(), isLoggedIn]),
    [isLoggedIn]
  );
  const queryFn = useCallback(() => (isLoggedIn ? getCustomer() : null), [isLoggedIn]);
  const { data, ...restResult } = useSuspenseQuery({ queryKey, queryFn });
  const customer = useMemo(
    () => (data ? convertApiCustomerToCustomer(data.customer) : null),
    [data]
  );
  return { data: customer, ...restResult };
};
