"use client";
import { Dispatch, SetStateAction, useCallback, useLayoutEffect, useRef, useState } from "react";

import { isClient } from "@/utils";
import { getFromPath } from "@/utils/object";

import { getLocalStorageValue, setLocalStorageValue, splitLocalStorageKeys } from "./helpers";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const subscriptions = new Map<string, Set<(value: any) => void>>();

type UseClientLocalStorageParams<T> = T extends undefined
  ? {
      key: string;
      initialValue?: T;
    }
  : {
      key: string;
      initialValue: T;
    };

// オーバーロードシグネチャ(初期値が指定されている場合)
export function useClientLocalStorage<T>(
  params: UseClientLocalStorageParams<T>
): [T, Dispatch<SetStateAction<T>>];

// オーバーロードシグネチャ(初期値がオプショナルまたは、undefinedの場合)
export function useClientLocalStorage<T>(
  params: UseClientLocalStorageParams<T | undefined>
): [T | undefined, Dispatch<SetStateAction<T>>];

/**
 * ローカルストレージを利用するカスタムフック
 * @remarks
 * Client Side renderingでのみ対応
 */
export function useClientLocalStorage<T>({
  key,
  initialValue,
}: UseClientLocalStorageParams<T | undefined>) {
  const [inMemoryValue, setInMemoryValue] = useState<T | undefined>(() => {
    if (!isClient) {
      throw new Error("useClientLocalStorage can only be used in the client");
    }
    try {
      const storedValue = getLocalStorageValue<T>(key);
      return storedValue ?? initialValue;
    } catch (_error) {
      return initialValue;
    }
  });
  const initializedRef = useRef(false);

  // ssr対応
  // 初期化、Hydrationエラーを回避するため、useStateの初期化関数ではなく、useLayoutEffectに記載する
  useLayoutEffect(() => {
    try {
      initializedRef.current = true;
      const storedValue = getLocalStorageValue<T>(key);
      setInMemoryValue(storedValue ?? initialValue);
    } catch (_error) {
      setInMemoryValue(initialValue);
    }
    // keyの変更は反映しない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValue = useCallback((value: T | ((prev: T | undefined) => T)) => {
    const currentValue = getLocalStorageValue<T>(key) ?? initialValue; // undefinedの場合は初期値を使用する
    const newValue = value instanceof Function ? value(currentValue) : value;
    setLocalStorageValue(key, newValue);
    // localStorageの変更を通知する(自身も含む)
    const keys = key.split(".");
    subscriptions.forEach((subscribedSubs, subscribedKey) => {
      const subscribedKeys = subscribedKey.split(".");
      const notice = keys.every((key, index) => subscribedKeys[index] === key);
      if (notice) {
        const noticeValue = getLocalStorageValue(subscribedKey);
        subscribedSubs.forEach((sub) => sub(noticeValue));
      }
    });
    // keyの変更は反映しない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 同一タブでlocalStorageが変更された場合に反映する
  useLayoutEffect(() => {
    const subs = subscriptions.get(key) || new Set();
    subs.add(setInMemoryValue);
    subscriptions.set(key, subs);
    return () => {
      const currentSubs = subscriptions.get(key);
      if (currentSubs) {
        currentSubs.delete(setInMemoryValue);
      }
    };
    // keyの変更は反映しない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 別タブでlocalStorageが変更された場合に反映する
  useLayoutEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      const [rootKey, valuePath] = splitLocalStorageKeys(key);
      if (event.key !== rootKey) return;
      const newRootData = event.newValue;
      const newRootValue = newRootData ? JSON.parse(newRootData) : initialValue;
      if (!valuePath) {
        // キーがネストしていない場合
        setInMemoryValue(newRootValue);
      } else {
        // キーがネストしている場合
        const newValue = getFromPath(newRootValue as object, valuePath as never);
        setInMemoryValue(newValue);
      }
    };
    addEventListener("storage", handleStorageChange);
    return () => removeEventListener("storage", handleStorageChange);
    // keyの変更は反映しない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [inMemoryValue, setValue] as const;
}
