"use client";
import { useCallback, useState } from "react";

import clsx from "clsx";

import { Merge } from "@/utils";

import styles from "./Switch.module.scss";

type BaseProps = React.ComponentProps<"div">;

type OwnProps = {
  onChange?: (value: boolean) => void;
  value?: boolean;
  defaultValue?: boolean;
  disabled?: boolean;
};

export type SwitchProps = Merge<BaseProps, OwnProps>;

export function Switch({
  onChange,
  value: propsValue,
  defaultValue,
  className,
  disabled,
  ...rest
}: SwitchProps): React.ReactNode {
  const [innerValue, setInnerValue] = useState(defaultValue ?? false);
  const value = propsValue ?? innerValue;

  const handleClick = useCallback(() => {
    const newValue = !value;
    setInnerValue(newValue);
    onChange?.(newValue);
  }, [onChange, value]);

  return (
    <div
      className={clsx(styles.root, value && styles.active, disabled && styles.disabled, className)}
      onClick={!disabled ? handleClick : undefined}
      {...rest}
    >
      <span />
    </div>
  );
}
