import clsx from "clsx";

import { getIsAppWebView } from "@/utils";

import { MobileHeader } from "./MobileHeader";
import { PCHeader } from "./PCHeader";
import styles from "./TheHeaderJp.module.scss";

export function TheHeaderJP(): React.ReactNode {
  const isAppWebView = getIsAppWebView();
  if (isAppWebView) {
    return null;
  }

  return (
    <header className={clsx(styles.headerFull)}>
      <PCHeader />
      <MobileHeader />
    </header>
  );
}
