import React from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./Merits.module.scss";

export function Merits(): React.ReactNode {
  return (
    <Image
      src="https://asset.basefood.co.jp/lp/common/merits.png"
      alt="BASE BREAD継続コースは、4週間ごとにBASE BREADをお届けするコースです。初回は商品価格20%オフ、2回目以降10%オフの価格でご購入いただけます。"
      aspectRatio="15 / 14"
      containerClassName={clsx(styles.image)}
    />
  );
}
