"use client";

import { useEffect, useState } from "react";

import { useDisableBodyScroll } from "@/utils/hooks";

import styles from "./FullScreenProgressBar.module.scss";

export interface FullScreenProgressBarProps {
  percentage?: number;
  completeCallback?: () => void;
}

export function FullScreenProgressBar({
  percentage = 0,
  completeCallback,
}: FullScreenProgressBarProps): React.ReactNode {
  const validTargetPercentage = Math.min(100, Math.max(0, percentage));
  const [progress, setCurrentPercentage] = useState(0);

  useDisableBodyScroll();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPercentage((prev) => {
        if (prev >= validTargetPercentage) {
          clearInterval(interval);
          if (prev === 100) {
            completeCallback?.();
          }
          return prev;
        }
        return prev + 1;
      });
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [completeCallback, validTargetPercentage]);

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.wrapper}>
          <svg viewBox="0 0 100 6" preserveAspectRatio="none" style={{ borderRadius: 8 }}>
            <path d="M 0,3 L 100,3" stroke="#eee" strokeWidth="1" fillOpacity="0"></path>
            <path
              d="M 0,3 L 100,3"
              stroke="#FFEA82"
              strokeWidth="6"
              fillOpacity="0"
              style={{ strokeDasharray: "100, 100", strokeDashoffset: 100 - progress }}
            ></path>
          </svg>
          <div className={styles.progressText}>処理中　{progress}%</div>
        </div>
      </div>
    </div>
  );
}
