"use client";
import { useCallback, useMemo } from "react";

import {
  convertCustomerToLocalStorageCustomer,
  convertLocalStorageCustomerToCustomer,
} from "@/models/customer/converters";
import { CustomerModel } from "@/models/customer/type";
import { useParsedGetCustomerIfLoggedIn } from "@/queries";

import { LocalStagePath, NuxtStoreCustomer } from "./types";
import { useClientLocalStorage } from "./useClientLocalStorage";
import { setLocalStorageValue } from "./useClientLocalStorage/helpers";
import { useAuth } from "../components/domains/auth";

const storeKey: LocalStagePath = `vuex.customer.customer`;

/**
 * ローカルストレージに保存されているユーザー情報を取得する
 * ログインしている場合、DBに保存されるユーザー情報を優先する
 * クライアントサイドでのみ利用可能
 *
 * TODO: localStorageのvuex.customer.customerはNuxt用なもので、Nextは参照していない
 *       10/9 Ph3のリリースした後、この関数を完全に消して、useParsedGetCustomerIfLoggedInを直接使えばいい
 */
export function useClientCustomerCustomer() {
  const { isLoggedIn } = useAuth();
  const { data: serverCustomer } = useParsedGetCustomerIfLoggedIn(isLoggedIn);
  const [storageCustomer, setStorageCustomer] = useClientLocalStorage<
    NuxtStoreCustomer["customer"] | undefined
  >({ key: storeKey });

  const customer = useMemo(
    // ログインしている場合は、サーバー側のユーザー情報を優先する
    () => (isLoggedIn ? serverCustomer : convertLocalStorageCustomerToCustomer(storageCustomer)),
    [isLoggedIn, serverCustomer, storageCustomer]
  );

  const setCustomer = useCallback(
    (newCustomer: CustomerModel | ((prev: CustomerModel | undefined) => CustomerModel)) => {
      setStorageCustomer((prev) => {
        const prevCustomer = convertLocalStorageCustomerToCustomer(prev);
        const newValue =
          typeof newCustomer === "function" ? newCustomer(prevCustomer) : newCustomer;
        return convertCustomerToLocalStorageCustomer(newValue);
      });
    },
    [setStorageCustomer]
  );

  return {
    customer: customer ?? null,
    setCustomer,
  };
}

export function clearStoredCustomer() {
  setLocalStorageValue(storeKey, undefined);
}
