import clsx from "clsx";

import styles from "./ListTable.module.scss";
import { List, ListProps } from "../List";

export interface ListTableProps extends ListProps {}

export function ListTable({ className, style, ...rest }: ListTableProps): React.ReactNode {
  return (
    <div className={clsx(styles.root, className)} style={style}>
      <List className={styles.list} {...rest} />
    </div>
  );
}
