import React from "react";

interface CircularProgressBarProps {
  progress: number;
  barClassName?: string;
  bgColor?: string;
  barColor?: string;
}

export function CircularProgressBar({
  progress,
  barClassName,
  bgColor,
  barColor,
}: CircularProgressBarProps) {
  const radius = 30;
  const strokeWidth = 10;
  const cxy = radius + strokeWidth;
  const strokeDasharray = radius * 2 * Math.PI;
  const strokeDashoffset = strokeDasharray - (progress / 100) * strokeDasharray;

  return (
    <svg height={cxy * 2} width={cxy * 2} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx={cxy}
        cy={cxy}
        r={radius}
        stroke={bgColor || "#ffffff"}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={cxy}
        cy={cxy}
        r={radius}
        stroke={barColor || "#00bfff"}
        strokeWidth={strokeWidth}
        fill="none"
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        transform="rotate(-90 40 40)"
        className={barClassName}
      />
    </svg>
  );
}
