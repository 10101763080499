import { useEffect, useRef } from "react";

import { ValueOf } from "next/dist/shared/lib/constants";
import {
  DeepPartial,
  EventType,
  FieldPath,
  FieldValues,
  UseFormReturn,
  useFormContext,
} from "react-hook-form";

import { objectValues } from "./object";
import { Tuplify } from "./type";

type Callback<T extends FieldValues> = (
  formValues: DeepPartial<T>,
  options: {
    name?: FieldPath<T>;
    type?: EventType;
    prevValues?: Partial<T>;
  }
) => void;

export function useWatchFormValues<T extends FieldValues>(
  callback: Callback<T>,
  formContext?: UseFormReturn<T>
) {
  const hooksContext = useFormContext<T>();
  const context = formContext ?? hooksContext;
  const { watch } = context;
  const callbackRef = useRef<Callback<T>>(callback);
  callbackRef.current = callback;

  const formValueRef = useRef<DeepPartial<T>>();
  useEffect(() => {
    const subscription = watch((formValues, { name, type }) => {
      const prevValues = formValueRef.current;
      formValueRef.current = formValues;
      callbackRef.current(formValues, { name, type, prevValues });
    });
    return () => subscription.unsubscribe();
  }, [watch]);
}

export function enumToZodEnum<T extends object>(enumObj: T) {
  return objectValues(enumObj) as Tuplify<ValueOf<T>>;
}

interface OnChangeEvent<T> {
  name?: keyof T;
  type?: string;
  value: unknown;
}

type OnChangeCallback<T extends FieldValues> = (
  values: Partial<T>,
  event: OnChangeEvent<T>
) => void;

export function useOnChange<T extends FieldValues>(
  onChange?: OnChangeCallback<T>,
  propFormContext?: UseFormReturn<T>
) {
  const formContext = useFormContext<T>();
  const { watch, getValues } = propFormContext ?? formContext;

  // フォームの値の変更を監視
  useEffect(() => {
    if (!onChange) return;
    const subscription = watch((value, { name, type }) => {
      if (type === undefined && name === undefined) {
        // typeとnameが両方undefinedの場合は、初期値の設定時に発火するため、無視する
        return;
      }
      const formValues = getValues();
      onChange(formValues as T, {
        name: name as keyof T,
        type,
        value,
      });
    });

    // コンポーネントのアンマウント時に購読を解除
    return () => subscription.unsubscribe();
  }, [watch, onChange, formContext, getValues]);
}
