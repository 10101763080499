"use client";
import React, { useCallback, useState } from "react";

import clsx from "clsx";

import { useAuth } from "@/components";
import { Row } from "@/components/containers";
import { Input, Selector } from "@/components/inputs";
import { useClientCartForm } from "@/storage";
import { provinces } from "@/utils/province";
import { zeroPadding } from "@/utils/string";
import { duplicatedZipCodeProvinces, formatZipCode, getAddressByZipCode } from "@/utils/zipCode";

import styles from "./AddressForm.module.scss";
import { PasswordInput } from "./PasswordInput";
import { AddressValues, UserCredentialsValues } from "../CheckoutForm/schema";
import { FieldLabel } from "../FieldLabel";
import { MarketingCheckbox } from "../MarketingCheckbox/MarketingCheckbox";

interface AddressFormWithCredentialsProps {
  withCredentials: true;
  credentials: Partial<UserCredentialsValues>;
  onChangeCredentials: (values: Partial<UserCredentialsValues>) => void;
}

interface AddressFormWithoutCredentialsProps {
  withCredentials: false;
  credentials?: undefined;
  onChangeCredentials?: undefined;
}

type AddressFormProps = {
  isLoggedIn?: boolean; // ログインしている場合は、メールアドレス入力不可、パスワード・お知らせチェックボックス非表示、ログアウトボタンを表示する
  showLogout: boolean;
  address: Partial<AddressValues>;
  onChangeAddress: (values: Partial<AddressValues>) => void;
  onChangedEmail?: (email: string) => void;
} & (AddressFormWithCredentialsProps | AddressFormWithoutCredentialsProps);

export function AddressForm({
  isLoggedIn = false,
  showLogout,
  address,
  onChangeAddress,
  withCredentials,
  credentials,
  onChangeCredentials,
  onChangedEmail,
}: AddressFormProps): React.ReactNode {
  const [provinceDisabled, setProvinceDisabled] = useState(false);
  const { logoutWithoutRedirect } = useAuth();
  const { clearForm } = useClientCartForm();

  const handleChangeAddress = useCallback(
    (newValues: Partial<AddressValues>) => {
      onChangeAddress({
        ...address,
        ...newValues,
      });
    },
    [address, onChangeAddress]
  );

  const handleChangeCredentials = useCallback(
    (newValues: Partial<UserCredentialsValues>) => {
      if (withCredentials) {
        onChangeCredentials({
          ...credentials,
          ...newValues,
        });
      }
    },
    [credentials, withCredentials, onChangeCredentials]
  );

  const handleLogout = useCallback(async () => {
    // ログアウトしcookie中のアクセストークンを削除
    await logoutWithoutRedirect();
    // localStorageに保存されているフォームデータをクリア
    clearForm();
    // ページをリロードによってフォームの状態をリセット
    window.location.href = "/checkout";
  }, [clearForm, logoutWithoutRedirect]);

  const fillAddressFromZip = useCallback(
    async (zipCode: string) => {
      if (zipCode.length !== 7) {
        setProvinceDisabled(false);
        return;
      }

      const postalAddress = await getAddressByZipCode(zipCode);
      if (!postalAddress) {
        setProvinceDisabled(false);
        return;
      }
      const provinceCode = `JP-${zeroPadding(postalAddress.provinceId, 2)}`;
      const hasProvince = provinces.find((prefecture) => provinceCode === prefecture.code);
      if (hasProvince) {
        handleChangeAddress({
          province: provinceCode,
          city: postalAddress.locality,
          addressLine1: postalAddress.street,
          zip: zipCode,
        });
        if (
          duplicatedZipCodeProvinces.find((duplicatedZipCode) => duplicatedZipCode.zip === zipCode)
        ) {
          setProvinceDisabled(false);
        } else {
          setProvinceDisabled(true);
        }
      }
    },
    [handleChangeAddress]
  );

  return (
    <div className={clsx("row", "mg__bottom__l")}>
      <div style={{ width: "100%" }}>
        <Row>
          <div className="col-12">
            <FieldLabel showUnfilled={!address.lastName}>お名前</FieldLabel>
          </div>
        </Row>
        <div className={clsx(styles.nameRow)}>
          <div className={clsx("col-12", "col-m-6")}>
            <Input
              name="lastName"
              type="text"
              placeholder="姓"
              value={address.lastName}
              onChange={(e) => handleChangeAddress({ lastName: e.target.value })}
            />
          </div>
          <div className={clsx("col-12", "col-m-6", "pd__top__s", "pd__top__off__pc")}>
            <Input
              name="firstName"
              type="text"
              placeholder="名"
              value={address.firstName}
              onChange={(e) => handleChangeAddress({ firstName: e.target.value })}
            />
          </div>
        </div>
      </div>
      {withCredentials && (
        <div className="col-12">
          <FieldLabel showUnfilled={!credentials?.email}>
            メールアドレス
            {isLoggedIn && showLogout && (
              <button
                type="button"
                className={clsx("btn", "inline", "gray", "mini", "angle__right")}
                onClick={handleLogout}
              >
                ログアウト
                <i className={clsx("fas", "fa-angle-right")} />
              </button>
            )}
          </FieldLabel>
          <Input
            name="email"
            type="email"
            placeholder="example@example.com"
            value={credentials?.email ?? ""}
            disabled={isLoggedIn}
            onChange={(e) => handleChangeCredentials({ email: e.target.value })}
            onBlur={(e) => onChangedEmail?.(e.target.value)}
          />

          <div className={clsx("mg__top__s", "mg__bottom__s")}>
            <p className={clsx("text__s", "text__gray__dark")}>
              ※docomo,au,softbankのキャリアメールはメールが届かない場合がございます。
              <br />
              上記以外のメールアドレスのご利用を推奨しております。
            </p>
          </div>
          {!isLoggedIn && (
            <MarketingCheckbox
              tooltipId="marketing-accept-tooltip"
              name="acceptsMarketing"
              checked={credentials.acceptsMarketing ?? false}
              onChange={(e) => handleChangeCredentials({ acceptsMarketing: e.target.checked })}
            />
          )}
        </div>
      )}

      {withCredentials && !isLoggedIn && (
        <div className={clsx("col-12", "col-m-6")}>
          <FieldLabel showUnfilled={!credentials.password}>パスワード</FieldLabel>
          <PasswordInput
            name="password"
            value={credentials.password}
            onChange={(e) => handleChangeCredentials({ password: e.target.value })}
          />
        </div>
      )}

      <Row style={{ flexWrap: "wrap", width: "100%" }}>
        <div className={clsx("col-12")}>
          <FieldLabel showUnfilled={!address.zip}>郵便番号</FieldLabel>
        </div>
        <div className={clsx("col-12", "col-m-6")}>
          <Input
            name="zip"
            value={address.zip}
            onChange={(e) => handleChangeAddress({ zip: e.target.value })}
            // 入力が完了した時点で都道府県・市区町村を自動入力する関数を呼び出す
            onBlur={(e) => {
              const value = formatZipCode(e.target.value);
              handleChangeAddress({ zip: value });
              fillAddressFromZip(value);
            }}
            type="tel"
            placeholder="000000"
          />
        </div>
        <div className={clsx("col-12", "col-m-6")}>
          <p className={clsx("text__s", "pd__top__s", "text__gray__dark")}>
            都道府県・市区町村が自動入力されます
          </p>
        </div>
      </Row>

      <div className={clsx("col-12", "col-m-6")}>
        <FieldLabel showUnfilled={!address.province}>都道府県</FieldLabel>
        <Selector
          name="province"
          disabled={provinceDisabled}
          options={[{ label: "", value: "" }].concat(
            provinces.map((prefecture) => {
              return { label: prefecture.jp, value: prefecture.code };
            })
          )}
          value={address.province}
          onChange={(province) => handleChangeAddress({ province })}
        />
      </div>

      <div className={clsx("col-12", "col-m-6")}>
        <FieldLabel showUnfilled={!address.city}>市区町村</FieldLabel>
        <Input
          name="city"
          value={address.city}
          type="text"
          placeholder="目黒区"
          onChange={(e) => handleChangeAddress({ city: e.target.value })}
        />
      </div>

      <div className="col-12">
        <FieldLabel showUnfilled={!address.addressLine1}>町名・番地</FieldLabel>
        <Input
          name="addressLine1"
          value={address.addressLine1}
          type="text"
          placeholder="町名・番地"
          onChange={(e) => handleChangeAddress({ addressLine1: e.target.value })}
        />
      </div>

      <div className="col-12">
        <FieldLabel>建物名・部屋番号</FieldLabel>
        <Input
          name="addressLine2"
          value={address.addressLine2 ?? ""}
          type="text"
          placeholder="建物名・部屋番号"
          onChange={(e) => handleChangeAddress({ addressLine2: e.target.value })}
        />
      </div>

      <div className="col-12">
        <FieldLabel showUnfilled={!address.phone}>電話番号</FieldLabel>
        <Input
          name="phone"
          value={address.phone}
          type="tel"
          placeholder="0312345678"
          onChange={(e) => handleChangeAddress({ phone: e.target.value })}
        />
      </div>
    </div>
  );
}
