"use client";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";

import clsx from "clsx";

import { Column, ColumnProps, Row } from "@/components/containers";
import { Merge } from "@/utils";

import styles from "./RadioButtonGroup.module.scss";
import { RadioButton } from "../RadioButton/RadioButton";

type BaseProps = ColumnProps;

export type RadioButtonGroupOption<T> = {
  value: T;
  label: React.ReactNode;
  disabled?: boolean;
};

type OwnProps<T> = {
  options: ReadonlyArray<RadioButtonGroupOption<T>>;
  onChange?: (value: T, e: ChangeEvent<HTMLInputElement>) => void;
  value?: T;
  defaultValue?: T;
  name: string;
  direction?: "row" | "column";
  outlined?: boolean;
  disabled?: boolean;
};

type RadioButtonGroupProps<T> = Merge<BaseProps, OwnProps<T>>;

/**
 *
 */
export function RadioButtonGroup<T>({
  options,
  onChange,
  value: propsValue,
  defaultValue,
  direction = "column",
  outlined = false,
  disabled: disabledAll,
  className,
  ...rest
}: RadioButtonGroupProps<T>): React.ReactNode {
  const [innerValue, setInnerValue] = useState(defaultValue);

  const [optionsWithStringValue, valueOptionMap] = useMemo(() => {
    const optionMap = new Map<string, RadioButtonGroupOption<T>>();
    const newOptions = options.map((option) => {
      const stringValue = JSON.stringify(option.value);
      optionMap.set(stringValue, option);
      return {
        stringValue,
        ...option,
      };
    });
    return [newOptions, optionMap] as const;
  }, [options]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const stringValue = e.target.value;
      const selectedOption = valueOptionMap.get(stringValue);
      if (selectedOption) {
        onChange?.(selectedOption.value, e);
        setInnerValue(selectedOption.value);
      }
    },
    [onChange, valueOptionMap]
  );

  const stringValue = useMemo(() => {
    const value = propsValue ?? innerValue;
    return optionsWithStringValue.find((option) => option.value === value)?.stringValue ?? "";
  }, [innerValue, optionsWithStringValue, propsValue]);

  const Container = direction === "row" ? Row : Column;
  const containerClassName = direction === "row" ? styles.rowContainer : styles.colContainer;

  return (
    <Container className={clsx(className, containerClassName)} {...rest}>
      {optionsWithStringValue.map((option) => {
        const checked = option.stringValue === stringValue;
        const borderClassName = outlined
          ? !checked
            ? styles.outlinedUnselected
            : styles.outlinedSelected
          : "";
        return (
          <RadioButton
            key={option.stringValue}
            value={option.stringValue}
            checked={checked}
            onChange={handleChange}
            className={clsx(borderClassName, styles.radio)}
            disabled={disabledAll || option.disabled}
          >
            {option.label}
          </RadioButton>
        );
      })}
    </Container>
  );
}
