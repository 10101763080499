"use client";
import { memo, useEffect } from "react";

import { initUkomiWidget } from "@/utils/ukomi/ukomi";

interface UKomiStarProps {
  groupName: string;
  name: string;
  price: number;
}

export const UKomiStar = memo(function UKomiStar({
  groupName,
  name,
  price,
}: UKomiStarProps): React.ReactNode {
  // サーバー側でレンダリングするとエラー(Text content does not match server-rendered HTML)がでるので、useStateを使ってクライアント側に強制

  useEffect(() => {
    initUkomiWidget();
  }, [groupName, name, price]);

  return (
    <>
      <div
        data-gname={groupName}
        data-name={name}
        data-url={`/products{name}`}
        data-price={price}
        className="review-summary-container details ukomi"
        data-group="true"
        data-action="summary"
      />
      <style jsx>
        {`
          .ukomi {
            height: 28px;
            width: 344px;
          }
        `}
      </style>
    </>
  );
});
