import React from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./CvHead.module.scss";

interface Props {
  font?: "gothic" | "maru";
}

export function CvHead({ font = "gothic" }: Props): React.ReactNode {
  const src =
    font === "gothic"
      ? "https://asset.basefood.co.jp/lp/common/heading_gothic.jpg"
      : "https://asset.basefood.co.jp/lp/common/heading_maru.jpg";

  return (
    <Image
      src={src}
      alt="BASE BREAD -ベースブレッド- 継続コース"
      aspectRatio="3 / 1"
      containerClassName={clsx(styles.image, "full__image")}
    />
  );
}
