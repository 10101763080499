import { useMemo } from "react";

import clsx from "clsx";

import { CartModel } from "@/models/cart/type";
import { formatCurrency } from "@/utils/currency";

export interface TotalPriceProps {
  cart: CartModel;
  className?: string;
  isFirst?: boolean;
}

export function TotalPrice({ cart, className, isFirst = true }: TotalPriceProps): React.ReactNode {
  const totalPrice = useMemo(() => {
    const base = isFirst ? cart.totalPrice : cart.afterSecondTotalPrice;
    const fee = cart.totalShippingFee;

    return base - fee;
  }, [cart.afterSecondTotalPrice, cart.totalPrice, cart.totalShippingFee, isFirst]);

  return (
    <span
      className={clsx("text__bold", className, cart.isSubscription ? "text__red" : "text__black")}
    >
      {formatCurrency(totalPrice)}
    </span>
  );
}
