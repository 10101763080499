import { ProductLargeImage } from "@/components/domains";

export function RenewalBread(): React.ReactNode {
  return (
    <ProductLargeImage
      url="/products/basebread/chocolate"
      pcImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_renew_4sku_W1920_H1080.jpg?0701",
        alt: "BASE BREAD リニューアル",
        aspectRatio: "1920/1080",
      }}
      spImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_renew_4sku_W1080_H1080.jpg?0701",
        alt: "BASE BREAD リニューアル",
        aspectRatio: "1080/1080",
      }}
    />
  );
}
