// orvalで使用するapplication/x-www-form-urlencodedの変換関数
export const customFormUrlEncodedFn = (body: Record<string, unknown>): URLSearchParams => {
  const params = new URLSearchParams();
  Object.entries(body).forEach(([key, value]) => {
    if (value === undefined) return;
    if (typeof value === "object" && value !== null) {
      params.append(key, JSON.stringify(value));
    } else {
      params.append(key, value?.toString() || "");
    }
  });

  return params;
};
