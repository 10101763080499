import { List } from "@/components/displays";

export function Invite(): React.ReactNode {
  return (
    <div>
      <dl className="mg__bottom__m">
        <dt className="text__m text__bold">第１条（規約）</dt>
        <dd className="text__m text__justify mg__top__s">
          本規約は、ベースフード株式会社（以下「当社」といいます）が提供する友だち紹介制度（以下「本制度」）の利用に関し、利用者（以下「ユーザー」といいます）と、当社との間に適用されます。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第２条（規約の変更）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 当社は、民法548条の4に基づき、当社が必要と判断する場合、ユーザーの事前の承諾を得ることなく、本規約の内容を変更することができます。",
              "2. 当社が本規約の内容を変更し、その変更内容をユーザーに通知し周知した場合、通知において指定された期日以降（ただし、通知において期日を指定しない場合には、当社が通知を発した当日を期日とします。）に、本制度を利用した場合、変更後の本規約に同意したものとみなし、変更後の本規約が適用されます。ユーザーが通知において指定された期日内に解約の手続きをとらなかった場合には、変更後の本規約に同意したものとみなされます。",
              "3. 前2項の規定にかかわらず、法令上、ユーザーの同意やあらかじめの通知等が必要となるような内容の変更の場合は、当社の所定の方法でユーザーの同意を得、またはあらかじめの通知等を行うものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第３条（連絡・通知）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 当社は、本制度に関連してユーザーに通知をする場合には、当社のウェブサイトに掲示する方法または利用されたユーザー指定の電子メールアドレス・住所に宛てて電子メールを送信するあるいは文書の郵送など、当社が適当と判断する方法で実施します。",
              "2. 前項に定める方法により行われた通知は、通知がユーザーに到達したか否かにかかわらず、前者の場合には通知内容が当社のウェブサイトに掲示された時点に、後者の場合は当社が電子メール・文書を発信した時点で、それぞれその効力を生じるものとします。",
              "3. 本制度に関する問い合わせその他ユーザーから当社に対する連絡または通知は、当社の定める方法で行うものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第４条（本制度の利用）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. ユーザーは、本規約を遵守することに同意することで、本制度の利用することができます。なお、本制度の利用にかかる通信料等はユーザーが負担するものとします。",
              "2. 本制度の利用は、本制度を利用する個人本人が行わなければならず、原則として代理人による利用は認められません。",
              "3. ユーザーは、当社が指定する方法により、当社が提供する商品を第三者に紹介し、当該第三者（以下「ご友人」といいます）が、当社が定めた手順に従い、商品を購入することによって、本制度を利用することができます。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第５条（特典）</dt>
        <dd className="text__m text__justify mg__top__s">
          本制度を利用することによる特典は以下のとおりです。
          <List
            className="mg__top__m"
            contents={[
              `1. ご友人特典
              ユーザーのご紹介により、ご友人が、当社が定めた手順に従い、商品を購入する場合に、ご友人には特典が付与されます。特典の内容や条件は当社が特典ごとにこれを決定し、別途サイト等で公表いたします。`,
              `2. ユーザーご本人特典
              ユーザーのご紹介により、ご友人が、当社が定めた手順に従い、商品を購入した場合に限り、ユーザーには特典が付与されます。特典の内容や条件は当社が特典ごとにこれを決定し、別途サイト等で公表いたします。`,
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第６条（利用の拒否または無効）</dt>
        <dd className="text__m text__justify mg__top__s">
          当社は、本制度の利用が次の各号のいずれかに該当する場合、当該サービス利用者の利用（再利用を含みます）を拒否することがあり、あるいは利用が無効となることがあります。なお、当社が本条により本制度の利用を拒否したあるいは無効とした場合、当社はその理由について一切の開示義務を負いません。
          <List
            className="mg__top__m"
            contents={[
              "1. 本規約に同意しない場合",
              "2. 本規約に違反するおそれがあると当社が判断した場合",
              "3. 未成年である場合でかつ保護者の同意がない場合",
              "4. 当社に提供した情報の全部または一部につき虚偽、誤記または記載漏れがあった場合",
              "5. ユーザーによる本制度の利用が、法令等その他公序良俗に反するおそれがある場合",
              "6. 反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合",
              "7. ユーザーが過去に当社との契約に違反した者またはその関係者と当社が判断した場合",
              "8. 当社からの連絡が不到達の場合あるいは期日までに返信がない場合",
              "9. 当社が定める本制度の利用要項を満たさない場合",
              "10. 当社が同一人物への複数回の紹介であると認めた場合",
              "11. その他、当社が利用を適当でないと判断した場合",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第７条（注意事項）</dt>
        <dd className="text__m text__justify mg__top__s">
          ユーザーは、本制度の利用にあたり、次の注意事項を理解し、遵守する必要があります。
          <List
            className="mg__top__m"
            contents={[
              "1. 送料や代引き手数料は本制度を利用した割引対象外です。",
              "2. ご紹介いただける人数は当社がキャンペーンごとに定めますが、同一人物を複数回紹介することはできません。",
              `3. 第5条で定める特典の対象となるご友人のご紹介は、ベースフード継続コース（旧定期便含む）の利用がはじめての方に限ります。過去に継続コースをご購入いただいたことのある方（再登録者も含む。）は、特典の対象となるご友人には含まれず、ご友人特典及びユーザーご本人特典の対象外となりますので、ご注意ください。
              なお、再登録者の定義については、商品購入に関する利用規約第1条第6号に定めておりますので、ご確認ください。`,
              "4. 次回の継続コースの決済金額を超える特典を所持していた場合、特典はさらに翌月の決済に持ち越されます。なお、持ち越して保有できる特典の上限は、当社の裁量でこれを決定することができ、あらかじめ当社が別途サイト等で公表いたします。",
              "5. 特典ごとに定められた有効期限があります。有効期限は、当社の裁量でこれを決定することができ、あらかじめ当社が別途サイト等で公表いたします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第８条（本制度の提供の停止・中断・終了）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            className="mg__top__m"
            contents={[
              "1. 当社は、当社の判断により本制度の全部又は一部の提供・運営を終了することができるものとします。当社は、当社の判断により本制度の全部又は一部の提供・運営を終了する場合、当社が適当と判断する方法でユーザーにその旨通知いたします。ただし、緊急の場合はユーザーへの通知を行わない場合があります。",
              <>
                2.
                当社は、以下各号の事由が生じた場合には、ユーザーに事前に通知することなく、本制度の一部又は全部を一時的に中断することができるものとします。
                <List
                  contents={[
                    "⑴　本制度用のハード・ソフト・通信機器設備等に関わるメンテナンスや修理を定期的又は緊急に行う場合",
                    "⑵　アクセス過多、その他予期せぬ要因でシステムに負荷が集中した場合",
                    "⑶　当社のセキュリティを確保する必要が生じた場合",
                    "⑷　ハッキングその他の方法により当社の保有する情報が流出等した場合",
                    "⑸　電気通信事業者の役務が提供されない場合",
                    "⑹　天災等の不可抗力により本制度の提供が困難な場合",
                    "⑺　火災、停電、その他の不慮の事故又は戦争、紛争、動乱、暴動、労働争議等により本制度の提供が困難な場合",
                    "⑻　法令又はこれらに基づく措置により本制度の運営が不能となった場合",
                    "⑼　その他前各号に準じ当社が必要と判断した場合",
                  ]}
                />
              </>,
              "3. 当社は、本条に基づき当社が行った措置によりユーザーに生じた損害について、一切の責任を負いません。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第９条（保証の否認・免責）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 当社は、本制度がユーザーの特定の目的に適合すること、期待する機能正確性・有用性・完全性を有すること、及び不具合が生じないことについて、何ら保証するものではありません。",
              "2. ユーザーは、本制度を利用することが、ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、ユーザーによる本制度の利用が、ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。",
              "3. ユーザーは自己の責任において本制度を利用するものとし、当社は、本規約に違反したことによって生じたユーザーの損害については、一切の責任を負いません。",
              "4. ユーザーは、本規約に違反することにより、又は本制度の利用に関連して当社に損害を与えた場合、当社に対しその全ての損害（弁護士等専門家費用及び当社において対応に要した人件費相当額を含みます。）を賠償しなければなりません。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第１０条（サービス利用上の地位の譲渡等）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. ユーザーは、書面による事前の承諾なく、本規約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。",
              "2. 当社が本制度にかかる事業を第三者に譲渡した場合、当社は、当該事業譲渡に伴い、本規約上の地位、本規約に基づく権利及び義務並びに当社の保有するユーザー（に関する情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項によって予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第１１条（分離可能性）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、ユーザー及び当社は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。",
              "2. 本規約のいずれかの条項又はその一部が、あるユーザーとの関係で無効又は執行不能と判断された場合であっても、他のユーザーとの関係における有効性等には影響を及ぼさないものとします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第１２条（準拠法及び管轄裁判所）</dt>
        <dd className="text__m text__justify mg__top__s">
          <List
            contents={[
              "1. 本規約の準拠法は日本法とします。",
              "2. 本規約に起因しまたは関連する一切の紛争については、訴額に応じ、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。",
            ]}
          />
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dt className="text__m text__bold">第１３条（協議解決）</dt>
        <dd className="text__m text__justify mg__top__s">
          ユーザー及び当社は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
        </dd>
      </dl>
      <dl className="mg__top__l mg__bottom__m">
        <dd className="text__m">
          附則
          <br />
          2021年6月9日制定
          <br />
          2021年8月4日改定
          <br />
          2023年9月1日改定
          <br />
          2024年7月18日改定
        </dd>
      </dl>
    </div>
  );
}
