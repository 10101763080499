import { getFromPath } from "./object";
import { Falsy, FilteredKeyOf, IndexType, PathFlatten, PathOf } from "./type";

type SumByKey<T> = FilteredKeyOf<PathFlatten<T>, number> | ((item: T) => number);

export function sumBy<T>(array: T[], key: SumByKey<T>): number {
  return array.reduce((acc, item) => {
    const value =
      typeof key === "function"
        ? key(item)
        : (getFromPath(item, key as unknown as PathOf<T>) as number);
    if (value != null && value !== undefined) {
      return acc + value;
    }
    return acc;
  }, 0);
}

type MapByKey<T> = FilteredKeyOf<PathFlatten<T>, IndexType> | ((item: T) => IndexType);

export function mapBy<T>(array: T[], key: MapByKey<T>): { [key: string]: T | undefined } {
  const res = array.reduce(
    (result, item) => {
      const keyValue =
        typeof key === "function"
          ? key(item)
          : (getFromPath(item, key as unknown as PathOf<T>) as IndexType);
      if (keyValue === undefined) return result;
      result[keyValue] = item;
      return result;
    },
    {} as { [key: string]: T }
  );
  return res;
}

/**
 * 配列内の要素を指定されたキーまたはキーを生成する関数に基づいてグループ化します。
 * @example
 * const employees: Employee[] = [
 *   { name: 'John', department: 'sales' },
 *   { name: 'Taro', department: 'hr' },
 *   { name: 'Smith', department: 'sales' },
 * ];
 *
 * // `groupBy`関数を使用して従業員を部署ごとにグループ化
 * const groupedByDepartment = groupBy(employees, 'department');
 *
 * console.log(groupedByDepartment);
 * // 出力:
 * // {
 * //   "sales": [{ "name": "John", "department": "sales" }, { "name": "Smith", "department": "sales" }],
 * //   "hr": [{ "name": "Taro", "department": "hr" }]
 * // }
 */
type GroupByKey<T> = FilteredKeyOf<PathFlatten<T>, IndexType> | ((item: T) => IndexType);
export function groupBy<T>(array: T[], key: GroupByKey<T>): { [key: string]: T[] } {
  const res = array.reduce(
    (result, item) => {
      const keyValue =
        typeof key === "function"
          ? key(item)
          : (getFromPath(item, key as unknown as PathOf<T>) as IndexType);
      if (keyValue === undefined) return result;
      if (!(keyValue in result)) result[keyValue] = [];
      result[keyValue] = [...result[keyValue], item];
      return result;
    },
    {} as { [key: string]: T[] }
  );
  return res;
}

/**
/**
 * 配列から `null` および `undefined` の要素を削除します。
 *
 * @param arr - `null` や `undefined` を含む可能性のある配列
 * @returns `null` および `undefined` を除去した新しい配列
 *
 * @example
 * const arr = [1, null, 2, undefined, 3];
 * const result = removeEmpty(arr);
 * console.log(result); // [1, 2, 3]
 * TODO: TypeScript 5.5以降、`as T[]`を削除することができます。
 */
export function removeEmpty<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((item) => item !== null && item !== undefined) as T[];
}

/**
 * 与えられた配列からfalsyな値を除外します。
 * ここでいうfalsyな値とは、`false`、`0`、`''`（空の文字列）、`null`、`undefined`, `NaN`を指します。
 * @returns
 * @param arr
 */
export function removeFalsy<T>(arr: ReadonlyArray<T>): Exclude<T, Falsy>[] {
  return arr.filter((item) => !!item) as Exclude<T, Falsy>[];
}
