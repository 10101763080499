import styles from "./DeliveryCautions.module.scss";

interface DeliveryCautionsProps {
  supportDropOff: boolean;
  isSubscription: boolean;
  isFreezeDelivery: boolean;
  isNormalDelivery: boolean;
}

export function DeliveryCautions({
  supportDropOff,
  isSubscription,
  isFreezeDelivery,
  isNormalDelivery,
}: DeliveryCautionsProps): React.ReactNode {
  return (
    <div className="mg__top__s">
      {isFreezeDelivery && isNormalDelivery ? (
        <>
          <p className="text__s text__red">※常温配送と冷凍配送は別便でお届けします。</p>
          {supportDropOff && (
            <p className="text__s text__gray__dark">
              ※常温配送対面受け取り希望でも、ご不在時は宅配BOXにお届けする場合がございます。あらかじめご了承ください（東京都・埼玉県・神奈川県・千葉県が対象）
            </p>
          )}
          {isSubscription && (
            <p className="text__s text__gray__dark">
              ※2回目以降は常温配送と冷凍配送で別々の日時指定が可能になります。
              <br />
              ※2回目以降は常温配送のみ置き配の指定が可能になります。
            </p>
          )}
        </>
      ) : (
        <>
          {!isFreezeDelivery && (
            <p className="text__s text__gray__dark">
              ※置き配を利用される場合、
              <a
                rel="noopener noreferrer"
                href="/terms?show_tab=5"
                target="_blank"
                className={styles.link}
              >
                置き配規約
              </a>
              に同意いただいたことになります。
            </p>
          )}
          {supportDropOff && !isFreezeDelivery && (
            <p className="text__s text__gray__dark">
              ※常温配送対面受け取り希望でも、ご不在時は宅配BOXにお届けする場合がございます。あらかじめご了承ください（東京都・埼玉県・神奈川県・千葉県が対象）
            </p>
          )}
        </>
      )}
    </div>
  );
}
