interface ExclamationIconProps extends React.SVGProps<SVGSVGElement> {}

export function ExclamationIcon(props: ExclamationIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
        fill="white"
      />
      <path d="M8.83162 10.0731H7.16846L6.50012 3.33325H9.50012L8.83162 10.0731Z" fill="#FF6478" />
      <path
        d="M7.99995 13.3333C8.70531 13.3333 9.27711 12.7628 9.27711 12.059C9.27711 11.3552 8.70531 10.7847 7.99995 10.7847C7.29459 10.7847 6.72278 11.3552 6.72278 12.059C6.72278 12.7628 7.29459 13.3333 7.99995 13.3333Z"
        fill="#FF6478"
      />
    </svg>
  );
}
