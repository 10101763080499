import { useMemo } from "react";

import { useGetCustomerPoints } from "@/generated/open-api/customer/customer";
import { convertObjToCamelCase } from "@/utils/converters";

export const useParsedGetCustomerPoints = (page: number) => {
  const { data, ...rest } = useGetCustomerPoints({ page });

  const customerPoints = useMemo(() => {
    if (!data?.data || !data?.meta) return;
    return {
      points: data.data.map((point) => convertObjToCamelCase(point)),
      meta: convertObjToCamelCase(data.meta),
    };
  }, [data]);

  return { data: customerPoints, ...rest };
};
