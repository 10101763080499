import { useMemo } from "react";

import { useGetProductsByNames } from "@/generated/open-api/product/product";
import { convertApiProductToProduct } from "@/models/product/converters";
import { ProductName } from "@/models/product/type";

type UseGetParsedProductsByNamesParams = {
  names: ProductName[];
};

export const useGetParsedProductsByNames = (params: UseGetParsedProductsByNamesParams) => {
  const { data, ...rest } = useGetProductsByNames({ names: JSON.stringify(params.names) });
  const parsed = useMemo(() => data?.map(convertApiProductToProduct) ?? [], [data]);
  return { data: parsed, ...rest };
};
