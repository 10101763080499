import { type PropsWithChildren } from "react";

export interface PointIconProps {
  className?: string;
}

export function PointIcon({ className }: PropsWithChildren<PointIconProps>): React.ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="#FAC83C"
      />
      <path
        d="M9 18.924H11.466V13.452C12.816 13.452 13.968 13.47 15.066 12.804C16.146 12.138 16.704 11.112 16.704 9.744C16.704 8.646 16.29 7.656 15.66 7.044C14.85 6.252 13.68 6 12.006 6H9V18.924ZM11.466 8.412H12.51C13.68 8.412 14.292 8.88 14.292 9.744C14.292 10.626 13.734 11.058 12.618 11.058H11.466V8.412Z"
        fill="white"
      />
    </svg>
  );
}
