import clsx from "clsx";

import { ModalDialogCustomizeSetProps } from "@/components/domains/lp/OnePageCheckoutForm/SetSelector/ModalDialogCustomizeSet";
import { ProductModel } from "@/models/product/type";

import styles from "./CustomizeSetOption.module.scss";

interface CustomizeSetOptionProps {
  customizedSelectedProducts: ModalDialogCustomizeSetProps["initialSelectedProducts"];
  products: ProductModel[];
  onClick: () => void;
}

// 「自分で選択する」の場合のラジオボタン
export function CustomizeSetOption({
  customizedSelectedProducts,
  products,
  onClick,
}: CustomizeSetOptionProps): React.ReactNode {
  return (
    <>
      <div className="text__s mg__top__m">
        {Object.entries(customizedSelectedProducts ?? {}).map(([variantId, quantity]) => {
          if (quantity === 0) {
            return null;
          }
          const product = products.find((product) => product.variantId === Number(variantId));
          if (!product) {
            return null;
          }

          return (
            <div key={variantId}>
              {product.title} {product.variantTitle} （{quantity}
              {product.meal}）
            </div>
          );
        })}
      </div>
      <div className="text__right mg__top__s mg__bottom__m">
        <button
          className={clsx("text__s text__yellow", styles.changeCustomizeSetButton)}
          onClick={onClick}
        >
          内容を変更する
        </button>
      </div>
    </>
  );
}
