import clsx from "clsx";
import Link from "next/link";

import { Image } from "@/components/displays";

import styles from "./ProductLargeImage.module.scss";

type LargeImage = {
  src: string;
  aspectRatio: string;
  alt: string;
};

interface ProductLargeImageProps {
  spImage: LargeImage;
  pcImage: LargeImage;
  url?: string;
  full?: string;
}

export function ProductLargeImage({
  spImage,
  pcImage,
  url,
  full,
}: ProductLargeImageProps): React.ReactNode {
  const imageContents = (
    <>
      <Image
        src={spImage.src}
        aspectRatio={spImage.aspectRatio}
        containerClassName={clsx("full__image clear__pc", full && styles.fullImageSp)}
        alt={spImage.alt}
      />
      <Image
        src={pcImage.src}
        aspectRatio={pcImage.aspectRatio}
        containerClassName="full__image clear__sp"
        alt={pcImage.alt}
      />
    </>
  );

  return url ? <Link href={url}>{imageContents}</Link> : imageContents;
}
