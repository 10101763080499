import { useCallback, useMemo } from "react";

import { deleteCookieAccessToken } from "@/app/actions";
import { useLogout } from "@/generated/open-api/customer/customer";
import { payseTokenKey } from "@/models/auth/types";
import { clearStoredCustomer, setClientCustomerAccessToken } from "@/storage";
import { clearClientOutletCart } from "@/storage/clearClientOutletCart";
import { useCookiesAnywhere } from "@/utils/hooks/useCookiesAnywhere";

type UseAuthType =
  | {
      accessToken: string;
      isLoggedIn: true;
      setAccessToken: (accessToken: string) => void;
      clearAccessToken: () => void;
      logout: () => Promise<void>;
      logoutWithoutRedirect: () => void;
    }
  | {
      accessToken: undefined;
      isLoggedIn: false;
      setAccessToken: (accessToken: string) => void;
      clearAccessToken: () => void;
      logout: () => Promise<void>;
      logoutWithoutRedirect: () => void;
    };

const getCookieOptions = () => {
  const isLocal = process.env.NEXT_PUBLIC_APP_ENV !== "production";
  const domain = window.location.hostname.match(/\.basefood.+?$/)?.[0] || "localhost";

  // ローカルではhttpでも開発できるようにsecureをfalseにする
  return {
    path: "/",
    domain,
    maxAge: 60 * 60 * 24 * 30, // 30日
    secure: !isLocal,
  };
};

/**
 *
 * @remarks
 * ClientSide、ServerSideどちらでも利用可能だが、Static Renderingでなくなるので注意
 */
export const useAuth = (): UseAuthType => {
  const [cookies, setCookie, removeCookie] = useCookiesAnywhere([payseTokenKey]);
  const accessToken = cookies[payseTokenKey];
  // TODO: Nuxtと連携するためにLocalStorageを使っているが、Next移行後は不要になる

  const setAccessToken = useCallback(
    (accessToken: string) => {
      const options = getCookieOptions();
      setCookie(payseTokenKey, accessToken, options);
      setClientCustomerAccessToken(accessToken);
    },
    [setCookie]
  );

  const clearAccessToken = useCallback(async () => {
    const options = getCookieOptions();
    removeCookie(payseTokenKey, options);
    setClientCustomerAccessToken(undefined);
    // 念のため、サーバー側からもCookieも削除
    await deleteCookieAccessToken();
  }, [removeCookie]);

  const { mutateAsync: logoutFunc } = useLogout();

  const logout = useCallback(async () => {
    await logoutFunc();
    clearAccessToken();
    clearStoredCustomer();
    clearClientOutletCart();
    window.location.href = "/";
  }, [clearAccessToken, logoutFunc]);

  const logoutWithoutRedirect = useCallback(async () => {
    await logoutFunc();
    clearAccessToken();
    clearClientOutletCart();
  }, [clearAccessToken, logoutFunc]);

  return useMemo(() => {
    return {
      accessToken,
      isLoggedIn: !!accessToken,
      setAccessToken,
      clearAccessToken,
      logout,
      logoutWithoutRedirect,
    } as UseAuthType;
  }, [accessToken, clearAccessToken, logout, logoutWithoutRedirect, setAccessToken]);
};
