/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

export type UsePointType = (typeof UsePointType)[keyof typeof UsePointType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsePointType = {
  all: "all",
  none: "none",
} as const;
