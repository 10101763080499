import clsx from "clsx";

import styles from "./FormTitle.module.scss";

export interface FormTitleProps {
  children?: React.ReactNode;
}
export function FormTitle({ children }: FormTitleProps): React.ReactNode {
  return (
    <p className={clsx("text__m", "text__bold", "pd__bottom__s", "mg__bottom__m", styles.title)}>
      {children}
    </p>
  );
}
