"use client";
import { useRef, useMemo } from "react";

type ProgressiveFactory<T> = (prevState?: T) => T;

/**
 * 与えられた依存関係リストに基づいて、状態をメモ化するカスタムフック。
 * 前回の状態を保持し、それを元に新しい状態を生成するファクトリ関数を使用します。
 *
 * @template T - メモ化される状態の型。
 * @param {ProgressiveFactory<T>} factory - 前回の状態を引数にとり、新しい状態を生成するファクトリ関数。
 * @param {React.DependencyList} deps - フックが再計算される際に依存する値のリスト。
 * @returns {T} メモ化された新しい状態。
 *
 * @example
 * const value = usePreviousMemo((prev) => prev ? prev + 1 : 0, [dependency]);
 */
export function usePreviousMemo<T>(factory: ProgressiveFactory<T>, deps: React.DependencyList): T {
  const prev = useRef<T>();
  return useMemo(() => {
    const state = factory(prev.current);
    prev.current = state;
    return state;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
