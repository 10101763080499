/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

/**
 * ステータス
 */
export type ResetPassword200Status =
  (typeof ResetPassword200Status)[keyof typeof ResetPassword200Status];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResetPassword200Status = {
  ok: "ok",
  ng: "ng",
} as const;
