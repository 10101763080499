"use client";

import React from "react";

import clsx from "clsx";
import { Link, LinkProps } from "react-scroll";

import arrow from "$/images/egift/arrow.svg";

import styles from "./AnygiftAnchorLink.module.scss";

interface AnygiftAnchorLinkProps extends Omit<LinkProps, "ref"> {}

export function AnygiftAnchorLink({ children, ...rest }: AnygiftAnchorLinkProps) {
  return (
    <Link className={clsx(styles.anchorLink, "pd__top__m pd__bottom__m")} smooth {...rest}>
      <span className="text__m text__bold">{children}</span>
      <img className={styles.anchorArrow} src={arrow.src} alt="矢印" />
    </Link>
  );
}
