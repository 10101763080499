import clsx from "clsx";

export interface FreeShippingLabelProps {
  className?: string;
}

export function FreeShippingLabel({ className }: FreeShippingLabelProps): React.ReactNode {
  return (
    <span className={clsx("tag bg__red square text__white inline mini wsnr", className)}>
      送料無料
    </span>
  );
}
