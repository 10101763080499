import { getFromPath, setFromPath } from "@/utils/object";

export const splitLocalStorageKeys = (key: string) => {
  const keys = key.split(".");
  const rootKey = keys[0];
  const valuePath = keys.slice(1).length > 0 ? keys.slice(1).join(".") : undefined;
  return [rootKey, valuePath] as const;
};

export function getLocalStorageValue<T>(key: string) {
  const [rootKey, valuePath] = splitLocalStorageKeys(key);
  if (!valuePath) {
    // キーがネストしていない場合
    const data = localStorage.getItem(key);
    return data ? (JSON.parse(data) as T) : undefined;
  } else {
    // キーがネストしている場合
    const rootData = localStorage.getItem(rootKey);
    const rooValue = rootData ? JSON.parse(rootData) : undefined;
    return rooValue ? (getFromPath(rooValue as object, valuePath as never) as T) : undefined;
  }
}

export function setLocalStorageValue<T>(key: string, value: T) {
  const [rootKey, valuePath] = splitLocalStorageKeys(key);
  if (!valuePath) {
    // キーがネストしていない場合
    localStorage.setItem(rootKey, JSON.stringify(value));
  } else {
    // キーがネストしている場合
    const rootData = localStorage.getItem(rootKey);
    const rootValue = rootData ? JSON.parse(rootData) : {};
    setFromPath(rootValue as object, valuePath as never, value);
    localStorage.setItem(rootKey, JSON.stringify(rootValue));
  }
}
