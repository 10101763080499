import { Coupon } from "@/generated/open-api/schemas";
import { CouponModel } from "@/models/coupon/type";

export const convertApiCouponToCoupon = (apiCoupon: Coupon): CouponModel => {
  return {
    amountGreaterThan: apiCoupon.amount_greater_than,
    anyRequiredProducts: apiCoupon.any_required_products,
    appends: apiCoupon.appends || [],
    canTotalUseCount: apiCoupon.can_total_use_count,
    code: apiCoupon.code!,
    enable: apiCoupon.enable!,
    endAt: apiCoupon.end_at,
    entitledProducts: apiCoupon.entitled_products || [],
    id: apiCoupon.id!,
    memo: apiCoupon.memo,
    onlyChurnedSubscriptionCustomer: apiCoupon.only_churned_subscription_customer!,
    onlyNoSubscriptionCustomer: apiCoupon.only_no_subscription_customer!,
    onlySubscriptionCustomer: apiCoupon.only_subscription_customer!,
    onlyVailInitialSubscription: apiCoupon.only_vail_initial_subscription!,
    perCustomerLimit: apiCoupon.per_customer_limit!,
    requiredProducts: apiCoupon.required_products,
    startsAt: apiCoupon.starts_at!,
    status: apiCoupon.status!,
    title: apiCoupon.title,
    totalUseCount: apiCoupon.total_use_count!,
    type: apiCoupon.type!,
    value: apiCoupon.value,
  };
};

export const convertCouponToNuxtStoreCoupon = (coupon: CouponModel): Coupon => {
  return {
    amount_greater_than: coupon.amountGreaterThan,
    any_required_products: coupon.anyRequiredProducts,
    appends: coupon.appends,
    can_total_use_count: coupon.canTotalUseCount,
    code: coupon.code,
    enable: coupon.enable,
    end_at: coupon.endAt,
    entitled_products: coupon.entitledProducts,
    id: coupon.id,
    memo: coupon.memo,
    only_churned_subscription_customer: coupon.onlyChurnedSubscriptionCustomer,
    only_no_subscription_customer: coupon.onlyNoSubscriptionCustomer,
    only_subscription_customer: coupon.onlySubscriptionCustomer,
    only_vail_initial_subscription: coupon.onlyVailInitialSubscription,
    per_customer_limit: coupon.perCustomerLimit,
    required_products: coupon.requiredProducts,
    starts_at: coupon.startsAt,
    status: coupon.status,
    title: coupon.title,
    total_use_count: coupon.totalUseCount,
    type: coupon.type,
    value: coupon.value,
  };
};
