"use client";

import { useMemo } from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";
import { useClientFrontStoreCartItems } from "@/storage";
import { withCsr } from "@/utils";
import { sumBy } from "@/utils/array";

import styles from "./CartBadge.module.scss";

export type CartBadgeProps = {
  width: number;
  height: number;
  src: string;
  isPC: boolean;
};

export function CartBadge({ width, height, src, isPC }: CartBadgeProps) {
  return (
    <div className={styles.container}>
      <Image src={src} alt="カート" size={{ width, height }} />
      <CartBadgeNum isPC={isPC} />
    </div>
  );
}

/**
 *  カートのバッジは、ローカルストレージにアクセスするためCSRを強制する実装
 */
const CartBadgeNum = withCsr(function CartBadgeNum({ isPC }: { isPC: boolean }) {
  const { cartItems } = useClientFrontStoreCartItems();
  const totalQuantity = useMemo(() => sumBy(cartItems, "quantity"), [cartItems]);
  return totalQuantity > 0 ? (
    <span className={clsx(styles.badge, isPC && styles.pcBadge)}>{totalQuantity}</span>
  ) : null;
});
