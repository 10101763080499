import React from "react";

export function isJSXElement(element: unknown): element is React.ReactNode {
  if (Array.isArray(element)) {
    // 配列の中にJSXが含まれるのであればtrueを返す
    return element.some(isJSXElement);
  }

  return !!element && typeof element === "object" && "type" in element && "props" in element;
}

export function nl2br(str: string) {
  return str.split("\n").map((line, index) => {
    const isLast = index === str.length - 1;
    return (
      <React.Fragment key={index}>
        {line}
        {!isLast && <br />}
      </React.Fragment>
    );
  });
}
