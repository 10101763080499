"use client";

import { useCallback } from "react";

import { convertApiCartToCart, convertCartToApiCart } from "@/models/cart/converters";
import { CartModel } from "@/models/cart/type";

import { LocalStagePath, NuxtStoreCart } from "./types";
import { useClientLocalStorage } from "./useClientLocalStorage";

const storeKey: LocalStagePath = `vuex.cart.cart`;

/**
 * TODO: 今後脱 vuex.cart.cartするために、cart情報をメモリに保持させるように改修する
 */
export const useClientCartCart = () => {
  const [cart, setCart] = useClientLocalStorage<NuxtStoreCart["cart"] | undefined>({
    key: storeKey,
  });

  /**
   * Nextから`vuex.cart.cart`のストレージを参照することは checkout/5 ページ以外ない。
   * Nuxt側で参照することがあるため、`vuex.cart.cart`のストレージを更新するための関数。
   */
  const setClientCartCart = useCallback(
    (cart: CartModel | undefined | null) => {
      const storedCart = cart ? convertCartToApiCart(cart) : {};
      setCart(storedCart);
    },
    [setCart]
  );

  return { cart: cart ? convertApiCartToCart(cart) : undefined, setClientCartCart };
};
