import type { HTMLProps } from "react";

import clsx from "clsx";

import styles from "./List.module.scss";

export interface ListProps extends HTMLProps<HTMLUListElement> {
  contents: Exclude<React.ReactNode, null | undefined>[];
}

export function List({ className, contents }: ListProps): React.ReactNode {
  return (
    <ul className={clsx(styles.root, className)}>
      {contents.map((content, index) => (
        <li key={`${content.toString()}-${index}`}>{content}</li>
      ))}
    </ul>
  );
}
