"use client";
import { LocalStagePath, NuxtCommonOrder } from "./types";
import { useClientLocalStorage } from "./useClientLocalStorage";

const storeKey: LocalStagePath = `vuex.cart.order`;

export function useClientCartOrder() {
  const [order, setOrder] = useClientLocalStorage<NuxtCommonOrder>({
    key: storeKey,
  });
  return { order: order && Object.keys(order).length === 0 ? undefined : order, setOrder };
}
