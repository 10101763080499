"use client";

import { useCallback, useState } from "react";

import clsx from "clsx";
import { Swiper as SwiperClass } from "swiper";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "../../../template/lp/Form01/styles/swipper.scss";
import { Image } from "@/components";
import { FirstSpecialBenefitsSection } from "@/components/domains/lp/FirstSpecialBenefitsSection";
import { ProductsBySkuCalcPriceModel } from "@/models/product/type";

import { SectionItem } from "./SectionItem";
import styles from "./StartSetSection.module.scss";

interface StartSetSectionProps {
  startSets: ProductsBySkuCalcPriceModel[];
  isWhiteTitle?: boolean;
}

export function StartSetSection({
  startSets,
  isWhiteTitle,
}: StartSetSectionProps): React.ReactNode {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const slideTo = useCallback((index: number) => swiper?.slideTo(index), [swiper]);

  return (
    <section className="bg__yellow pd__top__xl pd__bottom__xl">
      <div className="container">
        <div className="row">
          <div className="col-12 col-m-8 col-offset-m-2">
            <div className="text__center mg__bottom__l">
              <h3 className={clsx("text__xl text__bold", isWhiteTitle && "text__white")}>
                <span className={clsx("wsnr", isWhiteTitle && "text__m")}>はじめての方限定</span>{" "}
                <br />
                <span className="wsnr">継続コーススタートセット</span>
              </h3>
            </div>
          </div>
          <div className="col-12 col-m-4 col-offset-m-4 mg__bottom__m">
            <Image
              src="https://asset.basefood.co.jp/images/pkg/box_80.png"
              alt="継続コーススタートセット"
              className="image mg__bottom__m"
              aspectRatio="638/467"
            />
          </div>
        </div>

        <div className="clear__sp">
          <div className="row">
            {startSets.map((productSet, index) => (
              <SectionItem key={index} productSet={productSet} />
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-12 mg__bottom__l clear__pc">
            <div className={styles.toggle}>
              {startSets.map((productSet, index) => (
                <span
                  className={clsx(
                    "text__s text__bold",
                    styles.button,
                    activeIndex === index && styles.active
                  )}
                  key={index}
                  onClick={() => slideTo(index)}
                >
                  {productSet.quantity}袋セット
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Swiper
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className={clsx(styles.swiper, "clear__pc")}
              spaceBetween={20}
              slidesPerView={1}
              onSwiper={(swiper) => {
                setSwiper(swiper);
              }}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.activeIndex);
              }}
            >
              {startSets.map((productSet, index) => (
                <SwiperSlide key={index}>
                  <SectionItem key={index} productSet={productSet} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <FirstSpecialBenefitsSection />
      </div>
    </section>
  );
}
