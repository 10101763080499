/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

export type RankNameTransformed = (typeof RankNameTransformed)[keyof typeof RankNameTransformed];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RankNameTransformed = {
  Economy: "Economy",
  Business: "Business",
  First: "First",
  Vip: "Vip",
} as const;
