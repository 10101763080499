import clsx from "clsx";

import styles from "./CheckoutProgressBar.module.scss";

interface CheckoutProgressBarProps {
  percentage: number;
  unfilledCount: number;
  onClickConfirm?: () => void;
}

export function CheckoutProgressBar({
  percentage,
  unfilledCount,
  onClickConfirm,
}: CheckoutProgressBarProps): React.ReactNode {
  const isCompleted = percentage === 100;
  const allFilled = unfilledCount === 0;
  const Component = isCompleted ? ("button" as const) : ("div" as const);
  const progressClassName = isCompleted ? styles.processFilled : styles.processUnfilled;

  return (
    <Component
      className={clsx(
        styles.processBase,
        progressClassName,
        "text__center",
        "pd__top__s",
        "pd__bottom__s"
      )}
      onClick={() => isCompleted && onClickConfirm?.()}
    >
      <div style={{ width: `${percentage}%` }} />
      {!isCompleted && <span className={clsx("text__m", "wsnr")}>入力完了まで残り </span>}
      <span className={clsx("wsnr", "text__xl", "text__bold")}>{percentage}% </span>
      {isCompleted && (
        <span className={clsx("text__m", "wsnr")}>
          ご注文を確認する <i className="fas fa-angle-right" />
        </span>
      )}
      {!allFilled && (
        <span>
          <b className="wsnr">記入漏れが {unfilledCount} 点あります</b>
        </span>
      )}
    </Component>
  );
}
