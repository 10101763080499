import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./CustomerVoiceSection.module.scss";

export function CustomerVoiceSection(): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1">
          <p className="text__bold text__xl text__center mg__bottom__l">お客様の声</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-m-6">
          <div className={clsx("bg__white", styles.proteinVoice)}>
            <Image
              src="https://asset.basefood.co.jp/images/ph/illust_effect_G.png"
              alt="腹持ちもかなりよく、罪悪感のない間食としておすすめです。"
              aspectRatio="980 / 700"
              containerClassName={styles.image}
            />
            <p
              className={clsx(
                "text__bold",
                "text__l",
                "mg__top__m",
                "mg__bottom__m",
                styles.proteinVoiceTitle
              )}
            >
              <span>腹持ちもかなりよく、</span>
              <br />
              <span>罪悪感のない間食としておすすめです。</span>
            </p>
            <p className="text__m text__justify">
              朝ごはんを食べる時間がなく、コンビニで買ってデスクで食べることが多かったのですが、なんとなくわるいものを食べている罪悪感がありました。BASE
              FOODは、パンなのに栄養がとれることに惹かれて買ってみました。腹持ちがかなりよく、ビタミンがちゃんととれているからか、食べ始めてから美容の悩みが減りました。
              <span className={clsx("text__bold", styles.proteinMarkpen)}>
                時間がなくても、ちゃんとしたものを食べている安心感があります。
              </span>
              （20代後半・女性）
            </p>
          </div>
        </div>
        <div className="col-12 col-m-6 mg__top__l mg__top__off__pc">
          <div className={clsx("bg__white", styles.proteinVoice)}>
            <Image
              src="https://asset.basefood.co.jp/images/ph/illust_effect_F.png"
              alt="手間いらずだからこそ、むりなく続けられたんだと思います。"
              aspectRatio="980 / 700"
              containerClassName={styles.image}
            />
            <p
              className={clsx(
                "text__bold",
                "text__l",
                "mg__top__m",
                "mg__bottom__m",
                styles.proteinVoiceTitle
              )}
            >
              <span>手間いらずだからこそ、</span>
              <br />
              <span>むりなく続けられたんだと思います。</span>
            </p>
            <p className="text__m text__justify">
              たんぱく質が約30gと知って、トレーニング後のランチなどにぴったりでは？と思い、食べはじめました。からだのコンディションを整えるには、ただ運動をするだけでは不十分で、食事の改善が必要でした。トレーニングを継続しつつBASE
              FOODを取り入れたら、コンディションも上がってきました。調理が手間いらずだからこそ、
              <span className={clsx("text__bold", styles.proteinMarkpen)}>
                結果が出るまでむりなく続けられたんだと思います。
              </span>
              （30代前半・男性）
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="mg__top__m text__gray__dark text__s text__right">
            ※個人の感想であり、効果効能を示すものではありません。
          </p>
        </div>
      </div>
    </div>
  );
}
