import { useMemo } from "react";

import { useGetMileHistories } from "@/generated/open-api/mile/mile";
import { convertObjToCamelCase } from "@/utils/converters";

export const useParsedGetMileHistories = (page: number) => {
  const { data, ...rest } = useGetMileHistories({ page });

  const formattedData = useMemo(() => {
    if (!data || !data.data || !data.meta) return;

    const formatted = convertObjToCamelCase(data);

    return formatted;
  }, [data]);

  return { data: formattedData, ...rest };
};
