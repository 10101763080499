import { FieldErrors } from "react-hook-form";

import styles from "./FormErrorNotification.module.scss";

interface ErrorNotificationProps {
  errors: FieldErrors;
}

export function FormErrorNotification({ errors }: ErrorNotificationProps): React.ReactNode {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {errors.root && (
        <div className={styles.errors}>
          <div className="text__red">{errors.root.message}</div>
        </div>
      )}
    </>
  );
}
