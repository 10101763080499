import React from "react";

import clsx from "clsx";

interface ConfigButtonProps extends React.ComponentProps<"button"> {}

export function ConfigButton({ className, children, ...rest }: ConfigButtonProps): React.ReactNode {
  return (
    <button className={clsx("btn", "inline", "mini", "gray", className)} {...rest}>
      {children}
    </button>
  );
}
