import { PRODUCT_SALE_PARAMETER } from "@/configs/system";
import { getCart } from "@/generated/axios-functions/payseAPI";
import { type Cart } from "@/generated/open-api/schemas";

export async function getCalculatedCartInfo(selectedProducts: Record<string, number | undefined>) {
  const { cart } = await getCart({
    isLP: true,
    lp_path: location.pathname,
    override_is_first_subscription_order: "true",
    products: JSON.stringify(
      Object.entries(selectedProducts).map(([variantId, quantity]) => ({
        variant_id: variantId,
        quantity,
        subscription: true,
      }))
    ),
  });

  return cart;
}

export function calcTotalPrice(cart?: Cart, isInvited?: boolean) {
  if (cart?.total_price == null) {
    return 0;
  }

  // isInvitedの場合、inviteDiscountPriceを引く
  const inviteDiscount = isInvited ? PRODUCT_SALE_PARAMETER.inviteDiscount : 0;
  // 0円以下の場合は0円にする
  return Math.max(Number(cart.total_price) + inviteDiscount, 0);
}

export function calcProgressBarPercentage(cart?: Cart) {
  if (cart?.normal_product_total_price == null) {
    return 0;
  }

  const borderPrice = cart.is_freeze
    ? PRODUCT_SALE_PARAMETER.minDeliveryAmountFrozen
    : PRODUCT_SALE_PARAMETER.minDeliveryAmountNormal;

  return Math.min((parseInt(cart.normal_product_total_price) / borderPrice) * 100, 100);
}
