import {
  CalculatedNutrient,
  GetPointMile200,
  MileResponse,
  RankNameTransformed,
} from "@/generated/open-api/schemas";
import { CalculatedNutrientModel, MileModel } from "@/models/mile/type";
import { convertObjToCamelCase } from "@/utils/converters";
import { objectValues } from "@/utils/object";

export const convertApiMileToMile = (mile: MileResponse): MileModel => {
  return {
    calculatedNutrients: mile.calculated_nutrients
      ? convertApiCalculatedNutrientToCalculatedNutrient(mile.calculated_nutrients)
      : undefined,
    continuousMonths: mile.continuous_months ?? 0,
    continuousWeeks: mile.continuous_weeks ?? 0,
    nextRankUpMile: mile.next_rank_up_mile ?? 0,
    nextRankUpRate: mile.next_rank_up_rate ?? 0,
    rankName: mile.rank_name ? mile.rank_name : "Economy",
    rankNameOld: mile.rank_name_old ? mile.rank_name_old : "Bronze",
    royaltyRank: mile.royalty_rank?.map(convertObjToCamelCase) || [],
    totalMeals: mile.total_meals ?? 0,
    totalMile: mile.total_mile ?? 0,
  };
};

export const convertApiCalculatedNutrientToCalculatedNutrient = (
  calculated_nutrients: CalculatedNutrient
): CalculatedNutrientModel => {
  return {
    convertedIngredient: calculated_nutrients?.converted_ingredient,
    convertedIngredientEn: calculated_nutrients?.converted_ingredient_en,
    ingredientNumWithUnit: calculated_nutrients?.ingredient_num_with_unit,
    nutrient: calculated_nutrients?.nutrient,
    unitAnnotation: calculated_nutrients?.unit_annotation,
  };
};

export const convertGetPointMile200ToPointMile = (mile: GetPointMile200) => {
  const rankName = objectValues(RankNameTransformed).includes(mile.rank_name)
    ? mile.rank_name
    : "Economy";
  return {
    ...convertObjToCamelCase(mile),
    rankName: rankName,
  };
};
