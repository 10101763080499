"use client";
import { useCallback, useState } from "react";

/**
 * カスタムフック `useCount` は、数値を管理するためのフックです。
 * 初期値を指定してカウントを開始し、インクリメント、デクリメントの機能を提供します。
 *
 * @param initialValue カウンターの初期値。デフォルトは 0 です。
 * @returns カウント値、カウントを1増やす関数、カウントを1減らす関数。
 */
export function useCount(initialValue: number = 0) {
  const [count, setCount] = useState(initialValue);
  const increment = useCallback(() => setCount((c) => c + 1), []);
  const decrement = useCallback(() => setCount((c) => c - 1), []);
  return [count, increment, decrement] as const;
}
