import { AxiosResponse } from "axios";
import pino from "pino";

export const logger = pino({
  level: process.env.LOG_LEVEL || "info",
  browser: {
    asObject: true,
    serialize: true,
    write: (o) => {
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(o)); // nextサーバーだとなぜか1行にならないので、この処理で1行のJSONにする
    },
  },
  formatters: {
    level: (label: string) => {
      return {
        level: label,
      };
    },
  },
  timestamp: pino.stdTimeFunctions.isoTime,
});

/*
 * axiosのレスポンスのログを出力する
 * bodyも見たいときはAPP_DEBUGをtrueにする
 */
export function logResponse(response: AxiosResponse): AxiosResponse {
  logger.info({
    status: response.status,
    method: response.config?.method?.toUpperCase(),
    url: response.config?.url,
    params: response.config.params,
  });

  logger.debug({
    body: response.data,
  });

  return response;
}
