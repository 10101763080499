import { CSSProperties } from "react";

import clsx from "clsx";

import styles from "./Skeleton.module.scss";

interface SkeletonProps extends React.ComponentProps<"div"> {
  height?: CSSProperties["height"];
}

// 初期値の18pxは、text__sのpタグの高さ
export function Skeleton({ height = 18, style, className, ...rest }: SkeletonProps) {
  return <div style={{ height, ...style }} className={clsx(styles.root, className)} {...rest} />;
}
