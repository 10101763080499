import { ProductLargeImage } from "@/components/domains";

export function NewLaunchedYakisoba(): React.ReactNode {
  return (
    <ProductLargeImage
      url="/products/deli/basepasta/sauceyakisoba"
      pcImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_new_yakisoba_W1920_H1080.jpg",
        alt: "BASE YAKISOBA",
        aspectRatio: "1920/1080",
      }}
      spImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_new_yakisoba_W1080_H1080.jpg",
        alt: "BASE YAKISOBA",
        aspectRatio: "1080/1080",
      }}
    />
  );
}
