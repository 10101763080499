import React from "react";

import { PrivacyContent } from "../PrivacyContent";
// import styles from "./PrivacyContent.module.scss";

export function PrivacyPolicy(): React.ReactNode {
  return (
    <div>
      <article className="pd__bottom__xl">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text__xl text__bold">プライバシーポリシー</h1>
              <div className="mg__top__l">
                <PrivacyContent />
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}
