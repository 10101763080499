import { useMemo } from "react";

import clsx from "clsx";

import styles from "./PointerBox.module.scss";

export type PointerBoxProps = React.ComponentProps<"div"> & {
  direction?: "top" | "bottom" | "left" | "right";
  color?: React.CSSProperties["color"];
  bodyClassName?: string;
  bodyStyle?: React.CSSProperties;
  arrowClassName?: string;
  arrowStyle?: React.CSSProperties;
};

export function PointerBox({
  children,
  color = "#00508c",
  direction = "top",
  className,
  bodyClassName,
  bodyStyle,
  arrowClassName,
  arrowStyle,
  ...rest
}: PointerBoxProps): React.ReactNode {
  const containerClass = styles[`${direction}Container`];
  const arrowClass = styles[`${direction}Arrow`];
  const colorStyles = useMemo(() => {
    return {
      bodyStyle: {
        ...bodyStyle,
        backgroundColor: color,
      },
      arrowStyle: {
        ...arrowStyle,
        borderBottomColor: color,
      },
    };
  }, [arrowStyle, bodyStyle, color]);
  return (
    <div className={clsx(styles.root, containerClass, className)} {...rest}>
      <div
        className={clsx(styles.arrow, arrowClass, arrowClassName)}
        style={colorStyles.arrowStyle}
      />
      <div className={bodyClassName} style={colorStyles.bodyStyle}>
        {children}
      </div>
    </div>
  );
}
