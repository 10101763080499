// 言語別に金額をフォーマットする
export const formatCurrency = (value: string | number) => {
  const locales = process.env.NEXT_PUBLIC_LANG === "en" ? "us-EN" : "ja-JP";
  const currency = process.env.NEXT_PUBLIC_LANG === "en" ? "USD" : "JPY";
  const formatter = new Intl.NumberFormat(locales, {
    style: "currency",
    currency,
  });
  // デフォルトの「￥」を「¥」へ変換
  return formatter.format(Number(value)).replace("￥", "¥");
};
