import clsx from "clsx";

import { Image } from "@/components/displays";
import { useParsedGetSubscription } from "@/queries";

import styles from "./FreeShippingCampaignInvite.module.scss";

export function FreeShippingCampaignInvite(): React.ReactNode {
  const { data: subscription } = useParsedGetSubscription();

  return (
    <div className="bg__gray">
      <div className="container pd__top__l pd__top__xl__pc">
        <div className="row">
          <div className="col-12 col-m-6 col-offset-m-3">
            <div className={clsx("bg__white", styles.inviteHeader)}>
              <p
                className={clsx(
                  "text__m text__bold text__center mg__bottom__s text__red__dark",
                  styles.campaignTitle
                )}
              >
                送料無料キャンペーン開催中
              </p>
              <Image
                src="https://asset.basefood.co.jp/images/campaign/200million/bnr_free_shipping_W1200_H380.jpg"
                alt="2億袋突破記念 継続コース初回送料が今だけ無料"
                aspectRatio="1200/380"
              />
              <p className="text__m text__bold text__center mg__top__m">
                8/15までに紹介できる友だちは
              </p>
              <p className="text__l text__bold text__center">
                あと
                <span className="text__red__dark text__xxl">
                  {subscription.countRestOfInviteLimit}
                </span>
                人
              </p>
              <p className="text__s text__center mg__top__s">
                ※送料無料キャンペーン期間中（2024/7/18〜8/15）の招待可能数は5人までです。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
