import clsx from "clsx";

import { MoneySpan } from "@/components/displays";
import { PRODUCT_SALE_PARAMETER } from "@/configs/system";

import styles from "./PriceProgressBar.module.scss";

interface PriceProgressBarProps {
  price?: number;
  isFreeze?: boolean;
}

export function PriceProgressBar({ price, isFreeze }: PriceProgressBarProps): React.ReactNode {
  const maxPrice = isFreeze
    ? PRODUCT_SALE_PARAMETER.minDeliveryAmountFrozen
    : PRODUCT_SALE_PARAMETER.minDeliveryAmountNormal;
  const percent = !!price ? Math.min((price / maxPrice) * 100, 100) : 0;

  return (
    <div className={clsx("mg__top__s text__right", styles.container)}>
      <div
        className={clsx(styles.progressbar, isFreeze ? styles.freezeColor : styles.normalColor)}
        style={{ width: `${percent}%` }}
      />
      <span className={clsx("text__s", styles.progressbarTag)}>
        {isFreeze ? "冷凍配送" : "常温配送"}
      </span>
      <MoneySpan prefix="商品金額：" className={clsx("text__s", styles.progressPrice)}>
        {price}
      </MoneySpan>
    </div>
  );
}
