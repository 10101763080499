"use client";
import React, { useMemo } from "react";

interface BasicParagraphProps {
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  weight?: "normal" | "bold";
}

/**
 * from src/components/atoms/TextBasic.vue
 * @returns
 */
export function BasicParagraph(props: BasicParagraphProps): React.ReactNode {
  const { children, size, weight } = props;
  const classNames = useMemo(() => {
    let sizeClass = "text__m";
    let weightClass = "";
    if (weight === "bold") {
      weightClass = "text__bold";
    }
    if (size === "small") {
      sizeClass = "text__s";
    } else if (size === "large") {
      sizeClass = "text__l";
    }
    return `${sizeClass} ${weightClass}`;
  }, [size, weight]);

  return <p className={classNames}>{children}</p>;
}
