import clsx from "clsx";

import { ProductLargeImage } from "@/components/domains";

import styles from "./FreeShippingCampaign.module.scss";

interface FreeShippingCampaignProps {
  // キャンペーンブロックは商品詳細ページ用で、マージン調整が必要
  inCampaignBlock?: boolean;
}

export function FreeShippingCampaign({
  inCampaignBlock,
}: FreeShippingCampaignProps): React.ReactNode {
  return (
    <div>
      <ProductLargeImage
        spImage={{
          src: "https://asset.basefood.co.jp/images/campaign/200million/bnr_free_shipping_W1080_H980.jpg",
          aspectRatio: "54 / 49",
          alt: "継続コース初回送料が今だけ無料",
        }}
        pcImage={{
          src: "https://asset.basefood.co.jp/images/campaign/200million/bnr_free_shipping_W1200_H380.jpg",
          aspectRatio: "60 / 19",
          alt: "2億袋突破記念",
        }}
        full="true"
      />
      <p
        className={clsx(
          "text__s",
          inCampaignBlock && styles.marginAdjust,
          !inCampaignBlock && styles.marginAdjustIndex
        )}
      >
        ※キャンペーン期間は2024/7/18 16時～2024/8/15
        14時です（システム反映により前後する場合があります）。※継続コース初回の常温便送料500円（税込）が無料となります。冷凍便は対象外です。2回目以降は送料がかかります。※公式オンラインショップのみ対象です。Amazon・楽天・Yahoo!ショッピングなどは対象外です。※継続コースを初めて購入する方、過去に購入したがすでに解約済の方（常温便・冷凍便ともに解約済）が対象です。
      </p>
    </div>
  );
}
