import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./ReasonSupport.module.scss";

export function ReasonSupport(): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1">
          <p className="text__bold text__xl text__center mg__bottom__l">
            BASE FOOD
            <sup className="sup">
              <i className="far fa-registered fa-fw" />
            </sup>
            が<span className="wsnr">支持されている</span>
            <br />
            <span className="text__xxl">3つの理由</span>
          </p>
          <div className={clsx("bg__white", styles.proteinReason)}>
            <p className={clsx("text__xxxl", "text__bold", "mg__bottom__s", styles.proteinMarkpen)}>
              01
            </p>
            <p className="text__xl text__bold mg__bottom__m">
              主食を置き換えるだけで、栄養バランスがとれる
            </p>
            <p
              className={clsx(
                "text__m",
                "text__justify",
                "mg__bottom__l",
                "pd__bottom__l",
                styles.proteinReasonText
              )}
            >
              いままでは「主食、サラダ、メイン…」と組みあわせることで、栄養バランスを実現していましたが、いそがしい現代人には、すべて揃えることは大変なのが現実。だからBASE
              FOODは、
              <span className="text__bold">
                毎日食べる「主食」に、栄養をぎゅっとつめこみました。
              </span>
              BASE
              BREADなら、栄養を考えて買い揃えた食事と比べても、抜群の栄養バランス。コストパフォーマンスもばっちりです。
            </p>
            <div className="text__center mg__bottom__l">
              {/* form_04とformが異なるが、form_04へ統一 */}
              <Image
                src="https://asset.basefood.co.jp/lp/form/0911_compare.png"
                alt="主食を置き換えるだけで、栄養バランスがとれる"
                aspectRatio="982 / 1005"
                containerClassName={clsx("clear__sp", styles.image, styles.proteinReasonImage)}
              />
              <Image
                src="https://asset.basefood.co.jp/lp/form/0911_compare_sp.png"
                alt="主食を置き換えるだけで、栄養バランスがとれる"
                aspectRatio="574 / 1091"
                containerClassName={clsx("clear__pc", styles.image, styles.proteinReasonImage)}
              />
            </div>
            <p className={clsx("text__s", "text__gray__dark", styles.proteinNote)}>
              ※1 コンビニで揃えた食事は、サラダ、サンドイッチ、野菜ジュース、ヨーグルトの場合 <br />
              ※2 継続コース初回20%OFF価格。2回目以降は10%OFF価格。
              <br />
              ※グラフは、栄養素等表示基準値（18才以上、基準熱量2,200kcal）に基づき、1日分の基準値の1/3を100とした場合。
            </p>
          </div>
          <div className={clsx("bg__white", "mg__top__xl", styles.proteinReason)}>
            <p className={clsx("text__xxxl", "text__bold", "mg__bottom__s", styles.proteinMarkpen)}>
              02
            </p>
            <p className={clsx("text__xl", "text__bold", "mg__bottom__m")}>
              調理の手間なしで、さっと食べられる
            </p>
            <p
              className={clsx(
                "text__m",
                "text__justify",
                "mg__bottom__l",
                "pd__bottom__l",
                styles.proteinReasonText
              )}
            >
              栄養バランスのいい献立を考え、スーパーで買い物をして調理をするのは時間がかかります。
              家にBASE
              FOODをストックしておけば、袋を開けるだけで、おいしく栄養バランスのよい食事ができます。
            </p>
            <div className="text__center">
              <p className="text__l text__bold">
                Q 自炊にかける時間は以前と比べて短くなりましたか？
              </p>
              <Image
                src="https://asset.basefood.co.jp/images/parts/graph_time_cooking.png"
                alt="調理の手間なしで、さっと食べられる"
                aspectRatio="1 / 1"
                containerClassName={clsx(styles.image, styles.proteinCook)}
              />
            </div>
          </div>
          <div className={clsx("bg__white", "mg__top__xl", styles.proteinReason)}>
            <p className={clsx("text__xxxl", "text__bold", "mg__bottom__s", styles.proteinMarkpen)}>
              03
            </p>
            <p className="text__xl text__bold mg__bottom__m">全粒粉ベースの良質な糖質</p>
            <Image
              src="https://asset.basefood.co.jp/images/ph/bg_ingredients_layout.jpg"
              alt="原材料のこだわり"
              aspectRatio="49 / 27"
              containerClassName={styles.image}
            />
            <p className="text__m mg__top__m">
              小麦全粒粉がベースのため適度に糖質を抑えられます。全粒粉、大豆、昆布、チアシードなど、
              <span className="text__bold">10種類以上の栄養豊富な食材</span>
              をブレンドすることで、おいしさも栄養もかなえました。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
