import $ from "jquery";

declare global {
  interface Window {
    jQuery: typeof $;
    initWidget: () => void;
  }
}

/**
 * DOM内のukomiの要素に対して、ウィジェットを埋め込むための関数
 * ukomiのタグが埋め込まれたHTMLがレンダリングされた後に実行することで、ukomiのウィジェットを該当箇所に埋め込む
 * useEffect内で実行することで、DOMがレンダリングされた後に実行される
 */
export const initUkomiWidget = () => {
  window.jQuery = $;
  if (typeof window.initWidget === "function") {
    window.initWidget();
  }
};
