// delivery_location_codeの生まれ変わり
export const DeliveryReceiveOption = {
  FaceToFace: "00",
  FrontDoor: "01",
  DeliveryBox: "02",
  MeterBox: "03",
  Storage: "04",
  Garage: "05",
  BicycleBasket: "06",
  BuildingReception: "07",
} as const;

export type DeliveryReceiveOption =
  (typeof DeliveryReceiveOption)[keyof typeof DeliveryReceiveOption];

export const deliveryReceiveOptionLabels: Record<DeliveryReceiveOption, string> = {
  [DeliveryReceiveOption.FaceToFace]: "対面受け取り",
  [DeliveryReceiveOption.FrontDoor]: "置き配（玄関ドア前）",
  [DeliveryReceiveOption.DeliveryBox]: "置き配（宅配ボックス）",
  [DeliveryReceiveOption.MeterBox]: "置き配（メーターボックス）",
  [DeliveryReceiveOption.Storage]: "置き配（物置）",
  [DeliveryReceiveOption.Garage]: "置き配（車庫）",
  [DeliveryReceiveOption.BicycleBasket]: "置き配（自転車カゴ）",
  [DeliveryReceiveOption.BuildingReception]: "置き配（建物内受付・管理人預け）",
};

export const DeliveryTimeZone = {
  am: "am",
  time1416: "time1416",
  time1618: "time1618",
  time1820: "time1820",
  time1921: "time1921",
  free: "free",
} as const;

export type DeliveryTimeZone = (typeof DeliveryTimeZone)[keyof typeof DeliveryTimeZone];

export const deliveryTimeZoneLabels: Record<DeliveryTimeZone, string> = {
  [DeliveryTimeZone.am]: "午前中",
  [DeliveryTimeZone.time1416]: "14~16時",
  [DeliveryTimeZone.time1618]: "16~18時",
  [DeliveryTimeZone.time1820]: "18~20時",
  [DeliveryTimeZone.time1921]: "19~21時",
  [DeliveryTimeZone.free]: "時間指定なし",
};
