import { CheckoutFormValues } from "@/components/domains/checkout-form";
import { SubmitSection } from "@/components/domains/checkout-form/SubmitSection";
import { useForm } from "@/components/domains/lp/OnePageCheckoutForm/helper";
import { usePostParsedCartFromCartItems } from "@/queries";

import { ContactSection } from "./ContactSection";
import { ContractSection } from "./ContractSection";
import { DeliverySection } from "./DeliverySection";
import { LocationSection } from "./LocationSection";
import { PaymentSection } from "./PaymentSection";
import { ProductSection } from "./ProductSection";

interface CheckoutConfirmationProps {
  onClickBack: () => void;
  onClickSubmit: () => void;
}

export function CheckoutConfirmation({
  onClickBack,
  onClickSubmit,
}: CheckoutConfirmationProps): React.ReactNode {
  const { form } = useForm();
  const { data: cart } = usePostParsedCartFromCartItems();

  return (
    <>
      {cart && <ProductSection cart={cart} />}
      {form.credentials?.email && <ContactSection email={form.credentials.email} />}
      <LocationSection shippingAddress={form.shippingAddress ?? {}} />
      {cart && <DeliverySection cart={cart} form={form as CheckoutFormValues} />}
      {form.paymentData?.paymentMethod && (
        <PaymentSection paymentMethod={form.paymentData.paymentMethod} />
      )}
      <ContractSection />
      <SubmitSection onClickBack={onClickBack} onClickSubmit={onClickSubmit} showReturnPolicy />
    </>
  );
}
