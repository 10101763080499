"use client";

import React, { useEffect, useRef } from "react";

interface AnygiftButtonProps {
  productCode: string;
}

export const AnygiftButton: React.FC<AnygiftButtonProps> = ({
  productCode,
}): React.ReactElement => {
  const anyGiftDivRef = useRef<HTMLDivElement | null>(null);
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    const storeId = process.env.NEXT_PUBLIC_APP_ENV !== "production" ? "basefood-dev" : "basefood";
    window.AnyGift = { storeId: storeId };

    // ボタンの再設定
    if (anyGiftDivRef.current) {
      // 初期化前に既存のボタン要素を削除
      anyGiftDivRef.current.innerHTML = "";

      anyGiftDivRef.current.setAttribute("data-anygift", "true");
      anyGiftDivRef.current.setAttribute("data-anygift-product-code", productCode);
    }

    // スクリプトの再読み込み
    if (scriptRef.current) {
      scriptRef.current.remove();
    }

    // 新しいスクリプトを作成して追加
    const script = document.createElement("script");
    script.src = "//d1ioo46r7yo3cy.cloudfront.net/store.js";
    script.defer = true;
    document.body.appendChild(script);
    scriptRef.current = script;

    // バリエーション切り替えたら実行
    return () => {
      if (scriptRef.current) {
        scriptRef.current.remove();
      }
    };
  }, [productCode]);

  return <div ref={anyGiftDivRef} data-anygift-product-code={productCode}></div>;
};
