"use client";
import { useEffect, useState } from "react";

import {
  Stripe,
  StripeCardCvcElement,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElement,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import clsx from "clsx";
import { Tooltip } from "react-tooltip";

import { useAsync } from "@/utils/hooks";
import { nl2br } from "@/utils/jsx";
import { loadStripe } from "@/utils/stripe";

import styles from "./CreditCardForm.module.scss";

const elementStyles = {
  base: {
    color: "#32325D",
    fontWeight: 500,
    fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    placeholder: "hogehoge",
    "::placeholder": {
      color: "#CFD7DF",
    },
    ":-webkit-autofill": {
      color: "#e39f48",
    },
  },
};

interface StripeElements {
  cardNumber: StripeCardNumberElement;
  cardExpiry: StripeCardExpiryElement;
  cardCvc: StripeCardCvcElement;
  stripe: Stripe;
}

export interface CreditCardFormProps {
  onChange: (
    elements: StripeElements,
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) => void;
}

export function CreditCardForm({ onChange }: CreditCardFormProps): React.ReactNode {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [stripeElements, setStripeElements] = useState<StripeElements | null>(null);

  useEffect(() => {
    if (!stripeElements) return;
    const { cardNumber, cardExpiry, cardCvc } = stripeElements;
    cardNumber.on("change", (event) => onChange(stripeElements, event));
    cardExpiry.on("change", (event) => onChange(stripeElements, event));
    cardCvc.on("change", (event) => onChange(stripeElements, event));

    return () => {
      cardNumber.off("change");
      cardExpiry.off("change");
      cardCvc.off("change");
    };
  }, [onChange, stripeElements]);

  useAsync(async () => {
    const stripe = (await loadStripe())!;
    setStripe(stripe);
  }, []);

  useEffect(() => {
    if (!stripe) return;
    const elements = stripe.elements();

    const cardNumber = elements.create("cardNumber", { style: elementStyles });
    cardNumber.mount("#card-number");

    const cardExpiry = elements.create("cardExpiry", { style: elementStyles });
    cardExpiry.mount("#card-expiry");

    const cardCvc = elements.create("cardCvc", { style: elementStyles });
    cardCvc.mount("#card-cvc");

    setStripeElements({ stripe, cardNumber, cardExpiry, cardCvc });
    return () => {
      cardNumber.destroy();
      cardExpiry.destroy();
      cardCvc.destroy();
    };
  }, [stripe]);

  return (
    <div>
      <p className="text__s mg__bottom__s">カード番号</p>
      <div id="card-number" className="text__s cc-number" />
      <p className="text__s mg__bottom__s mg__top__m">有効期限</p>
      <div id="card-expiry" className="text__s cc-number" />
      <div className="text__s mg__bottom__s mg__top__m tooltip__title">
        CVV/CVC
        <i
          id="credit-cvc-tooltip"
          className={clsx("fas fa-question-circle tooltip", styles.questionIcon)}
        />
        <Tooltip anchorSelect="#credit-cvc-tooltip">
          {nl2br(
            `カード裏面にある3桁のセキュリティコードです。\nAmerican Expressカードは前面にある4桁のコードです。`
          )}
        </Tooltip>
      </div>
      <div id="card-cvc" className="text__s cc-number" />
    </div>
  );
}
